import React from 'react'

const KeyPoints = () => {
  return (
    <div className='wrapper'>
        <h6 className='text-lg font-medium'>There are many different crafts involved in the process of making a film. Some of the most common ones include:</h6>
        <div className='flex flex-col gap-4 mt-6'>
            {filmProductionProcess.map(process=><div key={process.id} className='space-y-1'>
                <p className='text-lg font-medium'>{process.id}{"."}{" "}{process.heading}{":"}</p>
                <p className='px-10 italic'>{process.text}</p>
            </div>)}
        </div>
    </div>
  )
}

export default KeyPoints

const filmProductionProcess = [
    {
      id: 1,
      heading: "Writing",
      text: "This is the process of creating the story and script for a film."
    },
    {
      id: 2,
      heading: "Directing",
      text: "This is the process of overseeing the artistic and technical aspects of a film production."
    },
    {
      id: 3,
      heading: "Producing",
      text: "This is the process of managing the financial and logistical aspects of a film production."
    },
    {
      id: 4,
      heading: "Cinematography",
      text: "This is the process of capturing the images for a film using a camera."
    },
    {
      id: 5,
      heading: "Editing",
      text: "This is the process of selecting and arranging the shots that will be used in a film."
    },
    {
      id: 6,
      heading: "Sound design",
      text: "This is the process of creating and incorporating sound effects, music, and dialogue into a film."
    },
    {
      id: 7,
      heading: "Art direction",
      text: "This is the process of designing and creating the sets, costumes, and other visual elements of a film."
    },
    {
      id: 8,
      heading: "Special effects",
      text: "This is the process of creating visual effects that cannot be achieved through traditional filmmaking techniques."
    },
    {
      id: 9,
      heading: "Makeup",
      text: "This is the process of applying makeup to actors and actresses to help them look their best on camera."
    },
    {
      id: 10,
      heading: "Costume design",
      text: "This is the process of designing and creating the costumes for a film."
    },
    {
      id: 11,
      heading: "Production design",
      text: "This is the process of designing and creating the sets, props, and other visual elements of a film."
    },
    {
      id: 12,
      heading: "Lighting",
      text: "This is the process of illuminating a scene to create the desired mood and look."
    },
    {
      id: 13,
      heading: "Gripping",
      text: "This is the process of setting up and operating the equipment that holds the cameras and other equipment in place."
    },
    {
      id: 14,
      heading: "Wardrobe",
      text: "This is the process of maintaining and organizing the costumes for a film."
    },
    {
      id: 15,
      heading: "Stunt coordination",
      text: "This is the process of coordinating and choreographing stunts for a film."
    },
    {
      id: 16,
      heading: "Set design",
      text: "This is the process of designing and creating the sets for a film."
    },
    {
      id: 17,
      heading: "Production management",
      text: "This is the process of managing the day-to-day operations of a film production."
    },
    {
      id: 18,
      heading: "Script supervision",
      text: "This is the process of managing the continuity of a film's script and ensuring that it is followed during production."
    },
    {
      id: 19,
      heading: "Art department coordination",
      text: "This is the process of coordinating the activities of the art department to ensure that all of the necessary elements are in place for a film shoot."
    },
    {
      id: 20,
      heading: "Location scouting",
      text: "This is the process of finding and securing locations for a film to be shot."
    },
    {
      id: 21,
      heading: "Casting",
      text: "This is the process of selecting actors and actresses for roles in a film."
    },
    {
      id: 22,
      heading: "Animal training",
      text: "This is the process of training animals to perform on camera."
    },
    {
      id: 23,
      heading: "Prop making",
      text: "This is the process of creating and fabricating props for a film."
    },
    {
      id: 24,
      heading: "Visual effects",
      text: "This is the process of creating special effects for a film using computer-generated imagery (CGI) and other techniques."
    }
  ];
  