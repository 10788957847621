import Home from './Component/Home/Home';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Component/Login/Login';
import Register from './Component/Register/Register';
import JobSearch from './Component/JobSearch/JobSearch';
import RecruiterDash from './Component/RecruiterDash/RecruiterDash';
import JobDetails from './Component/JobDetails/JobDetails';
import RecruiterProfile from './Component/RecruiterProfile/RecruiterProfile';
import ListingDashboard from './Component/ListingDashboard/ListingDashboard';
import ListingDetails from './Component/ListingDetails/ListingDetails';
import CandidateApplications from './Component/CandidateApplications/CandidateApplications';
import BuyCredits from './Component/BuyCredits/BuyCredits';
import TermsAndConditions from './Component/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';
import AdminLogin from './Admin/AdminLogin/AdminLogin';
import ChangePassword from './Component/ChangePassword/ChangePassword';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import LoginOTPUser from './Component/Login/LoginOTPUser';
import RootLayout from './Component/GlobalComp/RootLayout';
import UpdateJob from './Component/updateJob/UpdateJob';
import CreateNewJob from './Component/CreateJob/CreateNewjob';
import RequireAuthJoobSeeker, { RequireAuthRecruiter, RequireAuthAdmin, RootRouteComponent, RequireAuth } from './Component/RequireAuth/RequireAuth';
import Layout, { SubLayout } from './Admin/Layout/Layout';
import JobsForApproval from './Admin/Components/JobsForApproval';
import ApprovedJobs from './Admin/Components/ApprovedJobs';
import TotalRecruiters from './Admin/Components/TotalRecruiters';
import JobDetailsAdmin from './Admin/Components/Jobs/JobDetailsAdmin';
import UpdateJobAdmin from './Admin/Components/Jobs/UpdateJobAdmin';
import RegisterRecruiter from './Component/Register/RegisterRecruiter';
import LoginRecruiter from './Component/Login/LoginRecruiter';
import UserProfile from './Component/Profile/UserProfile/UserProfile';
import PersonalDetails from './Component/Profile/UserProfile/PersonalDetails';
import PhotosU from './Component/Profile/UserProfile/PhotosU';
import VideosU from './Component/Profile/UserProfile/VideosU';
import PostsU from './Component/Profile/UserProfile/PostsU';
import PersonalDetailsSU from './Component/Profile/userProfileDetailsSU/PersonalDetailsSU';
import UserProfileDetailsSU from './Component/Profile/userProfileDetailsSU/UserProfileDetailsSU';
import PhotosSU from './Component/Profile/userProfileDetailsSU/PhotosSU';
import VideosSU from './Component/Profile/userProfileDetailsSU/VideosSU';
import PostsSU from './Component/Profile/userProfileDetailsSU/PostsSU';
import LoginOTPRecruiter from './Component/Login/LoginOTPRecruiter';
import SinglePost from './Component/Profile/post/SinglePost';
import UserAboutU from './Component/Profile/UserProfile/UserAboutU';
import UserAboutSU from './Component/Profile/userProfileDetailsSU/UserAboutSU';
import ProfilesPage from './Component/Profile/ProfilesPage/ProfilesPage';
import KeyPoints from './Component/KeyPoints/KeyPoints';
import TrainingCenters from './Component/trainingCenters/TrainingCenters';
import ProductionAgency from './Component/productionAgency/ProductionAgency';



const Rout = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<RootRouteComponent />}>
        <Route path="/login" element={<Login />} />
        <Route path="/loginrecruiter" element={<LoginRecruiter />} />
        <Route path="/Loginotpuser" element={<LoginOTPUser />} />
        <Route path="/loginotprecruiter" element={<LoginOTPRecruiter />} />
        <Route path="/register" element={<Register />} />
        <Route path="/registerrecruiter" element={<RegisterRecruiter />} />
        <Route path="/AdminLogin" element={<AdminLogin />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        <Route path="/" element={<Home />} />
        <Route element={<RootLayout />}>
          <Route path="/JobSearch" element={<JobSearch />} />
          <Route path="/JobDetails/:jobId" element={<JobDetails />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/24crafts" element={<KeyPoints />} />
          <Route path="/productionagency" element={<ProductionAgency />} />
          <Route path="/trainingcenters" element={<TrainingCenters />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path='/post/:postId' element={<SinglePost />} />
          <Route element={<RequireAuthJoobSeeker />}>
            <Route path="/UserProfile" element={<UserProfile />}>
              <Route index element={<PersonalDetails />} />
              <Route path='photos' element={<PhotosU />} />
              <Route path='videos' element={<VideosU />} />
              <Route path='posts' element={<PostsU />} />
              <Route path='about' element={<UserAboutU />} />
            </Route>
            <Route path="/CandidateApplications" element={<CandidateApplications />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route path='/profiles' element={<ProfilesPage />} />
            <Route path="/profileDetails/:userId" element={<UserProfileDetailsSU />}>
              <Route index element={<PersonalDetailsSU />} />
              <Route path='photos' element={<PhotosSU />} />
              <Route path='videos' element={<VideosSU />} />
              <Route path='posts' element={<PostsSU />} />
              <Route path='about' element={<UserAboutSU />} />
            </Route>
          </Route>
          <Route element={<RequireAuthRecruiter />}>
            <Route path="/RecruiterDash" element={<RecruiterDash />} />
            <Route path="/RecruiterProfile" element={<RecruiterProfile />} />
            <Route path="/createjob" element={<CreateNewJob />} />
            <Route path="/updatejob/:id" element={<UpdateJob />} />
            <Route path="/ListingDashboard" element={<ListingDashboard />} />
            <Route path="/ListingDetails/:id" element={<ListingDetails />} />
            <Route path="/BuyCredits" element={<BuyCredits />} />
          </Route>
        </Route>
        <Route element={<RequireAuthAdmin />}>
          <Route path="admindash" element={<Layout />}>
            <Route path='/admindash' element={<SubLayout />}>
              <Route path='requiredapprovaljobs' element={<JobsForApproval />} />
              <Route path='approvedjobs' element={<ApprovedJobs />} />
              <Route path='totalrecruiters' element={<TotalRecruiters />} />
              <Route path='jobdetailsadmin/:jobId' element={<JobDetailsAdmin />} />
              <Route path="updatejobadmin/:id" element={<UpdateJobAdmin />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  ))


const App = () => {
  return <RouterProvider router={Rout} />;
};

export default App;