//completed
import React, { useState, useEffect } from "react";
import {
  API_STATIC_URL,
  ADSENSESLOTS,
  imgdefault,
} from "../../apiConfig";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBriefcase } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import AdsComponent from "../GlobalComp/AdsComponent";
import { Helmet } from "react-helmet";
import {
  useFilterJobsMutation,
  useGetJobCategoriesQuery,
  useGetJobSubcategoriesQuery,
} from "../../redux/slice/jobServiceApiSlice";
import { Link, useSearchParams } from "react-router-dom";

const JobSearch = () => {
  const [location, setLocation] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [titleChange, setTitleChange] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const [totalPages, setTotalPages] = useState(1); // Add total pages state
  const [jobsFilter, { data: filterJobs }] = useFilterJobsMutation();
  let [searchParams, setSearchParams] = useSearchParams();
  const jobTitle = searchParams.get("jobTitle") || "";
  const catId = searchParams.get("catId") || 1;
  const pageNumber = searchParams.get("pageNumber") || 1;
  const { data: categories } = useGetJobCategoriesQuery("categoriesList", {pollingInterval: 60000,refetchOnFocus: true,refetchOnMountOrArgChange: true,});
  const pageSize = 10;

  useEffect(() => {
    jobsFilter({jobTitle,catId,pageNumber,pageSize,location});
  }, [jobTitle,catId,jobsFilter,pageSize,searchParams,pageNumber,location]);

  useEffect(() => {
    setTitleChange(jobTitle);
    setCategoryId(catId);
  }, [jobTitle, catId]);

  useEffect(() => {
    setTotalPages(Math.ceil(filterJobs?.dataCount / pageSize));
  }, [filterJobs, pageSize]);



  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchParams({
        catId: categoryId,
        jobTitle: titleChange,
        pageNumber
      });
    /* const payload = {catId,subcatIds,location,minExperience,maxExperience,pageNumber,pageSize,jobTitle,};
   await jobsFilter(payload) */
  };

  const generatePaginationButtons = () => {
    const buttons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(currentPage + 2, totalPages);

      if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - 4);
      } else if (startPage === 1) {
        endPage = Math.min(5, totalPages);
      }

      if (startPage > 1) {
        buttons.push(1);
        if (startPage > 2) {
          buttons.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        buttons.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          buttons.push("...");
        }
        buttons.push(totalPages);
      }
    }
    return buttons;
  };

  const handleSearchSubmit = async () => {
    setSearchParams({
      catId: categoryId,
      jobTitle: titleChange,
      pageNumber: 1,
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Job Search</title>
        <link rel="canonical" href="https://silverscreenbook.com/JobSearch" />
      </Helmet>
      <div className="flex flex-col justify-between font-custom-font min-h-screen w-full">
        <div className="relative flex flex-col grow md:bg-opacity-60 z-3 items-center min-h-screen">
          <div className="pt-10 w-full bgcolorlite ">
            <div className="flex md:flex-row sm:flex-col  md:items-end sm:items-center justify-center gap-4 mb-4 ">
              <input
                type="text"
                placeholder="Enter Job Title"
                /* value={jobTitle==null?"":jobTitle} */ value={titleChange}
                className="relative h-10 block w-64 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black"
                name="title"
                onChange={(e) => setTitleChange(e.target.value)}
              />
              <select
                id="categorySelect"
                value={categoryId}
                children
                className="relative h-10 block w-64 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black"
                name="category_id"
                onChange={(e) => setCategoryId(e.target.value)}
              >
                {categories?.result?.length > 0 &&
                  categories?.result?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.categoryName}
                    </option>
                  ))}
              </select>

              <button
                className=" bodybg hover:bodybg h-10 items-center flex font-bold py-4 px-10 rounded-lg transition  ease-in-out transform hover:scale-105 "
                onClick={handleSearchSubmit}
              >
                <FaSearch color="white"/>
              </button>
            </div>
          </div>
          <div className="flex md:flex-row sm:flex-col w-full  ">
            <div className="md:w-1/5 sm:w-full flex flex-col gap-4 items-center">
              <div className="flex flex-col w-4/5 mt-3">
                <p>Location</p>
                <input
                  type="text"
                  placeholder="Location"
                  className="relative h-10 block w-64 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black"
                  name="location"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
            </div>
            <div className="flex w-full md:flex-row sm:flex-col">
              <div className="flex flex-col w-full font-custom-font items-center gap-4 py-4">
                <div className="flex flex-col w-full font-custom-font items-center gap-4 mt-5">
                  <div className="md:text-2xl font-semibold flex w-11/12 ">
                    {/* {selectedCategoryName ? selectedCategoryName : 'Education Jobs'} */}
                  </div>
                  {filterJobs?.result?.length === 0 && (
                    <div className="flex p-10 m-10  font-bold md:text-5xl sm:text-xl">
                      No Jobs Currently
                    </div>
                  )}
                  {filterJobs?.result?.length > 0 &&
                    filterJobs?.result?.map((item, index) => (
                      <Link
                        key={item.jobId}
                        to={`/JobDetails/${item.jobId}`}
                        className="relative md:w-10/12 md:h-32 sm:w-10/12 rounded-lg  shadow-lg bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-100 transition ease-in-out transform hover:scale-105"
                      >
                        <div className="flex md:flex-row sm:flex-col md:justify-between">
                          <div className="flex md:flex-row w-full md:items-center sm:flex-col m-2">
                            <div className="flex sm:flex-col ">
                              <div className="font-bold md:text-2xl text-gray-800 md:my-3 flex flex-row gap-2  items-center ml-4">
                                {item.jobTitle}
                              </div>
                              <div className="flex md:flex-row sm:flex-col md:items-center sm:justify-start text-sm text-gray-600 ml-4">
                                <div className="flex flex-row items-center justify-start">
                                  <img
                                    className="md:h-10 md:w-10 sm:h-6 sm:w-6 rounded-full border shadow-lg m-2 md:ml-0"
                                    src={`${item.imageUrl ? `${API_STATIC_URL}${item.imageUrl}`: `${imgdefault}`}`}
                                    alt={"logo"}
                                  />
                                  <span className="flex flex-row mr-3 font-bold md:text-2xl md:justify-center  md:ml-0 md:items-center">
                                    {" "}
                                    {item.companyName}
                                  </span>
                                </div>
                                <span className="flex flex-row mx-3 md:justify-center md:items-center">
                                  <FaLocationDot className="mx-2" />{" "}
                                  {item.location}
                                </span>
                                {item?.salary&&<span className="flex flex-row mx-3 md:justify-center md:items-center">
                                  <FaMoneyBillWave className="mx-2" />{" "}
                                  {item.salary}
                                </span>}
                              </div>
                            </div>
                          </div>
                          <div className="flex  md:items-center md:w-1/5">
                            <div className="text-sm text-gray-600 sm:ml-10">
                              {item.categoryName}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>

                <div className="mt-10">
                  <div className="flex mt-5 gap-2">
                    {filterJobs?.result?.length > 0 && (
                      <div className="flex mt-5 gap-2">
                        {generatePaginationButtons().map((button, index) => (
                          <button
                            key={index}
                            className={`rounded-lg bodybg px-2 font-semibold  border hover:scale-110 transition bordeodybg hover:bodybg ${
                              button === "..." ? "opacity-0" : ""
                            } ${
                              currentPage === button
                                ? "bodybg text-white"
                                : "text-black"
                            }`}
                            onClick={() =>
                              typeof button === "number"
                                ? handlePageChange(button)
                                : null
                            }
                          >
                            {button}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className=" my-5 flex justify-end items-center ">
                <div className="">
                  <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobSearch;
