
import { Card } from "@mui/material"
import { ADSENSESLOTS, API_STATIC_URL } from "../../../apiConfig"
import { useGetAllProfilesQuery, useGetCategoryProfilesMutation } from "../../../redux/slice/ProfileApiSlice"
import AdsComponent from "../../GlobalComp/AdsComponent"
import { Link} from "react-router-dom"
import { useGetJobCategoriesQuery } from "../../../redux/slice/jobServiceApiSlice"
import { FaUser } from "react-icons/fa"
import { useEffect, useState } from "react"



const ProfilesPage = () => {
  const [getCategoryProfiles,{ data, isLoading, isSuccess }] = useGetCategoryProfilesMutation()
  const { data: categories,isSuccess:catSuccess } = useGetJobCategoriesQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true, });
  const [catId,setCatId]=useState(1)
  useEffect(()=>{getCategoryProfiles({catId})},[catId,getCategoryProfiles])

  let content
  if (isLoading) {
    content = (
      <div className="flex justify-center items-center h-full wrapper ">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
      </div>
    );
  }

  if (isSuccess || catSuccess) {
    content = <div className=" flex flex-row gap-4 justify-between">
      <div className="shadow-xl border-r-2 border-gray-200 w-[300px]  p-3 py-4">
      <p className="text-lg font-semibold">Profile Categories:</p>
      <div className="flex flex-col gap-3 mt-4">
        {categories?.result?.length>0&&categories?.result?.map(category=><button onClick={()=>setCatId(category.id)} key={category.id} className={`font-medium text-sm hover:bg-red-600 rounded-lg p-2 hover:text-white px-3 text-left ${catId===category.id&&`bodybg text-white`}`}>{category.categoryName}</button>)}
      </div>
      </div>
      <div className="wrapper py-2">
        {data?.result?.length > 0?<h5 className="my-3 flex justify-center font-bold">{categories?.result?.filter(category=>category.id===catId)[0].categoryName} Profiles</h5>:
        <h5 className="my-3 flex justify-center font-bold">No {categories?.result?.filter(category=>category.id===catId)[0].categoryName} Profiles</h5>}
      <div className="flex flex-wrap gap-3 ">
        {data?.result?.length > 0 && data?.result?.map(profile => <Link to={`/profileDetails/${profile.profileId}`} key={profile.profileId} className="p-0 w-[150px] h-[190px] shadow-lg flex flex-col gap-2 border-2 border-gray-200 rounded-lg">
          {profile.imageUrl ? <img src={`${API_STATIC_URL}${profile.imageUrl}`} alt={profile.fullName} className="w-full h-[140px] rounded-t-lg" /> : <FaUser  color="gray" className="w-full py-1  h-[140px]" />}
          <p className="hover:underline flex justify-center font-medium text-sm line-clamp-1 overflow-ellipsis">{profile.fullName}</p>
        </Link>)

        }</div>
        </div>
      <div className="hidden md:block ">
        <div className="">
          <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
        </div>
      </div>
    </div>
  }

  return content
}

export default ProfilesPage
