//completed
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FaEye } from "react-icons/fa";
import { useUserChangePasswordMutation } from "../../redux/slice/userServiceApiSlice";

const ChangePassword = () => {
    const navigate = useNavigate();
    const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
    const [userChangePassword] = useUserChangePasswordMutation()
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordEmpty, setPasswordEmpty] = useState(false);
    const [newPasswordEmpty, setNewPasswordEmpty] = useState(false);
    const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);


    const handleToggleVisibility = (field) => {
        switch (field) {
            case 'password':
                setPasswordVisible(!passwordVisible);
                break;
            case 'newPassword':
                setNewPasswordVisible(!newPasswordVisible);
                break;
            case 'confirmNewPassword':
                setConfirmPasswordVisible(!confirmPasswordVisible);
                break;
            default:
                break;
        }
    }   
    const handleBack = () =>{
        if(currentUser?.userRoleId === 3){
            navigate('/userProfile');
        }else if(currentUser?.userRoleId===2){
            navigate('/RecruiterProfile');
        }
    }

    const handleSubmit = async () => {
        if (password === "") {
            setPasswordEmpty(true);
        } else {
            setPasswordEmpty(false);
        }
        if (newPassword === "") {
            setNewPasswordEmpty(true);
        } else {
            setNewPasswordEmpty(false);
        }
        if (confirmNewPassword === "") {
            setConfirmPasswordEmpty(true);
        } else {
            setConfirmPasswordEmpty(false);
        }
        if(newPassword !== confirmNewPassword){
            toast.error("Check your password and try again!",{
                autoClose: 1000,
                style: {
                  width: '300px', 
                }
              })
        }
        
        if (!passwordEmpty && !newPasswordEmpty && !confirmPasswordEmpty) {
            const payload = {
                CurrentPassword: password,
                userPassword: newPassword,
                id:currentUser.id
            }
            try{
                const response = await userChangePassword(payload)
            if(response?.data?.success === true){
                toast.success("Password Changed",{
                    autoClose: 1000,
                    style: {
                      width: '300px', 
                    }
                  })
                navigate(`/login/${currentUser.userRoleId}`)
            }else{
                toast.error("Check your passwords and try again",{
                    autoClose: 1000,
                    style: {
                      width: '300px', 
                    }
                  })
            }
            }catch(e){
                console.log(e);
            }
            
        } else {
            toast.error("Fill all fields",{
                autoClose: 1000,
                style: {
                  width: '300px', 
                }
              })
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Silver Screen Book - Applied Jobs</title>
                <link rel="canonical" href="https://silverscreenbook.com/CandidateApplications" />
            </Helmet>
                <div className='font-custom-font flex flex-col md:flex-row  w-full items-center  py-5 md:min-h-screen md:justify-center'>
                    <div className="flex flex-col md:w-2/5 sm:w-full items-center justify-center bg-blue-200 rounded-lg  border-blue-400 border-2 gap-4 px-20 py-5">
                        <div className="md:text-3xl font-bold my-3 mx-3">
                            Update Password
                        </div>
                        <div className="flex flex-row w-full">
                            <input
                                type={passwordVisible ? "text" : "password"}
                                className={`relative block w-full px-3 py-2 border ${passwordEmpty ? 'border-red-500 animate-shake' : 'border-gray-300'} placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Current Password"
                            />
                            <button
                                onClick={() => handleToggleVisibility('password')}
                                className="flex items-center bg-gray-200 relative w-11 inset-y-0 rounded-r-lg right-0 px-3 py-2 text-gray-500 border border-gray-300 focus:outline-none hover:bg-gray-300 hover:border-gray-500"
                            >
                                <FaEye />
                            </button>
                        </div>
                        <div className="flex flex-row w-full">
                            <input
                                type={newPasswordVisible ? "text" : "password"}
                                className={`relative block w-full px-3 py-2 border ${newPasswordEmpty ? 'border-red-500 animate-shake' : 'border-gray-300'} placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="New Password"
                            />
                            <button
                                onClick={() => handleToggleVisibility('newPassword')}
                                className="flex items-center bg-gray-200 relative w-11 inset-y-0 rounded-r-lg right-0 px-3 py-2 text-gray-500 border border-gray-300 focus:outline-none hover:bg-gray-300 hover:border-gray-500"
                            >
                                <FaEye />
                            </button>
                        </div>
                        <div className="flex flex-row w-full">
                            <input
                                type={confirmPasswordVisible ? "text" : "password"}
                                className={`relative block w-full px-3 py-2 border ${confirmPasswordEmpty ? 'border-red-500 animate-shake' : 'border-gray-300'} placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                placeholder="Confirm New Password"
                            />
                            <button
                                onClick={() => handleToggleVisibility('confirmNewPassword')}
                                className="flex items-center bg-gray-200 relative w-11 inset-y-0 rounded-r-lg right-0 px-3 py-2 text-gray-500 border border-gray-300 focus:outline-none hover:bg-gray-300 hover:border-gray-500"
                            >
                                <FaEye />
                            </button>
                        </div>
                        <div className="flex flex-row gap-4">
                        <button
                            className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-3 px-5 h-10 flex items-center rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                            onClick={handleBack}
                        >
                            Back
                        </button>
                        <button
                            className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-3 px-5 h-10 flex items-center rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                            onClick={handleSubmit}
                        >
                            Save
                        </button></div>
                    </div>
                </div>
        </>
    )
}

export default ChangePassword;
