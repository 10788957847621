import { Link, NavLink, Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FaFacebook } from "react-icons/fa";
import { Helmet } from "react-helmet";
import Divider from '@mui/material/Divider';
import {
    useGetCandidateProfileQuery,
    useUpdateCandidateProfilePicMutation,
} from "../../../redux/slice/jobServiceApiSlice";
import { FaCamera } from "react-icons/fa";
import { Avatar, } from "@mui/joy";
import SocialMediaLinks from "./SocialMedia";
import { BsInstagram } from "react-icons/bs";
import { IoLogoYoutube } from "react-icons/io5";
import { convertBase64 } from "./PersonalDetails";
import { API_STATIC_URL } from "../../../apiConfig";
import { useGetProfileRatingQuery } from "../../../redux/slice/ProfileApiSlice";
import { Rating, Stack } from "@mui/material";
import ProfileBio from "./ProfileBio";

const navLinks = [
    { id: 1, to: '/userProfile', text: 'Profile Details' },
    { id: 2, to: '/userProfile/photos', text: 'Photos' },
    { id: 3, to: '/userProfile/videos', text: 'Videos' },
    { id: 4, to: '/userProfile/posts', text: 'Posts' },
    { id: 5, to: '/userProfile/about', text: 'About' },
];


const UserProfile = () => {
    const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
    const { data: getCandidateProfile, refetch } = useGetCandidateProfileQuery(currentUser?.refId);
    const { data: avgRating,isSuccess } = useGetProfileRatingQuery(currentUser?.refId,{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true});
    const [updateCandidateProfilePic] = useUpdateCandidateProfilePicMutation();
    const handleProfilePicChange = async (e) => {
        const file = e.target.files[0];
        const previewBase64 = await convertBase64(file);
        await updateCandidateProfilePic({
            profileId: getCandidateProfile?.result?.id,
            profilePic: previewBase64,
        });
        refetch();
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Silver Screen Book - user Profile</title>
                <link rel="canonical" href="https://silverscreenbook.com/UserProfile" />
            </Helmet>
            <div className="shadow-lg">
            <div className=" wrapper">
                <div className="  w-full  m-2 flex justify-between md:flex-row flex-col gap-4 md:items-center">
                    <div className="flex flex-row  gap-4 items-center">
                    <div className="relative ">
                        {getCandidateProfile?.result.imageUrl ? (
                            <Avatar
                                alt="Profile Pic"
                                src={`${API_STATIC_URL}${getCandidateProfile?.result.imageUrl}`}
                                sx={{ width: 70, height: 70 }}
                            />
                        ) : (
                            <Avatar sx={{ width: 70, height: 70 }} color="success">
                                <p className="text-2xl">{getCandidateProfile?.result?.fullName?.charAt(0)}</p>
                            </Avatar>
                        )}
                        <div className="absolute top-[50px] left-[55px] ">
                            <label
                                htmlFor="propic"
                                className="rounded-full bg-gray-400 hover:cursor-pointer"
                            >
                                {" "}
                                <FaCamera />
                            </label>
                            <input
                                type="file"
                                id="propic"
                                accept="image/*"
                                onChange={handleProfilePicChange}
                                className="hidden"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col ">
                        <h1 className="md:text-3xl sm:text-nowrap font-bold">{getCandidateProfile?.result?.fullName}</h1>
                        {getCandidateProfile?.result?.profileTitle ? <div className="flex text-sm font-medium text-black items-center">{getCandidateProfile?.result?.profileTitle}<ProfileBio about={getCandidateProfile?.result?.profileTitle} refId={currentUser?.refId} refetch={refetch} /></div> :
                            <div className="flex text-sm font-medium text-black items-center">Bio <ProfileBio refId={currentUser?.refId} refetch={refetch} /></div>}
                    </div>
                    </div>
                    {isSuccess&& <div className="flex flex-col gap-1 md:justify-end items-end">
                        <p className="sm:text-nowrap font-bold">Your Profile Rating</p>
                        <div className="flex gap-2">
                        <Stack spacing={1} className="flex gap-2">
                       <Rating name="half-rating" value={avgRating?.result?.averageProfileRating} readOnly precision={0.1} />
                        </Stack>
                        <p className="font-bold">{avgRating?.result?.averageProfileRating}</p></div>
                    </div>}
                </div>
                <Divider />
                <div className="my-4 ">
                    <div className="flex gap-20 items-center">
                        <p className="text-lg font-semibold">Social Media Link </p>
                        <SocialMediaLinks refId={currentUser?.refId} refetch={refetch} instagram={getCandidateProfile?.result?.instagramLink} facebook={getCandidateProfile?.result?.facebookLink} youtube={getCandidateProfile?.result?.youtubeLink} />
                    </div>
                    <div className="flex flex-row gap-4 justify-between flex-wrap mt-4">
                        {getCandidateProfile?.result?.facebookLink && <Link to={getCandidateProfile?.result?.facebookLink} target="_blank" className="flex gap-2 items-center hover:underline text-sm font-medium"><FaFacebook color="blue" size={20} />{getCandidateProfile?.result?.facebookLink}</Link>}
                        {getCandidateProfile?.result?.instagramLink && <Link to={getCandidateProfile?.result?.instagramLink} target="_blank" className="flex gap-2 items-center hover:underline text-sm font-medium"><BsInstagram color="brown" size={20} />{getCandidateProfile?.result?.instagramLink}</Link>}
                        {getCandidateProfile?.result?.youtubeLink && <Link to={getCandidateProfile?.result?.youtubeLink} target="_blank" className="flex gap-2 items-center hover:underline text-sm font-medium"><IoLogoYoutube color="red" size={20} />{getCandidateProfile?.result?.youtubeLink}</Link>}
                    </div>
                </div>
                <Divider />
                <div className="flex gap-6 flex-row flex-wrap items-center mt-4 text-sm font-semibold  ">
                    {navLinks.map(link => (
                        <NavLink key={link.id} to={link.to} className={({ isActive}) =>isActive?`text-red-700`:""}>{link.text.toUpperCase()}</NavLink>
                    ))}
                </div>
            </div>
            </div>
            <Outlet />
        </>
    );
};

export default UserProfile;
