import { Box, Button, Divider, Drawer } from '@mui/joy';
import React, { useState } from 'react'
import { NavigationRoutes } from './Home';
import { FiMenu  } from "react-icons/fi";
import UserIcon from './UserIcon';
const MobileNav = () => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };
  
    const DrawerList = (
      <Box sx={{ width: 300 }}  role="presentation" onClick={toggleDrawer(false)} className="mx-auto py-3 w-full">
             <div className="lg:hidden">
                 <NavigationRoutes/>
             </div>
             <Divider className="lg:hidden" />
             <UserIcon/>
      </Box>
    );
  return (
    <div>
      <Button onClick={toggleDrawer(true)} variant="text" ><FiMenu  color="red" size="24" /></Button>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor='right'  size='sm' >
        {DrawerList}
      </Drawer>
    </div>
  )
}

export default MobileNav