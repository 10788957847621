
import { useParams } from 'react-router-dom';
import {  useGetUserTotalVideosQuery } from '../../../redux/slice/ProfileApiSlice';
import VideoComponent from '../VideoComponent/VideoComponent';



const VideoSU = () => {
  const { userId } = useParams()
    const { data: videos, refetch } = useGetUserTotalVideosQuery(userId, { refetchOnFocus: true, refetchOnMountOrArgChange: true, pollingInterval: 6000 });

    return (
        <div className='wrapper'>
            <div className="flex justify-between font-custom-font flex-row gap-4">
                <p className='font-medium text-lg'>VIDEOS:</p>
            </div>
            <div className="flex flex-wrap items-center gap-4 mt-6">
                {videos?.result?.length > 0 && videos?.result?.map(video => <VideoComponent video={video} key={video.profileVideoId} refetch={refetch} />)}
            </div>
        </div>
    );
}

export default VideoSU;