//completed
import { useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import { API_STATIC_URL, imgdefault } from "../../apiConfig";
import { LiaCoinsSolid } from "react-icons/lia";
import { FaBriefcase } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { HiOfficeBuilding } from "react-icons/hi";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { useRecruiterDashboardDataQuery } from "../../redux/slice/recruiterServiceApiSlice";
import { Link } from "react-router-dom";

const RecruiterDash = () => {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));
  const { data: dashboardData } = useRecruiterDashboardDataQuery(currentUser?.refId);
  console.log(dashboardData);
  const goToBuyCredits = () => {
    navigate("/BuyCredits");
  };

  return (
    <div className="font-custom-font">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Dashboard</title>
        <link rel="canonical" href="https://silverscreenbook.com/RecruiterDash" />
      </Helmet>

      <div className=" font-custom-font overflow-auto">
        <div className="flex mt-4  sm:flex-col md:flex-row items-center gap-4 w-full py-3 ">
          <div className="flex  items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          {dashboardData?.result ? (
            <div className="flex flex-col items-center justify-center w-full gap-6 mb-10">
              <div className="flex items-center justify-center gap-3 text-center">
                 <img
                    className="h-14 w-14 rounded-full border shadow-lg"
                    src={`${dashboardData?.result?.recruiter?.imageUrl ? `${API_STATIC_URL}${dashboardData?.result?.recruiter?.imageUrl}`: `${imgdefault}`}`}
                    alt={"logo"}
                  />
                <h1 className="md:text-4xl sm:text-3xl text-red-600  font-bold">
                  {dashboardData?.result?.recruiter?.companyName}
                </h1>
              </div>
              <div className="flex flex-col gap-3  w-full">
                <div className="flex flex-col gap-2 bg-gray-50 border-2 border-gray-100 md:px-3 py-3 sm:px-3 rounded-lg transition ">
                  <div className="flex flex-col gap-2">
                    <div className="flex md:flex-row sm:flex-col gap-2 ">
                      <div className="flex flex-row bggray w-full rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center text-xl jusitfy-center p-4 rounded-lg">
                          <FaUser />
                        </div>
                        <div className=" flex flex-col text-base justify-between ">
                          <div className="font-semibold">Name</div>
                          <div>
                            {dashboardData?.result?.recruiter?.recruiterName}
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row bggray w-full rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center text-xl jusitfy-center p-4 rounded-lg">
                          <FaPhoneAlt />
                        </div>
                        <div className=" flex flex-col text-base justify-between ">
                          <div className="font-semibold">Mobile</div>
                          <div>{dashboardData?.result?.recruiter?.mobile}</div>
                        </div>
                      </div>
                      <div className="flex flex-row bggray w-full rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center text-xl jusitfy-center p-4 rounded-lg">
                          <IoMdMail />
                        </div>
                        <div className=" flex flex-col text-base justify-between ">
                          <div className="font-semibold">Email</div>
                          <div>{dashboardData?.result?.recruiter?.email}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row bggray w-full rounded-lg p-2 gap-4 hover:scale-105 transition">
                    <div className=" flex items-center text-xl jusitfy-center p-4 rounded-lg">
                      <HiOfficeBuilding />
                    </div>
                    <div className=" flex flex-col text-base justify-between ">
                      <div className="font-semibold">Address</div>
                      <div>{dashboardData?.result?.recruiter?.address}</div>
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row sm:flex-col gap-3 h-full justify-center">
                  <div className="flex flex-col md:w-1/2 sm:w-full h-full  ">
                    <div className="flex bg-gray-400 text-white text-lg font-semibold px-10 py-2 rounded-t-lg">
                      Credits
                    </div>
                    <div className="flex flex-col gap-3 sm:py-3 bg-gray-50 border-2 border-gray-100 h-full text-base font-semibold px-4 rounded-b-lg ">
                      <div className="flex flex-row bggray rounded-lg p-2 w-full gap-4 hover:scale-105 transition ">
                        <div className=" flex items-center jusitfy-center text-xl  py-2 px-3 rounded-lg ">
                          <LiaCoinsSolid />
                        </div>
                        <div>
                          <div>Credits Used</div>
                          <div>
                            {dashboardData?.result?.recruiter?.creditsUsed}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row bggray rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center text-xl  py-2 px-3 jusitfy-center  rounded-lg ">
                          <LiaCoinsSolid />
                        </div>
                        <div>
                          <div>Credits Available</div>
                          <div>
                            {dashboardData?.result?.recruiter?.creditsAvailable}
                          </div>
                        </div>
                      </div>

                      <button
                        className="bodybg hover:bg-red-500 h-9 w-32 flex items-center justify-center text-white mt-2 mb-5 font-bold ml-2 mr-4 rounded-lg transition ease-in-out transform hover:scale-105"
                        onClick={goToBuyCredits}
                      >
                        Buy Credits
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col md:w-1/2 sm:w-full ">
                    <div className="flex bg-gray-400 text-white text-lg font-semibold px-10 py-2 rounded-t-lg ">
                      Jobs
                    </div>
                    <div className="flex flex-col gap-3 pt-3 pb-3 bg-gray-50 border-2 border-gray-100 -base font-semibold px-4  rounded-b-lg ">
                      <div className="flex flex-row bggray rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center jusitfy-center  p-4 rounded-lg">
                          <FaBriefcase />
                        </div>
                        <div>
                          <div>Total Jobs</div>
                          <div>{dashboardData?.result?.totalJobs}</div>
                        </div>
                      </div>
                      <div className="flex flex-row bggray rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center jusitfy-center  p-4 rounded-lg">
                          <FaBriefcase />
                        </div>
                        <div>
                          <div>Active Jobs</div>
                          <div>{dashboardData?.result?.activeJobs}</div>
                        </div>
                      </div>
                      <div className="flex flex-row bggray rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center jusitfy-center  p-4 rounded-lg">
                          <FaBriefcase />
                        </div>
                        <div>
                          <div>Inactive Jobs</div>
                          <div>{dashboardData?.result?.inactiveJobs}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-5 text-nowrap">
                <Link
                  to="/createjob"
                  className="bodybg hover:bg-red-500 h-10 flex items-center text-white font-bold py-3 px-5 rounded-lg transition ease-in-out transform hover:scale-105"
                >
                  Post a New Job
                </Link>
                <Link
                  to="/ListingDashboard"
                  className="bodybg hover:bg-red-500 h-10 flex items-center text-white font-bold py-3 px-5 rounded-lg transition  ease-in-out transform hover:scale-105"
                >
                  View Posted Jobs
                </Link>
              </div>
            </div>
          ) : (
            <p className="text-lg text-white">Loading...</p>
          )}
          <div className="flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterDash;
