//completed
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import {
  useGetJobCategoriesQuery,
} from "../../redux/slice/jobServiceApiSlice";
import {
  useGetRecruiterQuery,
  usePostJobMutation,
  useUpdateJobMutation,
} from "../../redux/slice/recruiterServiceApiSlice";

const CreateJobForm = ({ type, editJob }) => {
  const [jobTitle, setJobTitle] = useState(editJob?.jobTitle ?? "");
  const [jobDescription, setJobDescription] = useState(editJob?.jobDescription ?? "");
  const [categoryId, setCategoryId] = useState(editJob?.jobCategoryId ?? 1);
  const [salary, setSalary] = useState(editJob?.salary ?? "");
  const [mobile, setMobile] = useState(editJob?.mobile ?? "");
  const [email, setEmail] = useState(editJob?.email ?? "");
  const [location, setLocation] = useState(editJob?.location ?? "");
  const [submitted, setSubmitted] = useState(false); // Track if form is submitted
  const navigate = useNavigate();
  const { data: categories } = useGetJobCategoriesQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true, });
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));
  const { data: getRecruiter } = useGetRecruiterQuery(currentUser?.refId);
  const [postJob] = usePostJobMutation();
  const [updateJob] = useUpdateJobMutation()

  useEffect(() => {
    if (getRecruiter?.result) {
      setMobile(getRecruiter?.result?.mobile);
      setEmail(getRecruiter?.result?.email);
    }
  }, [getRecruiter]);

  useEffect(() => {
    if (
      getRecruiter?.result?.isMobileVerify === 0 ||
      getRecruiter?.result?.isEmailVerify === 0
    ) {
      toast.error("Verify Your MobileNo/Email", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
      navigate("/RecruiterProfile");
    }
  }, [getRecruiter, navigate]);

  const handleSubmit = async () => {
    // Set submitted to true
    setSubmitted(true);

    // Check if any required field is empty
    if (
      jobTitle === "" ||
      jobDescription === "" ||
      categoryId === "" ||
      location === ""
    ) {
      toast.error("Please Fill all fields", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    } else {
      const payload = {
        recruiterId: getRecruiter?.result?.id,
        jobTitle,
        jobDescription: jobDescription,
        jobCategoryId: categoryId,
        salary,
        mobile: getRecruiter?.result?.mobile,
        email: getRecruiter?.result?.email,
        location,
        isActive: 1,
      };
      try {
        let response
        if (type === "Create") {
          payload.isApproved = 0;
          response = await postJob(payload)
        } else {
          payload.isApproved = editJob.isApproved;
          payload.jobId = editJob.jobId;
          response = await updateJob(payload);
        }
        if (response?.data?.success === true) {
          toast.success(response.data.message, {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
          navigate(-1);
        } else {
          toast.error(response.data.message, {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Post a New Job</title>
        <link rel="canonical" href="https://silverscreenbook.com/createjob" />
      </Helmet>
      <div className=" mt-20 font-custom-font  overflow-auto">
        <div className="flex md:flex-row flex-col gap-2 items-center justify-center md:my-10 z-3 w-full ">
          <div className="my-4 flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          <div className="flex flex-col md:w-full bgcolorlite p-4 rounded-lg shadow-lg gap-2 ">
            <h2 className="text-2xl font-bold text-red-900 mb-2">
              {type === "Create" ? "Post a New Job" : "Update Job"}
            </h2>
            <div className="flex flex-wrap ">
              {/* Category */}
              <div className="w-full md:w-1/2 px-3 mt-2 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-red-800 text-xs font-bold mb-2"
                  htmlFor="category_id"
                >
                 Select Category
                </label>
                <select
                  className={`relative block w-full px-3 py-2 border ${submitted && categoryId.value === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="category_id"
                  name="category_id"
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  {categories?.result?.map((category) => (
                    <option className="" key={category.id} value={category.id}>
                      {category.categoryName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full px-3 mt-3">
                <label
                  className="block uppercase tracking-wide text-red-800 text-xs font-bold mb-2"
                  htmlFor="job_title"
                >
                  Job Title
                </label>
                <input
                  className={`relative block w-full px-3 py-2 border ${submitted && jobTitle === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="job_title"
                  name="job_title"
                  type="text"
                  value={jobTitle}
                  placeholder="Software Engineer"
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-7">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-red-800 text-xs font-bold mb-2"
                  htmlFor="job_description"
                >
                  Job Description
                </label>
                <textarea
                  className={`relative block w-full h-full bg-red-50 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black border  py-3 px-4 mb-40 leading-tight   focus:bg-white ${submitted && jobDescription === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                    }`}
                  id="job_description"
                  name="job_description"
                  placeholder="Enter job description"
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            {/* Add salary, mobile, email, and location input fields */}
            <div className="flex flex-wrap ">
              {/* Email */}
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-red-800 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  disabled
                  className={`relative block bg-gray-200 w-full px-3 py-2 border ${getRecruiter?.result?.id === "" && submitted
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* Mobile */}
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-red-800 text-xs font-bold mb-2"
                  htmlFor="mobile"
                >
                  Mobile
                </label>
                <input
                  disabled
                  className={`relative block bg-gray-200 w-full px-3 py-2 border ${getRecruiter?.result?.isEmailVerify === "" && submitted
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="mobile"
                  name="mobile"
                  type="text"
                  placeholder="Mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap ">
              {/* Salary */}
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-red-800 text-xs font-bold mb-2"
                  htmlFor="salary"
                >
                  Salary
                </label>
                <input
                  className={`relative block w-full px-3 py-2 border ${submitted && salary === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="salary"
                  name="salary"
                  type="text"
                  value={salary}
                  placeholder="Salary"
                  onChange={(e) => setSalary(e.target.value)}
                />
              </div>
              {/* Location */}
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-red-800 text-xs font-bold mb-2"
                  htmlFor="location"
                >
                  Job Location
                </label>
                <input
                  className={`relative block w-full px-3 py-2 border ${submitted && location === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="location"
                  name="location"
                  type="text"
                  value={location}
                  placeholder="Location"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-wrap my-3">
              <div className="flex relative w-full px-3 justify-center">
                <button
                  className="relative bodybg w-1/5 h-10 mr-10 hover:bg-red-400 text-white  focus:shadow-outline   ease-in-outbg-red-500  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition-transform hover:scale-110 hover:shadow-md"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
                <button
                  className="relative bodybg w-1/5 h-10 hover:bg-red-400 text-white  focus:shadow-outline  ease-in-outbg-red-500  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition-transform hover:scale-110 hover:shadow-md"
                  type="button"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="my-4 flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateJobForm;
