import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IMAGE_ROUTES } from "../../apiConfig";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ADSENSESLOTS } from "../../apiConfig";
import ReCAPTCHA from "react-google-recaptcha";
import AdsComponent from "../../Component/GlobalComp/AdsComponent";
import { useAuthenticateMutation } from "../../redux/slice/userServiceApiSlice";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const [emailEmpty, setEmailEmpty] = useState(false); // Track if email field is empty
  const [passwordEmpty, setPasswordEmpty] = useState(false); // Track if password field is empty
  const [authenticate]  =useAuthenticateMutation()

  const handleVerify = () => {
    setIsVerified(true);
  };

  const handleToggleVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };


  const loginClicked = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      toast.error("Please input your credentials", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
      if (email === "") setEmailEmpty(true); // Set emailEmpty to true if email is empty
      if (password === "") setPasswordEmpty(true);

      return;
    } else {
      setEmailEmpty(false); // Reset emailEmpty
      setPasswordEmpty(false); // Reset passwordEmpty

      try {
        let userData;

        const payload = {
          userName: email,
          userPassword: password,
          userRoleId: 1,
        };
        userData = await authenticate(payload)

        if (userData?.data?.result) {
          localStorage.setItem("vlinks", JSON.stringify(userData?.data?.result));
          toast.success("Login Successful", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
            navigate("/admindash/requiredapprovaljobs");
        } else {
          toast.error("Login failed. Invalid credentials.", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        }
      } catch (error) {
        console.log(error);
        setPassword("");
        toast.error("Login failed. Invalid credentials.", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Admin Dashboard</title>
        <link rel="canonical" href="https://silverscreenbook.com/AdminDash" />
      </Helmet>

      <div className="flex min-h-screen md:flex-row sm:flex-col justify-between gap-4  font-custom-font px-2 md:py-12">
      <div className=" flex justify-end items-center sm:hidden md:block">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
       <div className="flex  flex-col space-y-10 md:w-[400px] bg-red-400 md:p-10 sm:w-full rounded-2xl border border-red-600 hover:border-red-900">
            <Link
              className="flex justify-center items-center"
              to="/"
            >
              <img
                src={`${IMAGE_ROUTES.altLogo}`}
                alt="Logo"
                className="max-h-36"
              />
            </Link>
            <div className="flex flex-col items-center gap-5">
              <h2 className="relative text-center text-3xl font-extrabold text-red-900">
                ADMIN LOGIN
              </h2>
            </div>

            <div className="relative w-11/12 space-y-10">
              <div className="rounded-md shadow-sm space-y-6">
                <input
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`relative block w-full px-3 py-2 border ${
                    emailEmpty
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  placeholder="Enter Email"
                />

                <div className="flex flex-row">
                  <input
                    id="passwordInput"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`relative block w-full px-3 py-2 border ${
                      passwordEmpty
                        ? "border-red-500 animate-shake"
                        : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    placeholder="Enter Password"
                  />
                  <button
                    onClick={handleToggleVisibility}
                    className="flex items-center bg-gray-200 relative w-11 inset-y-0 rounded-r-lg right-0 px-3 py-2 text-gray-500 border border-gray-300 focus:outline-none hover:bg-gray-300 hover:border-gray-500"
                  >
                    <FaEye />
                  </button>
                </div>
              </div>

              <div className="flex flex-col gap-4 items-center">
                {/* <ReCAPTCHA
                  sitekey={RECAPCHASITEKEY}
                  size="normal"
                  onChange={handleVerify}
                /> */}
                <button
                  onClick={loginClicked}
                  disabled={!isVerified}
                  className="bodybg hover:bg-red-500 w-full text-white font-bold py-2 px-4 mt-2 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
       
        <div className=" flex justify-end items-center sm:hidden md:block">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
      </div>
    </>
  );
};

export default AdminLogin;
