//completed
import  { useEffect  } from 'react';
import { ADSENSECLIENTID} from '../../apiConfig';
import { toast } from 'react-toastify';

const AdsComponent = (props) => {
    const { dataAdSlot } = props;  

    useEffect(() => {  
        try { (window.adsbygoogle = window.adsbygoogle || []).push({}); }
        catch (e) { toast.error(e);}
    },[]);

    return (
        <>
            <ins className="adsbygoogle w-64"
                style={{ display: "block" }}
                data-ad-client={ADSENSECLIENTID}
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins>
        </>
    );
};

export default AdsComponent;