
// import Box from '@mui/material/Box';
// import Button from '@mui/joy/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import {  useEffect, useState } from 'react';
// import { FormLabel } from '@mui/joy';
// import { Add } from '@mui/icons-material';
// import InputEmoji from "react-input-emoji";
// import { convertBase64 } from './PersonalDetails';
// import { AiFillFileAdd, AiTwotoneCloseCircle } from "react-icons/ai";
// import { useGetUserTotalPostsMutation, useNewProfilePostMutation } from '../../redux/slice/ProfileApiSlice';
// import { toast } from 'react-toastify';
// import PostComponent from '../PostComponent/PostComponent';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//      /* border: '2px solid #000', */
//     boxShadow: 24,
//     p: 4,
// };

// const PostsU = () => {
//     const [open, setOpen] = useState(false);
//     const handleOpen = () => setOpen(true);
//     const [postText, setPostText] = useState("")
//     const [imageUrl, setImageUrl] = useState("")
//     const [videoUrl, setVideoUrl] = useState("")
//     const [pageNumber,setPageNumber]=useState(1)
//     const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
//     const [newProfilePost] = useNewProfilePostMutation()
//     const [getUserTotalPostsRS,{data:getUserTotalPosts}] = useGetUserTotalPostsMutation()
//     const pageSize =1;
//     useEffect(()=>{
//         getUserTotalPostsRS({profileId: currentUser.refId,pageSize,pageNumber:1})
//     },[])


//     function handleOnEnter(text) {
//         setPostText(text);
//     }

//     const handleClose = () => {
//         setOpen(false)
//         setPostText("");
//         setImageUrl("");
//         setVideoUrl("");
//     }

//     const handleChange = async (e) => {
//         const file = e.target.files[0];
//         const fileType = file.type.split('/')[0];

//         if (fileType === 'image') {
//             const imageUrl = await convertBase64(file);
//             setImageUrl(imageUrl);
//             setVideoUrl("");
//         } else if (fileType === 'video') {
//             const videoUrl = await convertBase64(file);
//             setVideoUrl(videoUrl);
//             setImageUrl("");
//         }
//     };

//     const handleSave = async () => {
//        const res= await newProfilePost({ profileId: currentUser.refId, imageUrl, videoUrl,postText })
//        if(res?.data?.success){
//         toast.success("New Post Added sucessfully", {
//             autoClose: 1000,
//             style: {
//               width: "300px",
//             },
//           });
//         setImageUrl("")
//         setVideoUrl("")
//         setPostText("");
//         setOpen(false);
//        }else{
//         toast.error("Can't add the new post", {
//             autoClose: 1000,
//             style: {
//               width: "300px",
//             },
//           });
//        }
       
//     }

//     return (
//         <div className='wrapper'>
//             <div className="flex justify-between flex-row gap-4">
//                 <p className='font-medium text-md'>Posts:</p>
//                 <Button onClick={handleOpen} startDecorator={<Add />} color="success" className='flex gap-2'>Create Post</Button>
//             </div>
//             <div className='flex justify-center flex-col gap-2 mt-6 items-center'>
//                {getUserTotalPosts?.result?.length>0 && getUserTotalPosts?.result?.map(post=><PostComponent post ={post} key={post.id}/>)}
//             </div>
//             <Modal
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="modal-modal-title"
//                 aria-describedby="modal-modal-description"
//             >
//                 <Box sx={style}>
//                     <Typography id="modal-modal-title" variant="h6" component="h2">
//                         Create Post
//                     </Typography>
//                     <div className=' mt-4 flex flex-col gap-3'>
//                         <div className='flex flex-col gap-2 relative'>
//                             <FormLabel>Post Text:</FormLabel>
//                             <InputEmoji placeholder="Message your thoughts..." borderColor='#000' cleanOnEnter value={postText} onChange={setPostText} onEnter={handleOnEnter} />
//                             {/* <Textarea placeholder="Message your thoughts..." endDecorator={<div className='absolute left-0  z-20'><EmojiPicker open={false} height={300} width={350} /></div>} minRows={2} value={postText} onChange={e=>setPostText(e.target.value)}/> */}
//                         </div>
//                         <div className='flex flex-col gap-2 relative'>
//                             {(imageUrl || videoUrl) && <div className="px-1 flex justify-between flex-row w-full absolute z-10">
//                                 <Button variant="plain" size="sm" component="label" htmlFor="fileInput" >
//                                     Change
//                                     <input id="fileInput" type="file" accept="image/*, video/*" onChange={handleChange} className="hidden" />
//                                 </Button>
//                                 <div>
//                                     <Button variant="plain" onClick={() => { setImageUrl(""); setVideoUrl("") }}>
//                                         <AiTwotoneCloseCircle size="20" />
//                                     </Button>
//                                 </div>
//                             </div>}
//                             {imageUrl &&  <div className='w-full h-[200px] overflow-auto'>
//                                 <img src={imageUrl} alt="Preview" className='w-full' />
//                             </div>}
//                             {videoUrl && <video src={videoUrl} controls /* style={{ maxWidth: '100%', maxHeight: '100%' }} */ className='w-full' />}
//                             {(!imageUrl && !videoUrl) && <label className='w-full h-[200px] justify-center flex items-center bg-gray-200 text-sm font-medium flex-col gap-1' htmlFor='image'><span><AiFillFileAdd size="18" /></span>Upload Image/Video</label>}
//                             <input type='file' accept="image/*, video/*" id="image" onChange={handleChange} className='hidden' />
//                         </div>
//                     </div>
//                     <div className=' flex justify-end gap-6 mt-4'>
//                         <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
//                         <Button onClick={handleSave} size="md" variant="solid" color="success" disabled={postText === "" && imageUrl==="" && videoUrl===""}>Post</Button>
//                     </div>
//                 </Box>
//             </Modal>
//         </div>
//     );
// }

// export default PostsU

import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect, useState, useCallback } from 'react';
import { FormLabel } from '@mui/joy';
import { Add } from '@mui/icons-material';
import InputEmoji from "react-input-emoji";
import { convertBase64 } from './PersonalDetails';
import { AiFillFileAdd, AiTwotoneCloseCircle } from "react-icons/ai";
import {useGetUserTotalPostsMutation, useNewProfilePostMutation } from '../../../redux/slice/ProfileApiSlice';
import { toast } from 'react-toastify';
import PostComponent from '../PostComponent/PostComponent';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};

const PostsU = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [postText, setPostText] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [refreshPosts, setRefreshPosts] = useState(false);
    const [posts, setPosts] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('vlinks'));
    const [newProfilePost] = useNewProfilePostMutation();
    const [getUserTotalPostsRS] = useGetUserTotalPostsMutation();
    const pageSize = 10;

    const fetchPosts = useCallback(async () => {
        const response = await getUserTotalPostsRS({ profileId: currentUser?.refId,selectedUserId:currentUser?.id, pageSize, pageNumber });
        if (response?.data?.result) {
            if (pageNumber === 1) {
                setPosts(response.data.result);
            } else {
                setPosts(prevPosts => [...prevPosts, ...response.data.result]);
            }
        }
    }, [getUserTotalPostsRS, currentUser?.refId,currentUser?.id, pageSize, pageNumber]);

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts, pageNumber, refreshPosts]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 250;
            const scrolledToBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - scrollThreshold;
            const scrolledToTop = document.documentElement.scrollTop === 0; 
            if (scrolledToBottom) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
           /*  if (scrolledToTop) {
                setPageNumber(1); // Reset page number to 1 when scrolled to the top
            } */
        };
        /* const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 1) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        }; */

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    function handleOnEnter(text) {
        setPostText(text);
    }

    const handleClose = () => {
        setOpen(false);
        setPostText("");
        setImageUrl("");
        setVideoUrl("");
    };

    const handleChange = async (e) => {
        if (e.target && e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const fileType = file.type.split('/')[0];
        if (fileType === 'image') {
            const imageUrl = await convertBase64(file);
            setImageUrl(imageUrl);
            setVideoUrl("");
        } else if (fileType === 'video') {
            // Create a video element to read the video file
            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);
    
            // Wait for metadata to be loaded to get the duration
            videoElement.addEventListener('loadedmetadata', async() => {
                // Check if the duration is less than 5 minutes (300 seconds)
                if (videoElement.duration < 300) {
                    const videoUrl =await convertBase64(file);
                    setVideoUrl(videoUrl);
                    setImageUrl("");
                } else {
                    toast.error("Video duration should be less than 5 minutes.", {
                        autoClose: 1000,
                        style: {
                            width: "300px",
                        },
                    });
                }
            });
        }
       // Reset the input value to allow re-uploading the same file
       e.target.value = null;}
    };
    
   /*  const handleChange = async (e) => {
        const file = e.target.files[0];
        const fileType = file.type.split('/')[0];

        if (fileType === 'image') {
            const imageUrl = await convertBase64(file);
            setImageUrl(imageUrl);
            setVideoUrl("");
        } else if (fileType === 'video') {
            const videoUrl = await convertBase64(file);
            setVideoUrl(videoUrl);
            setImageUrl("");
        }
        e.target.value = null;
    }; */

    const handleSave = async () => {
        const res = await newProfilePost({ profileId: currentUser.refId, imageUrl, videoUrl, postText });
        if (res?.data?.success) {
            toast.success("New Post Added successfully", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
            setPageNumber(1); // Reset page number to 1
            setRefreshPosts(prev => !prev);
            setImageUrl("");
            setVideoUrl("");
            setPostText("");
            setOpen(false);
        } else {
            toast.error("Can't add the new post", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
        }
    };

    return (
        <div className='wrapper'>
            <div className="flex justify-between font-custom-font flex-row gap-4">
                <p className='font-medium text-lg'>POSTS:</p>
                <Button onClick={handleOpen} startDecorator={<Add />} color="success" className='flex gap-2'>Create Post</Button>
            </div>
            <div className='flex md:w-[600px] justify-center mx-auto flex-col gap-2 mt-6 items-center'>
                {posts.map(post => <PostComponent post={post} key={post.id} userId={currentUser?.id} setPageNumber={setPageNumber} setRefreshPosts={setRefreshPosts}/>)}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create Post
                    </Typography>
                    <div className='mt-4 flex flex-col gap-3'>
                        <div className='flex flex-col gap-2 relative'>
                            <FormLabel>Post Text:</FormLabel>
                            <InputEmoji placeholder="Message your thoughts..." borderColor='#000'  value={postText} onChange={value=>setPostText(value)} /* onEnter={handleOnEnter} */ />
                        </div>
                        <div className='flex flex-col gap-2 relative'>
                            {(imageUrl || videoUrl) && <div className="px-1 flex justify-between flex-row w-full absolute z-10">
                                <Button variant="plain" size="sm" component="label" htmlFor="fileInput" type='button' >
                                    Change
                                    <input id="fileInput" type="file" accept="image/*, video/*" onChange={handleChange} className="hidden" />
                                </Button>
                                <div>
                                    <Button variant="plain" type='button' onClick={() => { setImageUrl(""); setVideoUrl(""); }}>
                                        <AiTwotoneCloseCircle size="20" />
                                    </Button>
                                </div>
                            </div>}
                            {imageUrl && <div className='w-full h-[200px] overflow-auto'>
                                <img src={imageUrl} alt="Preview" className='w-full' />
                            </div>}
                            {videoUrl && <div className='w-full h-[250px] overflow-auto'>
                            <video src={videoUrl} controls className='w-full ' />
                            </div>}
                           {/*  {videoUrl && <video src={videoUrl} controls className='w-full h-[200px] overflow-auto' />} */}
                            {(!imageUrl && !videoUrl) && <label className='w-full h-[200px] justify-center flex items-center bg-gray-200 text-sm font-medium flex-col gap-1' htmlFor='image'><span><AiFillFileAdd size="18" /></span>Upload Image/Video</label>}
                            <input type='file' accept="image/*, video/*" id="image" onChange={handleChange} className='hidden' />
                        </div>
                    </div>
                    <div className='flex justify-end gap-6 mt-4'>
                        <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
                        <Button onClick={handleSave} size="md" variant="solid" color="success" disabled={postText === "" && imageUrl === "" && videoUrl === ""}>Post</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default PostsU;
