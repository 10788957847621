// Local
// const API_BASE_URL = 'http://localhost:3500/silverscreenapi';
// const API_STATIC_URL = 'http://localhost:3500/silverscreenapi/static/';

// QA
// const API_BASE_URL = 'https://vilacampus.in/vskillsapi';
// const API_STATIC_URL = 'https://vilacampus.in/vskillsapi/static/';

// Prod
const API_BASE_URL = 'https://silverscreenbook.com/ssbapi';
const API_STATIC_URL = 'https://silverscreenbook.com/ssbapi/static/';

const PAYMENT_GATEWAY_KEY_ID = 'rzp_test_XzqZYz0SXAGhOs'
const PAYMENT_GATEWAY_KEY_SECRET = '65vWQpkaOTt3XS5OlW3B96NI'
const RECAPCHASITEKEY= "6LcWA5QpAAAAAGY39YYklGX-gSN_H-lshRLISBfy"
const RECAPCHASECRETKEY= "6LcWA5QpAAAAAB2a9zXb5D5hoNp8qmQTBINZAWrO"
const ADSENSECLIENTID = 'ca-pub-5656705273115236';
const ADSENSESLOTS = {
  bannerAd: '4743934832',
  inLineAd: 'ca-app-pub-2122666337993728/5047652781'
};

const LOCAL_TOKEN_NAME = 'silverscreenbook';

const IMAGE_ROUTES = {
    navImage: require('./img/coins-job-word.jpg'),
    mainLogo: require('./img/ssblogo_small.png'),
    altLogo: require('./img/ssbAltLogo.png'),
    
}

export const imgdefault = "/assets/nophoto.png"

export const ApplicationStatus=[{id:0,status:"New"},{id:1,status:"Shortlisted"},{id:2,status:"Rejected"},]

export const RoutesNavigation = [{id:1,route:"/",name:"HOME"},{id:2,route:"/JobSearch",name:"JOBS"},{id:3,route:"/profiles",name:"PROFILES"},{id:4,route:"productionagency",name:"PRODUCTION AGENCY"},{id:5,route:"trainingcenters",name:"TRAINING CENTERS"},{id:6,route:"24crafts",name:"24 CRAFTS"}]

export { API_BASE_URL, IMAGE_ROUTES, ADSENSECLIENTID, ADSENSESLOTS, PAYMENT_GATEWAY_KEY_ID, PAYMENT_GATEWAY_KEY_SECRET, API_STATIC_URL, LOCAL_TOKEN_NAME, RECAPCHASECRETKEY, RECAPCHASITEKEY};