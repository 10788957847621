
import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { FormLabel, IconButton, Input } from '@mui/joy';
import { FaRegEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IoEyeOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { useUserChangePasswordMutation } from '../../../redux/slice/userServiceApiSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};

const Changepassword = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [conNewPassword, setCOnNewPassword] = useState("")
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [passwordEmpty, setPasswordEmpty] = useState(false);
    const [newPasswordEmpty, setNewPasswordEmpty] = useState(false);
    const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
    const navigate = useNavigate();
    const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
    const [userChangePassword] = useUserChangePasswordMutation()

    const handleClose = () => {
        setPassword("")
        setNewPassword("")
        setCOnNewPassword("")
        setOpen(false)
        setPasswordVisible(false)
        setNewPasswordVisible(false)
        setConfirmPasswordVisible(false)
    };

    const handleSave = async () => {
        if (password === "") {
            setPasswordEmpty(true);
        } else {
            setPasswordEmpty(false);
        }
        if (newPassword === "") {
            setNewPasswordEmpty(true);
        } else {
            setNewPasswordEmpty(false);
        }
        if (conNewPassword === "") {
            setConfirmPasswordEmpty(true);
        } else {
            setConfirmPasswordEmpty(false);
        }
        if (newPassword !== conNewPassword) {
            toast.error("Check your password and try again!", {
                autoClose: 1000,
                style: {
                    width: '300px',
                }
            })
        }

        if (!passwordEmpty && !newPasswordEmpty && !confirmPasswordEmpty) {
            const payload = {
                CurrentPassword: password,
                userPassword: newPassword,
                id: currentUser.id
            }
            try {
                const response = await userChangePassword(payload)
                if (response?.data?.success === true) {
                    toast.success("Password Changed", {
                        autoClose: 1000,
                        style: {
                            width: '300px',
                        }
                    })
                    setPassword("")
                    setNewPassword("")
                    setCOnNewPassword("")
                    setOpen(false);
                    navigate(`/login`)
                } else {
                    toast.error("Check your passwords and try again", {
                        autoClose: 1000,
                        style: {
                            width: '300px',
                        }
                    })
                }
            } catch (e) {
                console.log(e);
            }

        } else {
            toast.error("Fill all fields", {
                autoClose: 1000,
                style: {
                    width: '300px',
                }
            })
        }

    }

    return (
        <div>
            <Button onClick={handleOpen} className='flex gap-2 w-full' color="danger" size="lg">Change Password</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Update Password
                    </Typography>
                    <div className=' mt-4 flex flex-col gap-3'>
                        <div className='flex flex-col gap-2'>
                            <FormLabel>Old Password</FormLabel>
                            <Input type={passwordVisible ? `text` : `password`} endDecorator={<>{<IconButton onClick={() => setPasswordVisible(p => !p)}>{!passwordVisible ? <IoEyeOutline /> : <FaRegEyeSlash />}</IconButton>}</>} color="neutral" variant="outlined" value={password} onChange={e => setPassword(e.target.value)} />
                            {passwordEmpty&&<p className="text-red-500">
                                 Password required*
                            </p>}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <FormLabel>New Password:</FormLabel>
                            <Input type={newPasswordVisible ? `text` : `password`} endDecorator={<>{<IconButton onClick={() => setNewPasswordVisible(p => !p)}>{!newPasswordVisible ? <IoEyeOutline /> : <FaRegEyeSlash />}</IconButton>}</>} color="neutral" variant="outlined" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                            {newPasswordEmpty&&<p className="text-red-500">
                                New Password required*
                            </p>}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <FormLabel>Confirm New Password:</FormLabel>
                            <Input type={confirmPasswordVisible ? `text` : `password`} endDecorator={<>{<IconButton onClick={() => setConfirmPasswordVisible(p => !p)}>{!confirmPasswordVisible ? <IoEyeOutline /> : <FaRegEyeSlash />}</IconButton>}</>} color="neutral" variant="outlined" value={conNewPassword} onChange={e => setCOnNewPassword(e.target.value)} />
                            {confirmPasswordEmpty&&<p className="text-red-500">
                                Confirm Password required*
                            </p>}
                        </div>
                    </div>
                    <div className=' flex justify-end gap-4 mt-4'>
                        <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
                        <Button onClick={handleSave} size="md" variant="solid" color="success">Change Password</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default Changepassword
