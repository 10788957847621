import { useEffect, useState } from "react";
import {
  useDeleteRecruiterMutation,
  useGetAllRecruitersMutation,
} from "../../redux/slice/recruiterServiceApiSlice";
import { API_STATIC_URL, imgdefault } from "../../apiConfig";
import { MdDelete} from "react-icons/md";
import { GoHome } from "react-icons/go";
import { FaRegUser } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { toast } from "react-toastify";
import { SelectedRecruiterJobs } from "./Jobs/RecruiterSelectedJobs";
import { EditRecruiter, ViewRecruiter } from "./Jobs/RecruiterDetails";
import { IoEyeOutline } from "react-icons/io5";

const TotalRecruiters = () => {
  const [getAllRecruitersS, { data: getAllRecruiters, isLoading }] =useGetAllRecruitersMutation();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recruiterId, setRecruiterId] = useState();
  const [editCond,setEditCon]=useState(0)
  const pageSize = 10;

  useEffect(() => {
    setTotalPages(Math.ceil(getAllRecruiters?.dataCount / pageSize));
  }, [getAllRecruiters?.dataCount, pageSize]);

  useEffect(() => {
    if(editCond===0){
      getAllRecruitersS({ pageNumber, pageSize });
    }
  }, [pageNumber, pageSize, getAllRecruitersS,editCond]);

  const handlePageChange = async (pageNumber) => {
    setPageNumber(pageNumber);
    setEditCon(0)
  };
  const generatePaginationButtons = () => {
    const buttons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      let startPage = Math.max(1, pageNumber - 2);
      let endPage = Math.min(pageNumber + 2, totalPages);

      if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - 4);
      } else if (startPage === 1) {
        endPage = Math.min(5, totalPages);
      }

      if (startPage > 1) {
        buttons.push(1);
        if (startPage > 2) {
          buttons.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        buttons.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          buttons.push("...");
        }
        buttons.push(totalPages);
      }
    }
    return buttons;
  };


  let content;
  if (isLoading) content = <div className=" text-blue-500 font-bold text-xl ">Loading....</div>


  if (getAllRecruiters?.result?.length > 0) {
    content = (
      <div className=" px-2 text-blue-500 flex md:flex-row flex-col gap-6 font-bold text-xl h-full">
        <div className="w-full flex flex-col gap-4">
          <div className=" flex flex-col gap-2 font-custom-font">
            {getAllRecruiters?.result.map((recruiter) => (
              <RecruiterAll
                recruiterId={recruiterId}
                recruiter={recruiter}
                setRecruiterId={setRecruiterId}
                setEditCon={setEditCon}
                key={recruiter.id}
              />
            ))}
          </div>
          <div className="flex gap-2 mt-5 px-2">
            {generatePaginationButtons().map((button, index) => (
              <button
                key={index}
                className={`rounded-lg bg-blue-400 px-3 py-1 text-sm font-semibold  border hover:scale-110 transition border-blue-400 hover:bg-yellow-500 ${
                  button === "..." ? "opacity-0" : ""
                } ${
                  pageNumber === button
                    ? "bg-blue-600 text-white"
                    : "text-black"
                }`}
                onClick={() =>
                  typeof button === "number" ? handlePageChange(button) : null
                }
              >
                {button}
              </button>
            ))}
          </div>
        </div>
        <div className="w-full">
         {editCond ===1 && <SelectedRecruiterJobs id={recruiterId} />}
         {editCond ===2 && <EditRecruiter id={recruiterId} setEditCon={setEditCon}/>}
         {editCond ===3 && <ViewRecruiter id={recruiterId} setEditCon={setEditCon}/>}
        </div>
      </div>
    );
  }
  if (getAllRecruiters?.result?.length === 0) {
    content = (
      <div className="px-2 text-blue-500 font-bold text-xl">No Recruiters</div>
    );
  }
  return <>{content}</>;
};

const RecruiterAll = ({ recruiter, setRecruiterId, recruiterId,setEditCon }) => {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [confirmVisible, setConfirmVisible] = useState(false);
const [deleteRecruiter]=useDeleteRecruiterMutation()
  const handleDeleteRecruiter = (jobId) => {
    setSelectedJobId(jobId); // Set the selected job id
    setConfirmVisible(true); // Show the confirmation dialog
  };

  const handleDeleteRecruiterSelected = async () => {
    try {
      const response = await deleteRecruiter(selectedJobId);

      if (response.data.success === true) {
        // Refresh the listings
        toast.success("Recruiter Deleted Successfully", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
        setEditCon(0)
      } else {
        toast.error("Failed to Delete Recruiter", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    } catch (error) {
      console.error("Error deleting Recruiter:", error);
    } finally {
      setConfirmVisible(false); // Hide the confirmation dialog
    }
  };

  return (
    <div
      onClick={() => {setEditCon(1);setRecruiterId(recruiter.id)}}
      className={`shadow-lg p-3 md:max-w-md flex flex-col gap-4 border-2 text-black ${
        recruiter.id === recruiterId && `bg-gray-300`
      } hover:bg-gray-100 `}
    >
      <div className="flex justify-between gap-4  items-center">
        <div className="flex gap-4 items-center ">
          <img
            src={`${
              recruiter.imageUrl
                ? `${API_STATIC_URL}${recruiter.imageUrl}`
                : `${imgdefault}`
            }`}
            className="w-10 h-10 rounded-full"
            alt={recruiter.companyName}
          />
          <p className="text-wrap">{recruiter.companyName}</p>
        </div>
        <p className="flex items-center gap-1">
          <FaRegUser size="16" />
          {recruiter.recruiterName}
        </p>
      </div>
      {/* <div className="text-sm flex justify-between text-yellow-500 gap-4 font-semibold">
        <p className="flex gap-1 items-center ">
          <MdOutlineEmail />
          {recruiter.email}
        </p>
        <p className="flex gap-1 items-center">
          <FaMobileScreen />
          {recruiter.mobile}
        </p>
      </div> */}
      <div className="text-sm flex justify-between gap-4 font-semibold">
        <p className="flex gap-1 items-center text-green-500">
          <GoHome size="16" />
          {recruiter.address}
        </p>
      </div>
      <div className="text-sm flex justify-between gap-4 font-semibold">
      <button onClick={(e)=>{e.stopPropagation();setEditCon(3);setRecruiterId(recruiter.id)}} className="flex p-2 text-orange-400 items-center gap-1">View<IoEyeOutline   /></button>
      <button onClick={(e)=>{e.stopPropagation();setEditCon(2);setRecruiterId(recruiter.id)}} className="flex p-2 text-orange-400 items-center gap-1">Edit<FiEdit  /></button>
        <button onClick={(e)=>{e.stopPropagation();setEditCon(0);handleDeleteRecruiter(recruiter.id)}}className="flex p-2 text-red-400 items-center gap-1">Delete<MdDelete size="16"/></button>  
      </div>
      {confirmVisible && (
        <div
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 font-custom-font"
      >
          <div className="flex flex-col bg-blue-300 rounded-lg">
            <div className="p-5 items-center flex ">
              <div className="text-2xl ">
                Are you sure you want to delete Recruiter?
              </div>
            </div>
            <div className="flex gap-5 justify-end align-center text-right px-5 py-5 pt-0">
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleDeleteRecruiterSelected}
              >
                Delete
              </button>
              <button
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={(e) =>{e.stopPropagation(); setConfirmVisible(false)}}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};




export default TotalRecruiters;
