//completed
import React, { useState } from "react";
import { IMAGE_ROUTES } from "../../apiConfig";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { useRegisterCandidateMutation } from "../../redux/slice/userServiceApiSlice";
import { Link } from "react-router-dom";
import { useGetJobCategoriesQuery } from "../../redux/slice/jobServiceApiSlice";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [gender, setGender] = useState("");
  const [emailEmpty, setGenderEmpty] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfBirthEmpty, setDateOfBirthEmpty] = useState(false);
  const [genderEmpty, setEmailEmpty] = useState(false);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [fullNameEmpty, setFullNameEmpty] = useState(false);
  const [mobileEmpty, setMobileEmpty] = useState(false);
  const [addressEmpty, setAddressEmpty] = useState(false);
  const [categoryId, setCategoryId] = useState(1);
  const { data: categories } = useGetJobCategoriesQuery("categoriesList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const [registerCandidate] = useRegisterCandidateMutation();

  const handleToggleVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
console.log(gender);

  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const submitClicked = async () => {
    if (email === "") setEmailEmpty(true);

    if (password === "") setPasswordEmpty(true);
    if (fullName === "") setFullNameEmpty(true);
    if (mobile === "") setMobileEmpty(true);
    if (address === "") setAddressEmpty(true);
    if (dateOfBirth === "") setDateOfBirthEmpty(true);
    if (gender === "") setGenderEmpty(true);
    // if(profilePic === null)setProfilePicEmpty(true);
    else {
      setAddressEmpty(false);
      setFullNameEmpty(false);
      setPasswordEmpty(false);
      setEmailEmpty(false);
      setMobileEmpty(false);
      setGenderEmpty(false);
      setDateOfBirthEmpty(false);
    }

    if (
      email === "" ||
      password === "" ||
      fullName === "" ||
      mobile === "" ||
      address === ""
    ) {
      toast.error("Please enter all fields", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    } else if (mobile.replace(/\D/g, "").length !== 10) {
      setMobileEmpty(false);
      setMobileEmpty(true);
      toast.error("Mobile Number must be 10 digits", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    } else if (!emailFormat.test(email)) {
      setEmailEmpty(true);
      toast.error("Please enter a valid email address", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    } else {
      setEmailEmpty(false);
      const data = {
        mobile,fullName,address,email,userPassword: password,image,categoryId, dateOfBirth,gender};

      try {
        // Send data based on selected user type

        let res = await registerCandidate(data);
        if (res.data.success === true) {
          toast.success(res.data.message, {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
          navigate(`/login`);
        } else {
          toast.error(res.data.message, {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        }
      } catch (error) {
        console.error("Registration error:", error);
        toast.error("Failed to register. Please try again later.", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    }
  };

  const handleMobileChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1$2$3"
    ); // Format the mobile number (e.g., XXX-XXX-XXXX)
    setMobileEmpty(false)
    setMobile(formattedValue);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Register</title>
        <link rel="canonical" href="https://silverscreenbook.com/Register" />
      </Helmet>
        <div className="flex md:flex-row sm:flex-col gap-4 px-2 justify-between items-center  font-custom-font md:py-8 py-4">
        <div className=" lg:block hidden">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          <div className="w-full h-full md:w-[1/2] space-y-2 border border-red-600 hover:border-red-700 px-4 py-2 rounded-2xl">
            <div className="flex flex-col items-center">
              <div className="flex justify-center">
                {" "}
                {/* Centering the logo horizontally */}
                <Link className="flex justify-center items-center" to="/">
                  <img
                    src={`${IMAGE_ROUTES.altLogo}`}
                    alt="Logo"
                    className="max-h-28"
                  />
                </Link>
              </div>

              <div className="flex flex-row items-center justify-center">
                {" "}
                {/* Using justify-between to push Upload section to the end */}
                <h2 className="text-2xl font-extrabold text-red-900">
                  Register Profile
                </h2>
              </div>
              <div className=" flex flex-col items-center  rounded-full overflow-hidden min-h-28 justify-center min-w-36">
                {" "}
                {/* Profile picture upload section */}
                <label htmlFor="profilePic" className="cursor-pointer">
                  {image /* Show the preview if profile picture is selected */ ? (
                    <img
                      src={image}
                      alt="Profile Preview"
                      className="mt-2 w-24 h-24 rounded-full"
                    />
                  ) : (
                    /* Show default message if no profile picture is selected */
                    <div className="flex flex-col items-center">
                      <span className="text-sm font-medium text-red-900 dark:text-white mb-2">
                        Upload Profile Picture
                      </span>
                      <span
                        className={`bg-red-200 px-3 py-2 rounded-lg text-gray-900 hover:bg-red-400 
                        border border-gray-400 animate-shake hover:scale-110 transition ease-in-out cursor-pointer`}
                      >
                        Select Image
                      </span>
                    </div>
                  )}
                </label>
                <input
                  id="profilePic"
                  type="file"
                  accept="image/*"
                  onChange={handleProfilePicChange}
                  className="hidden" /* Hide the input element visually */
                />
              </div>
            </div>

            <form className="mt-8 grid md:grid-cols-2 grid-cols-1 gap-4">
              <div className="space-y-3">
                <div>
                  <p className="block mb-2 text-sm font-medium text-red-900 dark:text-white">
                   Full Name
                  </p>
                  <input
                    id="name"
                    value={fullName}
                    onChange={(e) =>{ setName(e.target.value);setFullNameEmpty(false)}}
                    className={`relative block w-full px-3 py-2 border ${
                      fullNameEmpty
                        ? "border-red-400 animate-shake"
                        : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    placeholder="Enter Your Name"
                  />
                   {fullNameEmpty&&<p className="text-red-600 text-sm">*Enter Full Name</p>}
                </div>
                <div>
                  <p className="block mb-2 text-sm font-medium text-red-900 dark:text-white">
                    Your Email
                  </p>
                  <input
                    id="email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value);setEmailEmpty(false)}}
                    className={`relative block w-full px-3 py-2 border ${
                      emailEmpty
                        ? "border-red-400 animate-shake"
                        : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    placeholder="Enter Your Email Address"
                  />
                   {emailEmpty&&<p className="text-red-600 text-sm">*Enter Valid Email Address</p>}
                </div>
                <div>
                  <p className="block mb-2 text-sm font-medium text-red-900 dark:text-white">
                    Your Mobile No
                  </p>
                  <input
                    id="mobile"
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    placeholder="Enter Your Mobile no"
                    className={`relative block w-full px-3 py-2 border mb-2 ${
                      mobileEmpty
                        ? "border-red-400 animate-shake"
                        : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    value={mobile}
                    onChange={handleMobileChange}
                  />
                   {mobileEmpty&&<p className="text-red-600 text-sm">*Enter Valid Mobile No</p>}
                </div>
                <div>
                  <p className="block mb-2 text-sm font-medium text-red-900 dark:text-white">
                    Select the category
                  </p>
                  <select
                    id="categorySelect"
                    value={categoryId}
                    children
                    className={`relative block w-full px-3 py-2 border mb-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    name="category_id"
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option className="text-black" hidden value="">
                      Select a category
                    </option>
                    {categories?.result?.length > 0 &&
                      categories?.result?.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.categoryName}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <p className="block mb-2 text-sm font-medium text-red-900 dark:text-white">
                    Enter Password
                  </p>
                  <div className="flex flex-row">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) =>{ setPassword(e.target.value);setPasswordEmpty(false)}}
                      className={`relative block w-full px-3 py-2 border ${
                        passwordEmpty
                          ? "border-red-400 animate-shake"
                          : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                      placeholder="Enter a strong password"
                    />
                    {/* Toggle visibility button */}
                    <button
                      onClick={handleToggleVisibility}
                      className="flex items-center bg-gray-200 relative w-11 inset-y-0 rounded-r-lg right-0 px-3 py-2 text-gray-500 border border-gray-300 focus:outline-none hover:bg-gray-300 hover:border-gray-500"
                    >
                      <FaEye />
                    </button>
                  </div>
                  {passwordEmpty&&<p className="text-red-600 text-sm">*Enter Password</p>}
                </div>
              </div>
              <div className={`space-y-2`}>
           {/*    <div>
                  <p className="block mb-2 mt-2 text-sm font-medium text-red-900 dark:text-white">
                    Date Of Birth
                  </p>
                    <input
                      type="date"
                      value={confirmPassword}
                      onChange={(e) => {setDateOfBirth(e.target.value);setDateOfBirthEmpty(false)}}
                      className={`relative block w-full px-3 py-2 border ${
                        confirmPasswordEmpty
                          ? "border-red-400 animate-shake"
                          : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                      placeholder="Confirm Password"
                    />
                  {dateOfBirthEmpty&&<p className="text-red-600 text-sm">*Select the Date of birth</p>}
                </div> */}
                <div>
                <GenderGroup value= {gender} setGender={setGender}/>
                {genderEmpty&&<p className="text-red-600 text-sm">*Select the Gender</p>}
                </div>
                <div>
                  <DatePickerValue value={dateOfBirth} setDateOfBirth={setDateOfBirth}/>
                  {dateOfBirthEmpty&&<p className="text-red-600 text-sm">*Select the Date of birth</p>}
                </div>
                <div className={`flex flex-col h-[150px] mb-2`}>
                  <p className="block mb-2 text-sm font-medium text-red-900 dark:text-white">
                    Your Address
                  </p>
                  <textarea
                    id="address"
                    value={address}
                    onChange={(e) =>{ setAddress(e.target.value);setAddressEmpty(false)}}
                    className={`relative block w-full h-full bg-gray-150 px-3 border ${
                      addressEmpty
                        ? "border-red-400 animate-shake"
                        : "border-gray-300"
                    } placeholder-gray-500 py-1 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2 hover:border-black`}
                    placeholder="Enter Your Address"
                  />
                  {addressEmpty&&<p className="text-red-600 text-sm">*Enter Address Details</p>}
                </div>
              </div>
            </form>
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-row min-w-fit justify-center mb-2">
                <div>Already have an account? </div>
                <Link className="ml-2 hover:text-gray-700" to={`/login`}>
                  <b>Sign In</b>
                </Link>
              </div>
              <div className="relative w-1/2 flex flex-col items-center">
            
                <button
                  onClick={submitClicked}
                  type="submit"
                  className="bodybg w-full mt-2 hover:bg-red-500 font-bold py-2 px-5 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
          <div className="lg:block hidden">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
    </>
  );
};





export  function GenderGroup({value,setGender}) {

  return (
    <FormControl>
      <label className="block mb-2 mt-2 text-sm font-medium text-red-900 dark:text-white" >Gender</label>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        color="success"
        value={value}
        onChange={e=>setGender(e.target.value)}
      >
        <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
      </RadioGroup>
    </FormControl>
  );
}



export  function DatePickerValue({value,setDateOfBirth}) {

 return (<>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <label className="block mb-2 mt-2 text-sm font-medium text-red-900 dark:text-white">Date Of Birth</label>
        <DatePicker
          onChange={(newValue) => setDateOfBirth(newValue.format("MM-DD-YYYY"))}
          className="w-full"
        />
    </LocalizationProvider>  
   {/*  <label className="text-red-500">Date Of Birth</label>
    <Input type="date" id="birthday" name="birthday"/> */}
    </> );
}


export default Register;
