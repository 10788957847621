import React from 'react'
import SideNavBar, { TopNavBar } from './SideNavBar'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <>
    <TopNavBar/>
    <Outlet/>
    </>
  )
}
export const SubLayout = () => {
  return (
    <div className='flex flex-row'>
    <SideNavBar/>
    <div className='w-full h-full pt-24 pl-60 px-2 pb-4 '>
    <Outlet/>
    </div>
    </div>
  )
}

export default Layout