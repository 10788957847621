
import { apiSlice } from "../apiSlice";

export const ProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    newProfilePost: builder.mutation({
      query: (initialData) => ({
        url: `/profile/newProfilePost`,
        method: "POST",
        body: { ...initialData }
      }),
      invalidatesTags: [{ type: "Post", id: "LIST" }]
    }),
    getUserTotalPosts: builder.mutation({
      query: (initialData) => ({
        url: `/profile/getUserTotalPosts`,
        method: "POST",
        body: { ...initialData }
      }),
      invalidatesTags: [{ type: "Post", id: "LIST" }]
    }),
    getAllProfilePosts: builder.mutation({
      query: (initialData) => ({
        url: `/profile/getAllProfilePosts`,
        method: "POST",
        body: { ...initialData }
      }),
      invalidatesTags: [{ type: "Post", id: "LIST" }]
    }),
    commentPostByUser: builder.mutation({
      query: (initialData) => ({
        url: `/profile/commentPostByUser`,
        method: "POST",
        body: { ...initialData }
      }),
      invalidatesTags: [{ type: "Comment", id: "LIST" }]
    }),
    getPostCommentsByPostId: builder.query({
      query: (id) => ({ url: `/profile/getPostCommentsByPostId/${id}` }),
      providesTags: (result, error, arg) => {
        if (result?.result) {
          return [
            { type: 'Comment', id: 'LIST' },
            ...result.result.map((id) => ({ type: 'Comment', id })),
          ];
        } else return [{ type: 'Comment', id: 'LIST' }];
      },
    }),
    getPostById: builder.query({
      query: ({postId,userId}) => ({ url: `/profile/getPostById/${postId}/${userId}` }),
      providesTags: (result, error, id) => {
        if (result?.result) {
          return [{ type: "Post", id }];
        } else return [{ type: "Post", id: 0 }];
      },
    }),
    getHomeProfiles: builder.query({
      query: () => ({ url: `/profile/getHomeProfiles` }),
      providesTags: (result, error, arg) => {
        if (result?.result) {
          return [
            { type: 'Candidate', id: 'LIST' },
            ...result.result.map((id) => ({ type: 'Candidate', id })),
          ];
        } else return [{ type: 'Candidate', id: 'LIST' }];
      },
    }),
    getCategoryProfiles: builder.mutation({
      query: (initialData) => ({
         url: `/profile/getCategoryProfiles` ,
         method:"POST",
         body:{...initialData}
        }),
      invalidatesTags:[{ type: 'Candidate', id: 'LIST' }]
    }),
    deletePost: builder.mutation({
      query: (id) => ({
        url: `/profile/deletePost/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Post", id: "List" }]
    }),
    deletePhoto: builder.mutation({
      query: (id) => ({
        url: `/profile/deletePhoto/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Image", id: "List" }]
    }),
    deleteVideo: builder.mutation({
      query: (id) => ({
        url: `/profile/deleteVideo/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Video", id: "List" }]
    }),
    updatePost: builder.mutation({
      query: (initialData) => ({
        url: `/profile/updatePost`,
        method: "POST",
        body: { ...initialData }
      }),
      invalidatesTags: [{ type: "Post", id: "List" }]
    }),
    addImageProfile: builder.mutation({
      query: (initialData) => ({
        url: `/profile/addImageProfile`,
        method: "POST",
        body: { ...initialData }
      }),
      invalidatesTags: [{ type: "Image", id: "List" }]
    }),
    addVideoProfile: builder.mutation({
      query: (initialData) => ({
        url: `/profile/addVideoProfile`,
        method: "POST",
        body: { ...initialData }
      }),
      invalidatesTags: [{ type: "Video", id: "List" }]
    }),
    getUserTotalPhotos: builder.query({
      query: (id) => ({ url: `/profile/getUserTotalPhotos/${id}` }),
      providesTags: (result, error, arg) => {
        if (result?.result) {
          return [
            { type: 'Image', id: 'LIST' },
            ...result.result.map((id) => ({ type: 'Image', id })),
          ];
        } else return [{ type: 'Image', id: 'LIST' }];
      },
    }),
    getUserTotalVideos: builder.query({
      query: (id) => ({ url: `/profile/getUserTotalVideos/${id}` }),
      providesTags: (result, error, arg) => {
        if (result?.result) {
          return [
            { type: 'Video', id: 'LIST' },
            ...result.result.map((id) => ({ type: 'Video', id })),
          ];
        } else return [{ type: 'Video', id: 'LIST' }];
      },
    }),
    likePostByUser: builder.mutation({
      query: (initialData) => ({
        url: `/profile/likePostByUser`,
        method: "POST",
        body: { ...initialData }
      }),
      invalidatesTags: [{ type: "Post", id: "List" }]
    }),
    unlikePostByUser: builder.mutation({
      query: (initialData) => ({
        url: `/profile/unlikePostByUser/${initialData.id}/${initialData.postId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Post", id: "List" }]
    }),
    addProfileRatingByUser: builder.mutation({
      query: (initialData) => ({
        url: `/profile/addProfileRatingByUser`,
        method: "POST",
        body: { ...initialData }
      }),
      invalidatesTags: [{ type: "Rating", id: "List" }]
    }),
    getSelectedProfileByUser: builder.query({
      query: ({ profileId, userId }) => ({ url: `/profile/getSelectedProfileByUser/${profileId}/${userId}` }),
      providesTags: (result, error, id) => {
        if (result?.result) {
          return [{ type: 'Candidate', id }];
        } else return [{ type: 'Candidate', id: 0 }];
      },
    }),
    getProfileRating: builder.query({
      query: (id) => ({ url: `/profile/getProfileRating/${id}` }),
      providesTags: (result, error, id) => {
        if (result?.result) {
          return [{ type: 'Rating', id }];
        } else return [{ type: 'Rating', id: 0 }];
      },
    }),
  })
})

export const {
  useNewProfilePostMutation, useGetUserTotalPostsMutation, useCommentPostByUserMutation,
  useGetPostCommentsByPostIdQuery, useGetAllProfilePostsMutation, useGetHomeProfilesQuery,
  useGetPostByIdQuery, useDeletePostMutation, useUpdatePostMutation, useAddImageProfileMutation,
  useGetUserTotalPhotosQuery, useDeletePhotoMutation, useLikePostByUserMutation,
  useAddVideoProfileMutation, useGetUserTotalVideosQuery, useDeleteVideoMutation,useGetCategoryProfilesMutation,
  useUnlikePostByUserMutation, useAddProfileRatingByUserMutation, useGetSelectedProfileByUserQuery,
  useGetProfileRatingQuery,
} = ProfileApiSlice;
