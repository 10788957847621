//completed
import React, { useState } from "react";
import { API_STATIC_URL } from "../../apiConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { LiaCoinsSolid } from "react-icons/lia";
import { IoMdHome } from "react-icons/io";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import {
  useGetRecruiterQuery,
  useSaveRecruiterMutation,
  useUpdateRecruiterLogoMutation,
  useVerifyRecruiterEmailMutation,
  useVerifyRecruiterMobileMutation,
} from "../../redux/slice/recruiterServiceApiSlice";
import {
  useSendVerifyEmailOTPMutation,
  useSendVerifyMobileOTPMutation,
} from "../../redux/slice/notificationApiSlice";
import Changepassword from "../Profile/UserProfile/ChangePassword";
import { Button } from "@mui/joy";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      // Correct assignment of onload event
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      // Correct assignment of onerror event
      reject(error);
    };
  });
};

const VerifyMobileModal = ({
  closeMobileOTPModal,
  mobile,
  candidateId,
  refetch,
}) => {
  const [OTP, setOTP] = useState(null);
  const [verifyRecruiterEmail] = useVerifyRecruiterMobileMutation();
  const submitEmailOTP = async () => {
    try {
      const response = await verifyRecruiterEmail({
        candidateId,
        mobile,
        otp: OTP,
      });

      if (response.data.success === true) {
        toast.success("Mobile Verified", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
        closeMobileOTPModal();
        refetch();
      } else {
        toast.error("Check OTP and Try Again!", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    } catch (error) {
      toast.error(error, {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    }
  };

  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-red-800 bg-opacity-50">
      <div className="flex flex-col bg-red-300 rounded-lg p-8">
        <button
          className="flex bg-red-500 self-end rounded-lg px-4 py-1 "
          onClick={closeMobileOTPModal}
        >
          X
        </button>
        <h2 className="text-xl font-bold mb-4">
          Enter OTP sent to your Mobile No.
        </h2>
        <input
          className="h-10 text-black"
          onChange={(e) => setOTP(e.target.value)}
        />

        <button
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg mt-4"
          onClick={submitEmailOTP}
        >
          Submit OTP
        </button>
      </div>
    </div>
  );
};

const VerifyEmailModal = ({
  closeEmailOTPModal,
  emailId,
  candidateId,
  refetch,
}) => {
  const [OTP, setOTP] = useState(null);
  const [verifyRecruiterMobile] = useVerifyRecruiterEmailMutation();
  const submitEmailOTP = async () => {
    try {
      const response = await verifyRecruiterMobile({
        candidateId,
        emailId,
        otp: OTP,
      });

      if (response.data.success === true) {
        toast.success("Email Verified", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
        closeEmailOTPModal();
        refetch();
      } else {
        toast.error("Check OTP and Try Again!", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    } catch (error) {
      toast.error(error, {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    }
  };

  return (
    <div className="fixed top-0 left-0 z-50 w-full flex items-center justify-center bg-red-800 bg-opacity-50">
      <div className="flex flex-col bg-red-300 rounded-lg p-8">
        <button
          className="flex bg-red-500 self-end rounded-lg px-4 py-1 "
          onClick={closeEmailOTPModal}
        >
          X
        </button>
        <h2 className="text-xl font-bold mb-4">
          Enter OTP sent to your Email Address
        </h2>
        <input
          className="h-10 text-black"
          onChange={(e) => setOTP(e.target.value)}
        />

        <button
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg mt-4"
          onClick={submitEmailOTP}
        >
          Submit OTP
        </button>
      </div>
    </div>
  );
};

const LogoUploadModal = ({ handleLogoUpload, refId, refetch }) => {
  const [logo, setLogo] = useState(null);
  const [preview, setPreview] = useState("");
  const [updateRecruiterLogo] = useUpdateRecruiterLogoMutation();
  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    setLogo(file);
    const previewBase64 = await convertBase64(file);
    setPreview(previewBase64);
  };

  const handleUpload = async () => {
    if (logo) {
      const payload = {
        recruiterId: refId,
        logoImage: preview,
      };

      try {
        const response = await updateRecruiterLogo(payload);
        // Handle response
        if (response.data.success === false) {
          toast.error("Upload Failed", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        } else {
          refetch();
          handleLogoUpload();
          toast.success("Uploaded Successfully", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        }
      } catch (error) {
        // Handle error
        console.error("Error uploading logo:", error, {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    } else {
      toast.error("Select a File to Upload", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    }
  };

  return (
    <div className="fixed top-0 left-0 z-50 w-full flex items-center justify-center bg-red-800 bg-opacity-50">
      <div className="flex flex-col bg-red-300 rounded-lg p-8">
        <button
          className="flex bg-red-500 self-end rounded-lg px-4 py-1 "
          onClick={handleLogoUpload}
        >
          X
        </button>
        <h2 className="text-xl font-bold mb-4">Upload Logo</h2>
        <input type="file" onChange={handleLogoChange} />
        {preview && (
          <img
            src={preview}
            alt="Preview"
            className="my-4 w-32 h-32 object-cover"
          />
        )}
        <button
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg mt-4"
          onClick={handleUpload}
        >
          Upload
        </button>
      </div>
    </div>
  );
};

const RecruiterProfile = () => {
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [showEmailOTPModal, setEmailOTPModal] = useState(false);
  const [showMobileOTPModal, setMobileOTPModal] = useState(false);
  const [showLogoUploadModal, setShowLogoUploadModal] = useState(false); // State to manage the visibility of the logo upload modal
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));
  const { data: getRecruiter, refetch } = useGetRecruiterQuery(
    currentUser?.refId
  );
  const [sendVerifyMobileOTP] = useSendVerifyMobileOTPMutation();
  const [sendVerifyEmailOTP] = useSendVerifyEmailOTPMutation();

  const closeLogoUploadModal = () => {
    setShowLogoUploadModal(false);
  };
  const emailModalOpen = () => {
    toast.success(`OTP sent to ${getRecruiter?.result?.email}`, {
      autoClose: 1000,
      style: {
        width: "300px",
      },
    });
    sendVerifyEmailOTP({ emailId: getRecruiter?.result?.email });
    setEmailOTPModal(true);
  };
  const mobileModalOpen = async () => {
    toast.success(`OTP sent to ${getRecruiter?.result?.mobile}`, {
      autoClose: 1000,
      style: {
        width: "300px",
      },
    });
    await sendVerifyMobileOTP({ mobile: getRecruiter?.result?.mobile });
    setMobileOTPModal(true);
  };

  const handleEditProfile = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  return (
    <div className="App font-custom-font">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Profile</title>
        <link rel="canonical" href="https://silverscreenbook.com" />
      </Helmet>

      <div className="mt-20 w-full text-white font-custom-font">
        <div className="flex md:flex-row sm:flex-col gap-4 w-full py-10">
          <div className="flex  items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          <div className="flex flex-col md:w-3/5 w-full h-fit items-center mx-auto bg-gray-50 rounded-lg shadow-lg  ">
            <div className="flex flex-row gap-4 rounded-t-lg px-6 py-4 bg-gray-400 w-full shadow-lg">
              <h2 className="relative text-2xl font-bold text-white">
                {editing ? "Edit Profile" : "Your Profile"}
              </h2>
            </div>
            {!editing ? (
              <div className="flex flex-col w-full gap-4 py-4">
                <div className="flex md:flex-row sm:flex-col gap-4 px-4 w-full">
                  <div className="flex flex-row bggray md:w-1/2 items-center rounded-lg p-2 gap-4 hover:scale-105 transition">
                    <div className=" flex items-center jusitfy-center   rounded-lg">
                      {getRecruiter?.result?.imageUrl ? (
                        <img
                          src={`${API_STATIC_URL}${getRecruiter?.result?.imageUrl}`}
                          alt="Logo"
                          className="self-end h-12 w-12 rounded-full border shadow-lg"
                          onClick={() => setShowLogoUploadModal(true)}
                        />
                      ) : (
                        <>
                          <button
                            className="bodybg hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-4 transition ease-in-out hover:scale-105"
                            onClick={() => setShowLogoUploadModal(true)}
                          >
                            Upload Logo
                          </button>
                        </>
                      )}
                    </div>
                    <div className="text-base">
                      <div>{getRecruiter?.result?.companyName}</div>
                    </div>
                  </div>
                  <div className="flex flex-row bggray md:w-1/2 rounded-lg p-2 gap-4 hover:scale-105 transition">
                    <div className=" flex items-center text-xl jusitfy-center p-4 rounded-lg">
                      <FaUser />
                    </div>
                    <div className="text-base">
                      <div>Name</div>
                      <div>{getRecruiter?.result?.recruiterName}</div>
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row sm:flex-col gap-4 px-4 w-full">
                  <div className="flex flex-row bggray md:w-1/2 rounded-lg p-2 gap-4 hover:scale-105 transition">
                    <div className=" flex items-center text-xl jusitfy-center p-4 rounded-lg">
                      <IoMdMail />
                    </div>
                    <div className="text-base">
                      <div className="flex">
                        Email
                        {!editing &&
                          getRecruiter?.result?.isEmailVerify === 0 && (
                            <button
                              className="bodybg hover:bg-red-600 text-white  rounded-lg  py-1 text-sm font-bold px-2 ml-20"
                              onClick={emailModalOpen}
                            >
                              Verify Email
                            </button>
                          )}
                      </div>
                      <div>{getRecruiter?.result?.email}</div>
                    </div>
                  </div>

                  <div className="flex flex-row bggray md:w-1/2 rounded-lg p-2 gap-4 hover:scale-105 transition">
                    <div className=" flex items-center text-xl jusitfy-center p-4 rounded-lg">
                      <FaPhoneAlt />
                    </div>
                    <div className="text-base">
                      <div className="flex">
                        Mobile
                        {!editing &&
                          getRecruiter?.result?.isMobileVerify === 0 && (
                            <button
                              className="bodybg hover:bg-red-600 text-white font-bold rounded-lg   py-1 text-sm px-2 ml-20"
                              onClick={mobileModalOpen}
                            >
                              Verify Mobile
                            </button>
                          )}
                      </div>
                      <div>{getRecruiter?.result?.mobile}</div>
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row sm:flex-col gap-4 px-4 w-full">
                  <div className="flex flex-row bggray md:w-1/2 rounded-lg p-2 gap-4 hover:scale-105 transition">
                    <div className=" flex items-center text-xl jusitfy-center  p-4 rounded-lg">
                      <LiaCoinsSolid />
                    </div>
                    <div className="text-base">
                      <div>Credits Used</div>
                      <div>{getRecruiter?.result?.creditsUsed}</div>
                    </div>
                  </div>
                  <div className="flex flex-row bggray md:w-1/2 rounded-lg p-2 gap-4 hover:scale-105 transition">
                    <div className=" flex items-center text-xl jusitfy-center  p-4 rounded-lg">
                      <LiaCoinsSolid />
                    </div>
                    <div className="text-base">
                      <div>Credits Available</div>
                      <div>{getRecruiter?.result?.creditsAvailable}</div>
                    </div>
                  </div>
                </div>
                <div className="flex w-full px-4 ">
                  <div className="flex flex-row bggray md:w-full  rounded-lg p-2 gap-4  hover:scale-105 transition">
                    <div className=" flex items-center text-xl jusitfy-center p-4 rounded-lg">
                      <IoMdHome />
                    </div>
                    <div className="text-base">
                      <div>Address</div>
                      <div>{getRecruiter?.result?.address}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <EditProfileForm
                refetch={refetch}
                getRecruiter={getRecruiter?.result}
                refId={currentUser?.refId}
                handleCancel={handleCancel}
              />
            )}
            <div className="my-4 justify-center flex w-full items-center gap-4 ">
             {/*  {!editing && (
                <button
                  className="bodybg hover:bg-red-500 text-white font-bold py-2 px-4 ml-4 rounded-lg mt-4 transition ease-in-out hover:scale-105"
                  onClick={() => navigate("/changepassword")}
                >
                  Change Password
                </button>
              )} */}
              {!editing && <Changepassword/>}

              {!editing && (
                <Button color="danger" size="lg"
                  className="hover:bg-red-500 text-white font-bold rounded-lg "
                  onClick={handleEditProfile}
                >
                  Edit Profile
                </Button>
              )}
            </div>
          </div>

          <div className="flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
        {showLogoUploadModal && (
          <LogoUploadModal
            refetch={refetch}
            refId={currentUser?.refId}
            handleLogoUpload={closeLogoUploadModal}
          />
        )}
        {showEmailOTPModal && (
          <VerifyEmailModal
            refetch={refetch}
            candidateId={currentUser?.refId}
            closeEmailOTPModal={() => setEmailOTPModal(false)}
            emailId={getRecruiter?.result?.email}
          />
        )}
        {showMobileOTPModal && (
          <VerifyMobileModal
            refetch={refetch}
            candidateId={currentUser?.refId}
            closeMobileOTPModal={() => setMobileOTPModal(false)}
            mobile={getRecruiter?.result?.mobile}
          />
        )}
      </div>
    </div>
  );
};

const EditProfileForm = ({ getRecruiter, handleCancel, refId, refetch }) => {
  const [saveRecruiter] = useSaveRecruiterMutation();
  const [recruiterName, setRecruiterName] = useState(getRecruiter.recruiterName);
  const [address, setAddress] = useState(getRecruiter.address);
  const [companyName, setCompanyName] = useState(getRecruiter.companyName);
  const [mobile, setMobile] = useState(getRecruiter.mobile);
  const [email, setEmail] = useState(getRecruiter.email);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      const response = await saveRecruiter({ id: refId,recruiterName,companyName,address,mobile,email,});
      if (response?.data?.success) {
        toast.success("Profile updated successfully", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
        handleCancel();
        refetch();
      } else {
        toast.error("Failed to update profile", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
        navigate("/RecruiterDash");
      }
    } catch (error) {
      toast.error("Error:", error, {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    }
  };

  return (
    <div className="sm:flex sm:flex-col md:grid md:grid-cols-2  gap-3 rounded-lg m-10 px-10">
      <div className="flex flex-col gap-2">
        <label className="text-red-800 font-bold">Company Name</label>
        <input
          type="text"
          name="companyName"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          placeholder="Company Name"
          className="w-full mb-4 text-black px-4 py-2 border border-red-400 rounded-lg"
        />
      </div>

      <div className="flex flex-col gap-2">
        <label className="text-red-800 font-bold">Name</label>
        <input
          type="text"
          name="recruiterName"
          value={recruiterName}
          onChange={(e) => setRecruiterName(e.target.value)}
          placeholder="Recruiter Name"
          className="w-full mb-4 text-black px-4 py-2 border border-red-400 rounded-lg"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label className="text-red-800 font-bold">Email</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="w-full mb-4 text-black px-4 py-2 border border-red-400 rounded-lg"
        />
      </div>

      <div className="flex flex-col gap-2">
        <label className="text-red-800 font-bold">Mobile</label>
        <input
          type="text"
          name="mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          placeholder="Mobile"
          className="w-full mb-4 text-black px-4 py-2 border border-red-400 rounded-lg"
        />
      </div>
      <div className="flex flex-col gap-2 col-span-2">
        <label className="text-red-800 font-bold">Address</label>
        <textarea
          type="text"
          name="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Address"
          className="w-full mb-4 text-black px-4 py-2 border border-red-400 rounded-lg"
        />
      </div>
      <div className="flex gap-5">
        <Button color="success"
          className="bodybg hover:bg-red-500 text-black font-bold py-2 px-4 rounded mt-4 transition ease-in-out hover:scale-105"
          onClick={handleSubmit}
        >
          Update Profile
        </Button>
        <Button color="danger"
          className="bodybg hover:bg-red-500 text-black font-bold py-2 px-4 ml-4 rounded-lg mt-4 transition ease-in-out hover:scale-105"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default RecruiterProfile;
