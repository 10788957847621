import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { useGetJobCategoriesQuery } from "../../redux/slice/jobServiceApiSlice";

const Footer = () => {
  const { data: categories } = useGetJobCategoriesQuery("categoriesList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });



  return (
    <div className="font-custom-font ">
      <footer className="flex flex-col items-center  bg-gray-800 text-center md:text-left text-black sm:pt-4">
        <div className="container md:p-6 ">
          <div className="grid md:grid-cols-3 grid-cols-1 mb-10 ">
            <div className="relative mb-6 md:px-20">
              <h5 className="mb-2.5 font-bold text-white ">CATEGORIES</h5>
              <div className="flex flex-col gap-1">
              {categories?.result?.length > 0 &&
                categories?.result?.map(category=> (
                  <Link
                    key={category.id}
                    className=" text-white hover:scale-105 transition cursor-pointer"
                    to={`/JobSearch?catId=${category.id}`}
                  >
                    {category.categoryName}
                  </Link>
                ))}
                </div>
            </div>
            <div className="relative mb-6 md:px-20">
              <h5 className="mb-2.5 font-bold text-white ">POLICIES</h5>
              <div className="mb-0 list-none">
                <div className="py-1">
                  <Link
                   to="/TermsAndConditions"
                    className="font-semibold text-white hover:scale-105 transition cursor-pointer"
                  >
                    Terms & Conditions
                  </Link>
                </div>
                <div className="py-1">
                  <Link
                   to="/PrivacyPolicy"
                    className="font-semibold text-white hover:scale-105 transition cursor-pointer"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
            <div className="mb-6 md:px-20">
              <Link
                to="https://www.vilacampus.com"
                target="_blank"
                className="flex mb-4 font-bold text-white hover:scale-105 transition"
              >
                ABOUT
              </Link>

              <h5 className="mb-2.5 mt-8 font-bold uppercase  text-white dark:text-neutral-200 ">
                Contact Info
              </h5>

              <div className="mb-0 list-none">
                <div className="py-1 text-nowrap">
                  <div className="text-neutral-800 dark:text-neutral-200 flex flex-row flex-wrap items-center">
                    <span className="flex flex-row items-center text-white text-sm gap-2 font-bold">
                      <FaPhoneAlt />
                      Phone:
                    </span>
                    <span className="text-gray-400 ml-2">+91 9505599969</span>
                  </div>
                </div>
                <div className="py-1">
                  <div className="text-neutral-800 dark:text-neutral-200 flex flex-row flex-wrap  items-center">
                    <span className="flex flex-row items-center text-white text-sm gap-2 font-bold ">
                      <IoMdMail />
                      EMAIL:
                    </span>
                    <span className="flex text-gray-400 ml-2 hover:scale-105 hover:text-white transition ">
                      <a href="mailto:info@vilacampus.com">
                        info@vilacampus.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center w-full p-4 text-center text-gray-400 bg-gray-900 dark:text-neutral-200">
          © 2024 Copyright:{" "}
          <div className="text-white ml-2 mr-2"> Silver Screen Book</div> All Rights
          Reserved
        </div>
      </footer>
    </div>
  );
};

export default Footer;
