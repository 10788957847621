import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect, useState, useCallback } from 'react';
import { FormLabel } from '@mui/joy';
import { convertBase64 } from './PersonalDetails';
import { AiFillFileAdd, AiTwotoneCloseCircle } from "react-icons/ai";
import { useAddImageProfileMutation, useGetUserTotalPhotosQuery } from '../../../redux/slice/ProfileApiSlice';
import { toast } from 'react-toastify';
import { IoImagesOutline } from 'react-icons/io5';
import PhotoComponent from '../PhotoComponent/PhotoComponent';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};

const PhotosU = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [imageUrl, setImageUrl] = useState("");
    /* const [pageNumber, setPageNumber] = useState(1);
    const [refreshPosts, setRefreshPosts] = useState(false); 
    const [posts, setPosts] = useState([]);*/
    const currentUser = JSON.parse(localStorage.getItem('vlinks'));
    const [addImageProfile] = useAddImageProfileMutation();
    const { data: photos, refetch } = useGetUserTotalPhotosQuery(currentUser?.refId, { refetchOnFocus: true, refetchOnMountOrArgChange: true, pollingInterval: 6000 });
    /* const [getUserTotalPostsRS] = useGetUserTotalPostsMutation();
    const pageSize = 1;

    const fetchPosts = useCallback(async () => {
        const response = await getUserTotalPostsRS({ profileId: currentUser.refId, pageSize, pageNumber });
        if (response?.data?.result) {
            if (pageNumber === 1) {
                setPosts(response.data.result);
            } else {
                setPosts(prevPosts => [...prevPosts, ...response.data.result]);
            }
        }
    }, [getUserTotalPostsRS, currentUser.refId, pageSize, pageNumber]);

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts, pageNumber, refreshPosts]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 250;
            const scrolledToBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - scrollThreshold;
            const scrolledToTop = document.documentElement.scrollTop === 0; 
            if (scrolledToBottom) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []); */

    const handleClose = () => {
        setOpen(false);
        setImageUrl("");
    };

    const handleChange = async (e) => {
        const file = e.target.files[0];
        const imageUrl = await convertBase64(file);
        setImageUrl(imageUrl);
        e.target.value = null;
    };

    const handleSave = async () => {
       // console.log({ profileId: currentUser.refId, imageUrl });
        const res = await addImageProfile({ profileId: currentUser.refId, imageUrl });
        if (res?.data?.success) {
            toast.success("New Photo Added successfully", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
            refetch()
            /*             setPageNumber(1); // Reset page number to 1
                        setRefreshPhotos(prev => !prev); */
            setImageUrl("");
            setOpen(false);
        } else {
            toast.error("Can't add the new Photo", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
        }
    };
    return (
        <div className='wrapper'>
            <div className="flex justify-between font-custom-font flex-row gap-4">
                <p className='font-medium text-lg'>PHOTOS:</p>
                <Button onClick={handleOpen} startDecorator={<IoImagesOutline />} color="success" className='flex gap-1'>Add Photo</Button>
            </div>
            <div className="flex flex-wrap items-center gap-4 mt-6">
                {photos?.result?.length > 0 && photos?.result?.map(photo => <PhotoComponent photo={photo} key={photo.profileImageId} refetch={refetch} />)}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Photo
                    </Typography>
                    <div className='mt-4 flex flex-col gap-3'>
                        <FormLabel>Photo:</FormLabel>
                        <div className='flex flex-col gap-2 relative'>

                            {(imageUrl) && <div className="px-1 flex justify-between flex-row w-full absolute z-10">
                                <Button variant="plain" size="sm" component="label" htmlFor="fileInput" >
                                    Change
                                    <input id="fileInput" type="file" accept="image/*" onChange={handleChange} className="hidden" />
                                </Button>
                                <div>
                                    <Button variant="plain" onClick={() => { setImageUrl(""); }}>
                                        <AiTwotoneCloseCircle size="20" />
                                    </Button>
                                </div>
                            </div>}
                            {imageUrl && <div className='w-full h-[200px] overflow-auto'>
                                <img src={imageUrl} alt="Preview" className='w-full' />
                            </div>}
                            {(!imageUrl) && <label className='w-full h-[200px] justify-center flex items-center bg-gray-200 text-sm font-medium flex-col gap-1' htmlFor='image'><span><AiFillFileAdd size="18" /></span>Upload Image</label>}
                            <input type='file' accept="image/*" id="image" onChange={handleChange} className='hidden' />
                        </div>
                    </div>
                    <div className='flex justify-end gap-6 mt-4'>
                        <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
                        <Button onClick={handleSave} size="md" variant="solid" color="success" disabled={imageUrl === ""}>Save</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default PhotosU;