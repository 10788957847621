
import ReactHtmlParser from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';
import { useGetCandidateProfileQuery } from "../../../redux/slice/jobServiceApiSlice";
import { useParams } from 'react-router-dom';

const UserAboutSU = () => {
  const { userId } = useParams()
  const { data: getCandidateProfile, isSuccess } = useGetCandidateProfileQuery(userId,{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true});

  return (
    <div className="wrapper">
      <div className="flex justify-between font-custom-font flex-row gap-4 mb-3">
        <p className='font-medium text-lg'>About:</p>
       </div>
      {isSuccess && getCandidateProfile?.result?.profileDescription && <div>
        {ReactHtmlParser(getCandidateProfile?.result?.profileDescription)}
      </div>}
    </div>
  )
}

export default UserAboutSU