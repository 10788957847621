import Card from '@mui/joy/Card';
import { API_STATIC_URL } from '../../../apiConfig';
import { Avatar, Button, Divider, } from "@mui/joy";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineLike } from 'react-icons/ai';
import { FaFacebook, FaRegCommentDots } from 'react-icons/fa';
import { IoIosLink, IoMdSend, IoMdShareAlt } from 'react-icons/io';
import { useEffect, useRef, useState } from 'react';
import InputEmoji from "react-input-emoji";
import { useCommentPostByUserMutation, useGetPostCommentsByPostIdQuery, useLikePostByUserMutation, useUnlikePostByUserMutation } from '../../../redux/slice/ProfileApiSlice';
import Comments from './Comments';
import DeletePost from './DeletePost';
import EditPost from './EditPost';
import { BiSolidLike } from "react-icons/bi";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FaSquareWhatsapp, FaXTwitter } from 'react-icons/fa6';
import ImageScreen from './ImageScreen';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};

const PostComponent = ({ post,userId,setPageNumber,setRefreshPosts,type }) => {
    const [day, month, year] = post?.postDate.split('/').map(Number);
    const dateObject = new Date(year, month - 1, day);
    const formattedDate = Intl.DateTimeFormat('en-US', { day: "numeric", month: 'long', year: 'numeric' }).format(dateObject);
    const [ openComment,setOpenComment]=useState(false)
    const containerRef = useRef(null);
    const [ comment,setComment]=useState("")
    const [ likedPost,setLikedPost]=useState(false)
    const navigate =useNavigate()
    const [commentPostByUser]=useCommentPostByUserMutation()
    const location =useLocation()
    const {data:commentsRelatedPosts,refetch}=useGetPostCommentsByPostIdQuery(post?.id,{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
    const [likePostByUser,{data:getlike}]=useLikePostByUserMutation()
    const [unlikePostByUser,{data:getunlike}]=useUnlikePostByUserMutation()
    const [postLikeId,setPostLikeId]=useState(0)
    const [ZoomIn,setZoomIn]=useState(false)

    useEffect(()=>{
        if(post?.postLikeId){setLikedPost(true);setPostLikeId(post?.postLikeId)}
    },[post?.postLikeId])

    useEffect(()=>{
        if(getlike?.result>0){
            setPostLikeId(getlike?.result)
        }else if(getunlike?.result>0){
            setPostLikeId(0)
        }
    },[getlike,getunlike])

    async function handleOnEnter() {
       await commentPostByUser({postId:post?.id,postComments:comment,userId})
       refetch()
    }

    const handleCommentStatus =()=>{
        if(userId){
            setOpenComment(p=>!p);setComment("")
        }else{
            navigate("/login")
        }
        
    }

    const handleLikeStatus = async()=>{
        if(userId){
            if(likedPost&&postLikeId){
               await unlikePostByUser({id:postLikeId,postId:post?.id})
                setLikedPost(false)
            }else{
                await  likePostByUser({postId:post?.id,userId})
                setLikedPost(true)
            }
        }else{
            navigate("/login")
        }
        
    }
    
    const scrollToBottom = () => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      };

    useEffect(() => {
        scrollToBottom();
      }, [commentsRelatedPosts]);

    return (
        <Card  className="w-full" variant="soft">
            <div className='flex flex-row justify-between gap-4'>
            <div className='flex flex-row gap-1 items-center'>
                {post?.profileImageUrl ? (
                    <Avatar
                        alt="Profile Pic"
                        src={`${API_STATIC_URL}${post?.profileImageUrl}`}
                        sx={{ width: 40, height: 40 }}
                    />
                ) : (
                    <Avatar sx={{ width: 40, height: 40 }} color="success">
                        <p className="text-xl">{post?.fullName?.charAt(0)}</p>
                    </Avatar>
                )}
                <div className="flex flex-col justify-start items-start  text-sm  ">
                    <Link to={`/profileDetails/${post?.profileId}`} className='hover:underline'>{post?.fullName}</Link>
                    <Link to={`/post/${post?.id}`}  className='hover:underline'>{formattedDate}</Link>
                </div>
            </div>
            {location.pathname==="/userProfile/posts"&&<div className='flex'>
            {/* <Button variant='plain' size="sm" className='flex gap-1' ><MdEdit  size="18"/>Edit</Button> */}
            {/* <Button variant='plain' size="sm" color="danger" className='flex gap-1' ><MdDelete  size="18"/>Delete</Button> */}
            <EditPost post={post} setPageNumber={setPageNumber} setRefreshPosts={setRefreshPosts}/>
            <DeletePost id={post?.id} setPageNumber={setPageNumber} setRefreshPosts={setRefreshPosts}/>
            </div>}
            </div>
            <div className='w-full text-wrap text-lg font-medium'>
                {post?.postText}
            </div>
            <div className='w-full relative'>
                {post?.imageUrl && < ><img src={`${API_STATIC_URL}${post?.imageUrl}`} onClick={()=>setZoomIn(true)} alt={post?.fullName} className={`max-w-full mx-auto relative h-[400px] hover:cursor-pointer`}/>
                {ZoomIn&&<ImageScreen imageUrl={post?.imageUrl} setZoomIn={setZoomIn}/>}
                </>}
                {post?.videoUrl && (
                    <video controls className='max-w-full mx-auto'>
                        <source src={`${API_STATIC_URL}${post?.videoUrl}`} type="video/mp4"/>
                    </video>
                )}
            </div>
            <div className="flex flex-row justify-between w-full">
                <Button variant='plain' className='flex gap-1'  onClick={handleLikeStatus}>{likedPost ? <BiSolidLike size="18" />:<AiOutlineLike size="18"/>}{likedPost ?"Liked":"Like"}</Button>
                <Button variant='plain' className='flex gap-1' onClick={handleCommentStatus}><FaRegCommentDots  size="18"/>Comment</Button>
                <ShareModel postId={post?.id}/>
            </div>
            {openComment&&<Divider/>}
            {openComment&&<div className='bg-white'>
            {commentsRelatedPosts?.result?.length>0&& <div className='overflow-auto h-[200px] w-full'ref={containerRef}>
              {commentsRelatedPosts?.result?.map(comment =><Comments key={comment.commentId} comment={comment} userId={userId} />)}
              </div>}
            <InputEmoji buttonElement={<IoMdSend  />} /* endDecorator={<IoMdSend  />} */ className="border-none border-0" placeholder="write comment..." borderColor='#000' cleanOnEnter value={comment} onChange={(value)=>setComment(value)}  onEnter={handleOnEnter} />
            </div>}
        </Card>
    )
}




const ShareModel =({postId})=>{
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () =>setOpen(false)
    const location = useLocation();
    const baseUrl = window.location.origin;
    const fullPath = `${baseUrl}/post/${postId}`;
    
    const handleCopyLink = () => {
        navigator.clipboard.writeText(fullPath);
        setOpen(false);
      };

 return   <div>
 <Button onClick={handleOpen} variant='plain' className='flex gap-1' ><IoMdShareAlt  size="18"/>Share</Button>
 <Modal
     open={open}
     onClose={handleClose}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
 >
     <Box sx={style}>
         <Typography id="modal-modal-title" variant="h6" component="h2">
             Share Post
         </Typography>
         <div className=' mt-4 flex  gap-5'>
             <FacebookShareButton url={fullPath}> <FaFacebook size={28} color="blue" /> Facebook</FacebookShareButton>
            <WhatsappShareButton url={fullPath} title="product image"> <FaSquareWhatsapp size={28} color="green" />Whatsapp</WhatsappShareButton>
            <TwitterShareButton url={fullPath}> <FaXTwitter size={28} color="black" />Twitter</TwitterShareButton>
            <Button variant='plain' onClick={handleCopyLink} className='flex flex-col' color='black'><IoIosLink size={28} color="black" />Copy</Button>
         </div>
     </Box>
 </Modal>
</div>
}


export default PostComponent
