import { useEffect, useState } from "react";
import { FaBriefcase, FaMoneyBillWave } from "react-icons/fa";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { FcApproval } from "react-icons/fc";
import { MdOutlinePendingActions } from "react-icons/md";
import { Link } from "react-router-dom";
import { useGetRecruiterTotalJobsMutation } from "../../../redux/slice/recruiterServiceApiSlice";

export const SelectedRecruiterJobs = ({ id }) => {
    const [getRecruiterTotalJobsS, { data: getRecruiterTotalJobs, isLoading }] =useGetRecruiterTotalJobsMutation();
    const [totalPages, setTotalPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 10;
  
    useEffect(() => {
      setTotalPages(Math.ceil(getRecruiterTotalJobs?.dataCount / pageSize));
    }, [getRecruiterTotalJobs?.dataCount, pageSize]);
  
    useEffect(() => {
      getRecruiterTotalJobsS({ id, isActive: 1, pageSize, pageNumber });
    }, [id, pageNumber, pageSize, getRecruiterTotalJobsS]);
  
    const handlePageChange = async (pageNumber) => {
      setPageNumber(pageNumber);
    };
  
    const generatePaginationButtons = () => {
      const buttons = [];
  
      if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
          buttons.push(i);
        }
      } else {
        let startPage = Math.max(1, pageNumber - 2);
        let endPage = Math.min(pageNumber + 2, totalPages);
  
        if (endPage === totalPages) {
          startPage = Math.max(1, totalPages - 4);
        } else if (startPage === 1) {
          endPage = Math.min(5, totalPages);
        }
  
        if (startPage > 1) {
          buttons.push(1);
          if (startPage > 2) {
            buttons.push("...");
          }
        }
  
        for (let i = startPage; i <= endPage; i++) {
          buttons.push(i);
        }
  
        if (endPage < totalPages) {
          if (endPage < totalPages - 1) {
            buttons.push("...");
          }
          buttons.push(totalPages);
        }
      }
      return buttons;
    };
  
    let content;
    if (isLoading)
      content = (
        <div className=" text-blue-500 font-bold text-xl ">Loading....</div>
      );
  
    if (getRecruiterTotalJobs?.result?.length > 0) {
      content = (
        <div className=" px-2 text-blue-500 flex flex-row gap-6 font-bold text-xl h-full">
          <div className=" flex flex-col w-full gap-4">
            <p className="flex justify-center w-full px-5 mx-auto">
              Recruiter Jobs
            </p>
            <div className=" flex flex-col w-full  gap-2 font-custom-font">
              {getRecruiterTotalJobs?.result.map((job) => (
                <Recruiterjobs job={job} key={job.id} />
              ))}
            </div>
            <div className="flex gap-2 mt-5 px-2">
              {generatePaginationButtons().map((button, index) => (
                <button
                  key={index}
                  className={`rounded-lg bg-green-400 px-3 py-1 text-sm font-semibold  border hover:scale-110 transition border-green-400 hover:bg-yellow-500 ${
                    button === "..." ? "opacity-0" : ""
                  } ${
                    pageNumber === button
                      ? "bg-green-600 text-white"
                      : "text-black"
                  }`}
                  onClick={() =>
                    typeof button === "number" ? handlePageChange(button) : null
                  }
                >
                  {button}
                </button>
              ))}
            </div>
          </div>
        </div>
      );
    }
    if (getRecruiterTotalJobs?.result?.length === 0) {
      content = (
        <div className="px-2 py-4 text-blue-500 font-bold text-xl">
          Selected Recruiter Not Posted Any Jobs
        </div>
      );
    }
    return <>{content}</>;
  };
  
 export const Recruiterjobs = ({ job }) => {
    return (
      <Link
        className="shadow-lg p-3 md:w-md flex flex-col gap-4  border-2 text-black  hover:bg-blue-200"
        to={`/admindash/jobdetailsadmin/${job.id}`}
      >
        <div className="flex justify-between gap-4  items-center">
          <p className="flex justify-end">{job.jobTitle}</p>
          {job.isApproved ? (
            <p className="text-green-600 items-center text-sm  flex gap-2">
              <FcApproval size="18" color="green" />
              Approved
            </p>
          ) : (
            <p className="text-red-500 items-center  text-sm flex gap-2">
              <MdOutlinePendingActions size="18" color="red" />
              Require Approval
            </p>
          )}
        </div>
        <div className="text-sm flex justify-between gap-4 font-semibold">
          <p>{job.recruiterName}</p>
          <p>{job.categoryName}</p>
          <p>{job.subcategoryName}</p>
        </div>
        <div className="text-sm flex justify-between gap-4 font-semibold">
          <p className="flex gap-2 items-center"><FaPhone />{job.mobile}</p>
          <span className="flex flex-row  md:justify-center items-center">
            <FaLocationDot className="mx-2" /> {job.location}
          </span>
          {job.salary&&<span className="flex flex-row mx-3 md:justify-center items-center">
            <FaMoneyBillWave className="mx-2" /> {job.salary}
          </span>}
         
        </div>
      </Link>
    );
  };