
import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MdEdit } from 'react-icons/md';
import Textarea from '@mui/joy/Textarea';
import { useEffect, useState } from 'react';
import {  useUpdateProfileTitleMutation } from '../../../redux/slice/jobServiceApiSlice';
import InputEmoji from "react-input-emoji";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};

const ProfileBio = ({ about,refetch,refId }) => {
    const [open, setOpen] =useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [profileTitle,setProfileTitle]=useState()
    const [updateProfileTitle]=useUpdateProfileTitleMutation()
    useEffect(()=>{setProfileTitle(about)},[about])
    
    const handleSave =async()=>{
        await updateProfileTitle({profileId:refId,profileTitle})
        refetch()
        setProfileTitle("")
        setOpen(false);
    }

    function handleOnEnter(text) {
        setProfileTitle(text);
    }
      
    return (
        <div >
            <Button onClick={handleOpen} variant="plain" ><MdEdit color='black' size={18} /></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Profile Bio
                    </Typography>
                    <div className='mt-4'>
                    {/* <Textarea placeholder="Profile Description" minRows={2} value={profileTitle} onChange={e=>setProfileTitle(e.target.value)}/> */}
                    <InputEmoji placeholder="Message your thoughts..." borderColor='#000' cleanOnEnter initialValue={profileTitle} value={profileTitle} onChange={value=>setProfileTitle(value)} /* onEnter={handleOnEnter} *//>
                    </div>
                    <div className=' flex justify-end gap-4'>
                        <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
                        <Button onClick={handleSave} size="md" variant="solid" color="success">Save</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default ProfileBio
