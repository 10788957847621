
import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MdEdit } from 'react-icons/md';
import { useEffect, useState } from 'react';
import {  useUploadSocialMediaLinksMutation } from '../../../redux/slice/jobServiceApiSlice';
import { FormLabel, Input } from '@mui/joy';
import { FaFacebook } from 'react-icons/fa';
import { IoLogoYoutube } from 'react-icons/io';
import { BsInstagram } from 'react-icons/bs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};

const SocialMediaLinks = ({ youtube,instagram,facebook, refetch, refId }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [facebookLink, setFacebookLink] = useState("")
    const [instagramLink, setInstagramLink] = useState("")
    const [youtubeLink, setYoutubeLink] = useState("")
    const [uploadSocialMediaLinks] = useUploadSocialMediaLinksMutation()

     useEffect(()=>{
        setFacebookLink(facebook)
        setInstagramLink(instagram)
        setYoutubeLink(youtube)
    },[youtube,instagram,facebook])

    const handleSave = async () => {
        await uploadSocialMediaLinks({ profileId: refId, facebookLink, instagramLink, youtubeLink })
        refetch()
        setFacebookLink("")
        setInstagramLink("")
        setYoutubeLink("")
        setOpen(false);
    }
    return (
        <div>
            <Button onClick={handleOpen} variant="plain" className='flex gap-2'>{!facebookLink&& !instagramLink&& !youtubeLink && "Add Links"}<MdEdit color='black' size={18} /></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Social Media Links
                    </Typography>
                    <div className=' mt-4 flex flex-col gap-3'>
                        <div className='flex flex-col gap-2'>
                            <FormLabel>Facebook Link:</FormLabel>
                            <Input startDecorator={<FaFacebook color="blue" size={20} />} color="neutral" variant="outlined" value={facebookLink} onChange={e => setFacebookLink(e.target.value)} />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <FormLabel>Instagram Link:</FormLabel>
                            <Input startDecorator={<BsInstagram color="brown" size={20} />} color="neutral" variant="outlined" value={instagramLink} onChange={e => setInstagramLink(e.target.value)} />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <FormLabel>Youtube Link:</FormLabel>
                            <Input startDecorator={<IoLogoYoutube color="red" size={20} />} color="neutral" variant="outlined" value={youtubeLink} onChange={e => setYoutubeLink(e.target.value)} />
                        </div>
                    </div>
                    <div className=' flex justify-end gap-4 mt-4'>
                        <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
                        <Button onClick={handleSave} size="md" variant="solid" color="success">Save</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default SocialMediaLinks
