import { useParams } from "react-router-dom"
import { useGetPostByIdQuery } from "../../../redux/slice/ProfileApiSlice"
import PostComponent from "../PostComponent/PostComponent";
import AdsComponent from "../../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../../apiConfig";


const SinglePost = () => {
    const {postId}=useParams()
    const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
     const {data}=useGetPostByIdQuery({userId:currentUser?.id,postId},{refetchOnFocus:true,refetchOnMountOrArgChange:true,pollingInterval:6000})

  return (
    <div className="wrapper flex gap-8 justify-between">
       <div className="my-2 flex items-center max-lg:hidden ">
              <div className="w-full">
                <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
              </div>
         </div>
      <div className="flex justify-center max-h-full md:w-[600px] mx-auto my-2">
      {data?.result&& <PostComponent post={data?.result} type="SP" userId={currentUser?.id}/>}
      </div>
      <div className="my-2 flex items-center max-lg:hidden">
              <div className="w-full">
                <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
              </div>
         </div>
    </div>
  )
}

export default SinglePost
