import { useGetUserTotalPhotosQuery } from '../../../redux/slice/ProfileApiSlice';
import { useParams } from 'react-router-dom';
import PhotoComponent from '../PhotoComponent/PhotoComponent';

const PhotosSU = () => {
  const { userId } = useParams()
  const { data: photos, refetch } = useGetUserTotalPhotosQuery(userId, { refetchOnFocus: true, refetchOnMountOrArgChange: true, pollingInterval: 6000 });
  
  return (
    <div className='wrapper'>
            <div className="flex justify-between font-custom-font flex-row gap-4">
                <p className='font-medium text-lg'>PHOTOS:</p>
            </div>
            <div className="flex flex-wrap items-center gap-4 mt-6">
                {photos?.result?.length > 0 && photos?.result?.map(photo => <PhotoComponent photo={photo} key={photo.profileImageId} refetch={refetch} />)}
            </div>
        </div>
  )
}

export default PhotosSU