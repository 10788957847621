import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FaFacebook } from "react-icons/fa";
import { Helmet } from "react-helmet";
import Divider from '@mui/material/Divider';
import { Avatar, } from "@mui/joy";
import { BsInstagram } from "react-icons/bs";
import { IoLogoYoutube } from "react-icons/io5";
import { useGetCandidateProfileQuery } from "../../../redux/slice/jobServiceApiSlice";
import { API_STATIC_URL } from "../../../apiConfig";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { useAddProfileRatingByUserMutation, useGetSelectedProfileByUserQuery } from "../../../redux/slice/ProfileApiSlice";

const navLinks = [
    { id: 1, to: '', text: 'Profile Details' },
    { id: 2, to: 'photos', text: 'Photos' },
    { id: 3, to: 'videos', text: 'Videos' },
    { id: 4, to: 'posts', text: 'Posts' },
    { id: 5, to: 'about', text: 'About' },
];


const UserProfileDetailsSU = () => {
    const { userId } = useParams()
    const [addProfileRatingByUser]=useAddProfileRatingByUserMutation()
    const currentUser = JSON.parse(localStorage.getItem('vlinks'));
    const { data: getCandidateProfile, refetch,isError,isLoading } = useGetSelectedProfileByUserQuery({profileId:userId,userId:currentUser?.id}, { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true });

    const handleRating=async(newValue)=>{
        if(getCandidateProfile?.result?.profile_rating_id){
          await addProfileRatingByUser({rating:newValue,profileId:userId,userId:currentUser?.id,ratingId:getCandidateProfile?.result?.profile_rating_id})
        }else{
            await addProfileRatingByUser({rating:newValue,profileId:userId,userId:currentUser?.id})
        }
        refetch()
    }
    if (isLoading) {
        return <div className="wrapper text-xl font-semibold">Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching data.</div>;
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Silver Screen Book - user Profile Details</title>
                <link rel="canonical" href="https://silverscreenbook.com/Details" />
            </Helmet>
            <div className="wrapper">
                <div className=" w-full  m-2 flex justify-between md:flex-row flex-col gap-2 md:items-center">
                    <div className=" flex flex-row  gap-6 items-center ">
                        <div className="relative ">
                            {getCandidateProfile?.result?.imageUrl ? (
                                <Avatar
                                    alt="Profile Pic"
                                    src={`${API_STATIC_URL}${getCandidateProfile?.result?.imageUrl}`}
                                    sx={{ width: 70, height: 70 }}
                                />
                            ) : (
                                <Avatar sx={{ width: 70, height: 70 }} color="success">
                                    <p className="text-2xl">{getCandidateProfile?.result?.fullName?.charAt(0)}</p>
                                </Avatar>
                            )}
                        </div>
                        <div className="flex flex-col ">
                            <h1 className="md:text-3xl sm:text-nowrap font-bold">{getCandidateProfile?.result?.fullName}</h1>
                            {getCandidateProfile?.result?.profileTitle && <div className="flex text-sm font-medium text-black items-center">{getCandidateProfile?.result?.profileTitle}</div>}
                        </div>
                    </div>
                    {currentUser?.refId !== Number(userId) && <div className="flex flex-col gap-1 md:justify-end items-end">
                        <p className="sm:text-nowrap font-bold">Rate This Profile</p>
                        <Stack spacing={1}>
                       <Rating name="half-rating" value={getCandidateProfile?.result?.rating||0} onChange={(event,newValue) => {handleRating(newValue)}} precision={0.5} />
                        </Stack>
                    </div>}
                </div>
                <Divider />
                <div className="my-4 ">
                    <div className="flex gap-20 items-center">
                        <p className="text-lg font-semibold">Social Media Link </p>
                    </div>
                    <div className="flex flex-row gap-4 justify-between flex-wrap mt-4">
                        {getCandidateProfile?.result?.facebookLink && <Link to={getCandidateProfile?.result?.facebookLink} target="_blank" className="flex gap-2 items-center hover:underline text-sm font-medium"><FaFacebook color="blue" size={20} />{getCandidateProfile?.result?.facebookLink}</Link>}
                        {getCandidateProfile?.result?.instagramLink && <Link to={getCandidateProfile?.result?.instagramLink} target="_blank" className="flex gap-2 items-center hover:underline text-sm font-medium"><BsInstagram color="brown" size={20} />{getCandidateProfile?.result?.instagramLink}</Link>}
                        {getCandidateProfile?.result?.youtubeLink && <Link to={getCandidateProfile?.result?.youtubeLink} target="_blank" className="flex gap-2 items-center hover:underline text-sm font-medium"><IoLogoYoutube color="red" size={20} />{getCandidateProfile?.result?.youtubeLink}</Link>}
                    </div>
                </div>
                <Divider />
                <div className="flex gap-6 flex-row flex-wrap my-4 text-sm font-semibold  ">
                    {navLinks.map(link => (
                        <NavLink key={link.id} to={link.to} className={({ isActive }) => isActive ? `text-red-700` : ""}>{link.text.toUpperCase()}</NavLink>
                    ))}
                </div>
                <Divider />
            </div>
            <Outlet />
        </>
    );
};

export default UserProfileDetailsSU;
