import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { ADSENSESLOTS, IMAGE_ROUTES } from "../../apiConfig";
import Toggle from "../GlobalComp/ToggleButton";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSendLoginEmailOTPMutation, useSendLoginMobileOTPMutation } from "../../redux/slice/notificationApiSlice";
import { toast } from "react-toastify";
import { useAuthenticateOTPMutation } from "../../redux/slice/userServiceApiSlice";
import { useParams } from "react-router-dom";

const LoginOTPUser = () => {
  const [isVerified, setIsVerified] = useState(true);
  const [sendLoginMobileOTP,{data:mobileOtpData}]  = useSendLoginMobileOTPMutation()
  const [authenticateOTP,{data:loginotpdata}]  = useAuthenticateOTPMutation()
  const [emailMobile, setEmailMobile] = useState(null);
  const [emailMobileEmpty, setEmailMobileEmpty] = useState(false);
  const [otpEmpty,setOtpEmpty]=useState(false)
  const [OTPSent,setOTPSent] =useState(false)
  const [OTP,setOTP] =useState("")
  const navigate =useNavigate()
 
    useEffect(()=>{
       if( mobileOtpData?.success){
        setOTPSent(true)
       }
    },[mobileOtpData])

    useEffect(()=>{
       if(loginotpdata?.success){
          navigate({
            pathname: '/JobSearch',
            search: `?${createSearchParams({ catId:1})}`,
          });
       }
    },[loginotpdata,navigate])




  const handleSubmit = async () => {
      const mobilePattern = /^[0-9]{10}$/; 
      if (!mobilePattern.test(emailMobile)) {
        setEmailMobileEmpty(true);
        toast.error("Please Enter valid Mobile No", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        return;
      }
      if(OTPSent){
        if(!OTP){
            setOtpEmpty(true);
            toast.error("Please Enter OTP", {
                autoClose: 1000,
                style: {
                  width: "300px",
                },
              });
            return;
        }else{
            await authenticateOTP({userName:emailMobile,userRoleId:3,otp:OTP})
        }
      }else{
        const res= await sendLoginMobileOTP({userName:emailMobile,userRoleId:3});
        if(!res?.data?.success){
            toast.error("Invalid Mobile No", {
                autoClose: 1000,
                style: {
                  width: "300px",
                },
              });
         }else{
          toast.success("OTP Sent to your Mobile", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
         }
      }
    } 
  
  

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Login</title>
        <link rel="canonical" href="https://silverscreenbook.com/Login" />
      </Helmet>

      <div className="flex md:flex-row sm:flex-col gap-4 justify-between items-center px-2  font-custom-font ">
      <div className="flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        <div className="md:min-h-screen w-[420px] flex items-center  justify-center md:py-2 sm:py-4 md:px-4">
          <div className="flex items-center flex-col space-y-5 md:px-10 w-full rounded-2xl border border-red-600 hover:border-red-900">
            <Link to="/">
              <img
                src={`${IMAGE_ROUTES.altLogo}`}
                alt="Logo"
                className="max-h-36"
              />
            </Link>
            <div className="flex flex-col items-center gap-5">
              <h2 className="relative text-center text-2xl font-extrabold text-red-900">
                Login to your account
              </h2>
            </div>

            <div className="relative w-11/12 space-y-5">
              <div className="rounded-md shadow-sm space-y-2">
                <label htmlFor="email" >Mobile Number</label>
                <input
                  id="email"
                  value={emailMobile??""}
                  onChange={(e) => setEmailMobile(e.target.value)}
                  className={`relative block w-full px-3 py-2 border ${
                    emailMobileEmpty
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  placeholder="Enter Mobile number"
                />

                {OTPSent && (
                  <div className="flex flex-row">
                    <input
                      id="otpInput"
                      value={OTP}
                      onChange={(e) => setOTP(e.target.value)}
                      className={`relative block w-full px-3 py-2 border ${
                        otpEmpty
                          ? "border-red-500 animate-shake"
                          : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                      placeholder="Enter OTP"
                    />
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>
                <div className="text-sm">
                  <Link
                    className="font-medium text-sm hover:text-red-700"
                    to={`/Login`}
                  >
                    Sign in with password
                  </Link>
                </div>
              </div>
              <div className="flex flex-col items-center">
                {/* <ReCAPTCHA
                  sitekey={RECAPCHASITEKEY}
                  size="normal"
                  onChange={handleVerify}
                /> */}
                <button onClick={handleSubmit} className="bodybg  w-full font-bold py-2 px-4 mt-2 rounded-lg" disabled={!isVerified}>
                  {OTPSent ? "Sign In" : "Send OTP"}
                </button>
              </div>
              <div>
                <div className="flex items-center justify-end mb-2 gap-4">
                  <div className="">New Here?</div>
                  <Link
                    to={`/register`}
                    className="bodybg hover:bg-red-500 font-bold py-3 px-5 h-10 flex items-center rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex   items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
      </div>
    </>
  );
};

export default LoginOTPUser;
