
import { ADSENSESLOTS } from "../../apiConfig";
import {
  useGetJobCategoriesQuery,
} from "../../redux/slice/jobServiceApiSlice";
import { Link } from "react-router-dom";
import AdsComponent from "../GlobalComp/AdsComponent";
import { useCallback, useEffect, useState } from "react";
import { useGetAllProfilePostsMutation } from "../../redux/slice/ProfileApiSlice";
import PostComponent from "../Profile/PostComponent/PostComponent";
import HomeProfiles from "./HomeProfiles";

const BrowsingJobs = () => {
  const [posts, setPosts] = useState([]);
  const pageSize = 10;
  const [getAllProfilePosts] = useGetAllProfilePostsMutation();
  const [pageNumber, setPageNumber] = useState(1);
  const currentUser = JSON.parse(localStorage.getItem('vlinks'));

  const { data: categories } = useGetJobCategoriesQuery("categoriesList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const fetchPosts = useCallback(async () => {
    const response = await getAllProfilePosts({userId:currentUser?.id, pageSize, pageNumber });
    if (response?.data?.result) {
      if (pageNumber === 1) {
        setPosts(response.data.result);
    } else {
        setPosts(prevPosts => [...prevPosts, ...response.data.result]);
    }
    }
  }, [getAllProfilePosts, pageSize, pageNumber,currentUser?.id]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts, pageNumber]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 250;
      const scrolledToBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - scrollThreshold;
      if (scrolledToBottom) {
        setPageNumber(prevPageNumber => prevPageNumber + 1);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <div className="w-full  text-black flex flex-col gap-4 py-10 md:px-12">
      <div className=" sm:ml-3 ">
        <h1 className="md:text-2xl mb-10 md:font-bold sm:text-lg sm:font-semibold">
          Browse Categories
        </h1>
        <div className="grid grid-cols-1  sm:text-sm w-full md:grid-cols-3 gap-2 px-2">
          {categories?.result?.length > 0 &&
            categories?.result?.map((category) => (
              <Link
                key={category.id}
                className="flex justify-between  border-2 bgcolorlite border-red-300 rounded-lg mx-1 mb-1 transition duration-300 text-lg px-4 ease-in-out transform hover:scale-105 "
                to={`/JobSearch?catId=${category.id}`}
              >
                {category.categoryName}
              </Link>
            ))}
        </div>
      </div>
      <div className="flex md:flex-row w-full gap-10 flex-col mt-10 sm:px-4 py-10">
      <div className="my-10 flex items-center max-lg:hidden lg:w-1/5">
              <div className="w-full">
                <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
              </div>
         </div>
        <div className="flex flex-col md:w-3/5 w-full">
          <h1 className="md:text-2xl flex  mb-10 md:font-bold sm:text-lg sm:font-semibold">
            Recent Posts
          </h1>
          <div className="flex flex-col gap-4 items-center w-full ">
            {posts?.length > 0 &&
              posts?.map(post => <PostComponent post={post} key={post.id} userId={currentUser?.id}  />)}
          </div>
        </div>
        <div className="md:w-1/5 w-full flex flex-col">
          <h1 className="md:text-2xl mb-10 md:font-bold sm:text-lg sm:font-semibold sm:mt-10 md:mt-0">
            Profiles
          </h1>
          <HomeProfiles />
        </div>
      </div>
    </div>
  );
};

export default BrowsingJobs;


/* 
import { API_STATIC_URL } from "../../apiConfig";
import Cards from "./Cards";
import Slides from "./Slides";
import { useNavigate } from "react-router-dom";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBriefcase } from "react-icons/fa";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import {
  useGetHomepageJobsQuery,
  useGetJobCategoriesQuery,
} from "../../redux/slice/jobServiceApiSlice";
import { Link } from "react-router-dom";

const BrowsingJobs = () => {
  const { data: homeJobs } = useGetHomepageJobsQuery("jobsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const { data: categories } = useGetJobCategoriesQuery("categoriesList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();

  const goToJobListing = (item) => {
    // Redirect to job details page
    navigate("/JobDetails");
  };


  return (
    <div className="sm:w-full bg-white py-10">
      <div className=" md:mx-20 mx-3 mb-10 ">
        <div className=" items-center mb-10 ">
          <h1 className="md:text-3xl mb-10 md:font-bold sm:text-lg sm:font-semibold">
            Browse Categories
          </h1>
        </div>
        <div className="md:grid grid-cols-1 sm:flex sm:flex-col sm:text-sm sm:w-full md:grid-cols-3 gap-1 ml-2 mr-2">
          {categories?.result?.length > 0 &&
            categories?.result?.map((category) => (
              <Link
                key={category.id}
                className="flex justify-between  border-2 bg-gray-100 border-gray-100 rounded-lg mx-1 mb-1 transition duration-300 text-lg px-4 ease-in-out transform hover:scale-105 hover:bg-gray-200"
                to={`/JobSearch?catId=${category.id}`}
              >
                {category.categoryName}
              </Link>
            ))}
        </div>
      </div>
      <div className="flex mt-10 bg-gray-100 rounded-lg md:flex-row w-full sm:flex-col p-20 sm:px-2">
        <div className=" flex flex-col md:pl-5 md:w-3/5 sm:w-full">
          <h1 className="md:text-3xl mb-10 md:font-bold sm:text-lg sm:font-semibold">
            Recent Jobs
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            {homeJobs?.result?.length > 0 &&
              homeJobs?.result?.map((item, index) => (
                <Link
                  key={item.jobId}
                  to={`/JobDetails/${item.jobId}`}
                  className="relative md:w-11/12 md:h-32 sm:w-full rounded-lg shadow-lg bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-100 transition ease-in-out transform hover:scale-105"
                >
                  <div className="flex md:flex-row sm:flex-col md:justify-between">
                    <div className="flex md:flex-row w-full md:items-center sm:flex-col m-2">
                      <div className="flex sm:flex-col">
                        <div className="font-bold md:text-2xl text-gray-800 md:my-3 flex flex-row gap-2 items-center ml-4">
                          {item.jobTitle}
                        </div>
                        <div className="flex md:flex-row sm:flex-col md:items-center sm:text-sm text-gray-600 ml-4">
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="md:h-10 md:w-10 sm:h-6 sm:w-6 rounded-full border shadow-lg m-2  md:ml-0"
                              src={`${API_STATIC_URL}${item.imageUrl}`}
                              alt={item.jobTitle}
                            />
                            <span className="flex flex-row mr-3 md:justify-center md:text-2xl font-semibold  md:ml-0 md:items-center">
                              {" "}
                              {item.companyName}
                            </span>
                          </div>
                          <span className="flex flex-row mx-3 md:justify-center md:items-center">
                            <FaLocationDot className="mx-2" /> {item.location}
                          </span>
                          <span className="flex flex-row mx-3 md:justify-center md:items-center">
                            <FaMoneyBillWave className="mx-2" /> {item.salary}
                          </span>
                          <span className="flex flex-row mx-3 md:justify-center md:items-center">
                            <FaBriefcase className="mx-2" />{" "}
                            {item.minExperience} to {item.maxExperience} yrs
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex sm:justify-start sm:ml-10 items-center md:w-1/5 ">
                      <div className="text-sm  text-gray-600">
                        {item.categoryName}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        <div className=" md:w-2/5 sm:w-full md:pr-5  flex flex-col">
          <h1 className="md:text-3xl mb-10 md:font-bold sm:text-lg sm:font-semibold sm:mt-10 md:mt-0">
            Featured Jobs
          </h1>
          <Slides />
          <div className="bg-gray-100 flex justify-center items-center ">
            <div className="flex w-full">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowsingJobs;
 */