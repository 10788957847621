import { apiSlice } from "../apiSlice";

export const jobServiceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getJobCategories:builder.query({
            query: (id) => ({ url: `/jobs/getJobCategories` }),
            providesTags: (result, error, arg) => {
                if (result?.result) {
                  return [
                    { type: 'Category', id: 'LIST' },
                    ...result.result.map((id) => ({ type: 'Category', id })),
                  ];
                } else return [{ type: 'Category', id: 'LIST' }];
              },
        }) ,
        getJobSubcategories:builder.query({
            query: (id) => ({ url: `/jobs/getJobSubcategories/${id}` }),
            providesTags: (result, error, arg) => {
                if (result?.result) {
                  return [
                    { type: 'SubCatecory', id: 'LIST' },
                    ...result.result.map((id) => ({ type: 'SubCatecory', id })),
                  ];
                } else return [{ type: 'SubCatecory', id: 'LIST' }];
              },
        }) ,
        getHomepageJobs:builder.query({
            query: (id) => ({ url: `/jobs/getHomepageJobs` }),
            providesTags: (result, error, arg) => {
              if (result?.result) {
                return [
                  { type: 'Job', id: 'LIST' },
                  ...result.result.map((id) => ({ type: 'Job', id })),
                ];
              } else return [{ type: 'Job', id: 'LIST' }];
            },
        }) ,
        getCandidateProfile:builder.query({
            query: (id) => ({ url: `/jobs/getCandidateProfile/${id}` }),
            providesTags: (result, error, id) => {
              if (result?.result) {
                return [{ type: 'Candidate', id }];
              } else return [{ type: 'Candidate', id: 'LIST' }];
            },
        }) ,
        getJobById:builder.query({
            query: (id) => ({ url: `/jobs/getJobById/${id}` }),
           providesTags:["Job"],
            /* providesTags: (result, error, id) => {
              if (result?.result) {
                return [{ type: 'Job', id }];
              } else return [{ type: 'Job', id: 'LIST' }];
            }, */
        }) ,
        getFeaturedJobs:builder.query({
            query: (id) => ({ url: `/jobs/getFeaturedJobs` }),
            providesTags: (result, error, arg) => {
              if (result?.result) {
                return [
                  { type: 'Job', id: 'LIST' },
                  ...result.result.map((id) => ({ type: 'Job', id })),
                ];
              } else return [{ type: 'Job', id: 'LIST' }];
            },
        }) ,
        filterJobs:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/filterJobs`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Job",id:"List"}]
        }), 
        applyJob:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/applyJob`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Job",id:"List"}]
        }), 
        updateCandidateProfile:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/updateCandidateProfile`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Candidate",id:"List"}]
        }), 
        uploadNewResume:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/uploadNewResume`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Candidate",id:"List"}]
        }), 
        updateProfileTitle:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/updateProfileTitle`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Candidate",id:"List"}]
        }), 
        updateProfileDescription:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/updateProfileDescription`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Candidate",id:"List"}]
        }), 
        uploadSocialMediaLinks:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/uploadSocialMediaLinks`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Candidate",id:"List"}]
        }), 
        verifyCandidateMobile:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/verifyCandidateMobile`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Candidate",id:"List"}]
        }), 
        verifyCandidateEmail:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/verifyCandidateEmail`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Candidate",id:"List"}]
        }), 
        updateCandidateProfilePic:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/updateCandidateProfilePic`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Candidate",id:"List"}]
        }), 
        getAppliedJobs:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/getAppliedJobs`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Job",id:"List"}]
        }), 
        adminJobsForApproval:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/adminJobsForApproval`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Job",id:"List"}]
        }), 
        adminJobsApproved:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/adminJobsApproved`,
                 method:"POST",
                 body:{...initialData}
                 }),
           invalidatesTags:["Job"]
        }), 
        adminApproveJob:builder.mutation({
            query: (initialData) => ({
                 url: `/jobs/adminApproveJob`,
                 method:"POST",
                 body:{...initialData}
                 }),
            invalidatesTags:[{type:"Job",id:"List"}]
        }), 
    }),
})
export const { 
  useGetJobCategoriesQuery,useGetHomepageJobsQuery,useGetFeaturedJobsQuery,useGetJobSubcategoriesQuery,
  useFilterJobsMutation,useGetJobByIdQuery,useGetCandidateProfileQuery,useUpdateProfileTitleMutation,
  useApplyJobMutation,useUpdateCandidateProfileMutation,useUploadNewResumeMutation,
  useVerifyCandidateEmailMutation,useVerifyCandidateMobileMutation,useUpdateCandidateProfilePicMutation,
  useGetAppliedJobsMutation,useUpdateProfileDescriptionMutation,useUploadSocialMediaLinksMutation,
  useAdminJobsForApprovalMutation,useAdminApproveJobMutation,useAdminJobsApprovedMutation,
}
=jobServiceApiSlice