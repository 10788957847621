import { useCallback, useEffect, useState } from "react"
import {  useGetHomeProfilesQuery } from "../../redux/slice/ProfileApiSlice";
import { Link } from "react-router-dom";
import { API_STATIC_URL } from "../../apiConfig";
import { Avatar } from "@mui/joy";


const HomeProfiles = () => {
    const {data:getHomeProfiles} =useGetHomeProfilesQuery("candidateList",{pollingInterval:6000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
    /*  const fetchPosts = useCallback(async () => {
        const response = await getHomeProfilesSS({  pageSize, pageNumber });
        if (response?.data?.result) {
            setHomeProfiles(prev => [...prev, ...response.data.result]);
        }
    }, [getHomeProfilesSS, pageSize, pageNumber]);
    
    useEffect(() => {
        fetchPosts();
    }, [fetchPosts, pageNumber]);
    
    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 250;
            const scrolledToBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - scrollThreshold;
           if (scrolledToBottom) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []); */   
  return (
    <div className="bg-gray-100 w-full flex flex-col gap-2 p-3 ">
        {getHomeProfiles?.result?.length>0 && getHomeProfiles?.result?.map(profile=><div key={profile.profileId} className='flex flex-row gap-1 items-center'>
                {profile?.imageUrl ? (
                    <Avatar
                        alt="Profile Pic"
                        src={`${API_STATIC_URL}${profile?.imageUrl}`}
                        sx={{ width: 40, height: 40 }}
                    />
                ) : (
                    <Avatar sx={{ width: 40, height: 40 }} color="success">
                        <p className="text-xl">{profile?.fullName?.charAt(0)}</p>
                    </Avatar>
                )}
                <div className="flex flex-col items-center justify-start  text-sm  ">
                    <Link to={`/profileDetails/${profile?.profileId}`} className='hover:underline'>{profile?.fullName}</Link>
                </div>
            </div>)}
    </div>
  )
}

export default HomeProfiles