
import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useState } from 'react';
import {  useDeletePostMutation} from '../../../redux/slice/ProfileApiSlice';
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};

const DeletePost = ({id,setPageNumber,setRefreshPosts}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [ deletePost]=useDeletePostMutation()
   
    const handleSave = async () => {
        const res = await deletePost(id);
        if (res?.data?.success) {
            toast.success("Post Deleted successfully", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
            setPageNumber(1); // Reset page number to 1
            setRefreshPosts(prev => !prev);

            setOpen(false);
        } else {
            toast.error("Can't delete  post", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
        }
    };

    return (
        <div className=''>
            <div className="flex  flex-row ">
            <Button variant='plain' onClick={handleOpen} startDecorator={<MdDelete  size="18" />} size="sm" color="danger"  >Delete</Button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Delete Post
                    </Typography>
                    <p className='text-lg'>
                        Are you sure, you want to delete the post?
                    </p>
                    <div className='flex justify-end gap-6 mt-4'>
                        <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
                        <Button onClick={handleSave} size="md" variant="solid" color="success">Delete</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default DeletePost;

