import React from 'react'
import RNavBar from './RNavbar'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'

const RootLayout = () => {
  return (
    <div className='m-0'>
      <RNavBar/>
      <div className='mt-20 min-h-96'>
      <Outlet/>
      </div>
      <Footer/>
    </div>
  )
}

export default RootLayout