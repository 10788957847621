
import { useEffect, useState, useCallback } from 'react';
import { useGetUserTotalPostsMutation } from '../../../redux/slice/ProfileApiSlice';
import { useParams } from 'react-router-dom';
import PostComponent from '../PostComponent/PostComponent';

const PostsSU = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [refreshPosts, setRefreshPosts] = useState(false);
    const [posts, setPosts] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('vlinks'));
    const { userId } = useParams()
    const [getUserTotalPostsRS] = useGetUserTotalPostsMutation();
    const pageSize = 10;

    const fetchPosts = useCallback(async () => {
        const response = await getUserTotalPostsRS({ profileId: userId, pageSize, pageNumber,selectedUserId:currentUser?.id });
        if (response?.data?.result) {
            if (pageNumber === 1) {
                setPosts(response.data.result);
            } else {
                setPosts(prevPosts => [...prevPosts, ...response.data.result]);
            }
        }
    }, [getUserTotalPostsRS, userId, pageSize, pageNumber,currentUser?.id]);

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts, pageNumber, refreshPosts]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 250;
            const scrolledToBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - scrollThreshold;
            if (scrolledToBottom) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <div className='wrapper'>
            <div className="flex justify-between font-custom-font flex-row gap-4">
                <p className='font-medium text-lg'>POSTS:</p>
            </div>
            <div className='flex md:w-[600px] mx-auto justify-center flex-col gap-2 mt-6 items-center'>
                {posts.map(post => <PostComponent post={post} key={post.id} userId={currentUser?.id} setPageNumber={setPageNumber} setRefreshPosts={setRefreshPosts} />)}
            </div>
        </div>
    );
}

export default PostsSU;