//completed
import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import { useGetJobCategoriesQuery } from '../../redux/slice/jobServiceApiSlice';
import { createSearchParams } from 'react-router-dom';

const NavSearch = () => {
    
    const [selectedCategory, setSelectedCategory] = useState('');
    const [title, setTitle] = useState('');
    const navigate = useNavigate();
    const {data:categories}=useGetJobCategoriesQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
   
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'category_id') {
            setSelectedCategory(value);
        } else if (name === 'title') {
            setTitle(value);
        }
    };

    const goToJobSearch = () => {
        navigate({
            pathname: '/JobSearch',
            search: `?${createSearchParams({ catId: selectedCategory,jobTitle:title })}`,
          });
       
    };

    return (
        <div className='text-center  md:bg-opacity-60 sm:bg-opacity-100 sm:flex sm:flex-col py-32  min-h-fit text-white'>
            <h1 className='md:text-6xl sm:text-2xl '>Start Shaping Your Future Now</h1>
            <h6 className='md:text-2xl mt-10 '>Streamline Your Search for the Perfect Job</h6>
            <div className='flex flex-row items-center justify-center mt-10'>
                <div className='flex md:flex-row sm:flex-col sm:items-center gap-2'>
                    <input type='text' placeholder='Enter Job Title' value={title} name="title" className='h-10 w-64 relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black' onChange={handleChange} />
                    <select id="categorySelect" children className='h-10 w-64 relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black' name="category_id" value={selectedCategory} onChange={handleChange}>
                        <option className='text-black' hidden value="">Select a category</option>
                        {categories?.result?.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.categoryName}
                            </option>
                        ))}
                    </select>
                    <button onClick={goToJobSearch} className=' bodybg h-10 flex items-center w-24 hover:bg-bodybg m-2 text-white font-bold py-4 px-10 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 '><FaSearch /></button>
                </div>
            </div>
        </div>
    )
}

export default NavSearch;
