import BrowseJobs from "./BrowseJobs";
import NavSearch from "./NavSearch";
import { Helmet } from "react-helmet";
import {  NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { RoutesNavigation, IMAGE_ROUTES } from "../../apiConfig";
import MobileNav from "./MobileNav";
import Footer from "../GlobalComp/Footer";

 const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Home</title>
        <link rel="canonical" href="https://silverscreenbook.com" />
      </Helmet>
      <div className="font-custom-font w-full">
        <HomePageNavBar/>
        <img src="/assets/homeImage.png" alt="homeImage" className="w-full h-[550px]"/>
        <div className="absolute top-[12%] lg:left-[23%] left-[15%]">
            <NavSearch />
        </div>
        <div className="relative z-3 ">
          <BrowseJobs />
        </div>
        <Footer/>
      </div>
    </>
  );
}; 

const HomePageNavBar=()=>{
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

   return (
        <div className={`fixed top-0  w-full px-3  ${isSticky? `bg-white`:""} shadow-lg  z-10 `}>
              <div className="flex items-center justify-between gap-4 ">
                <NavLink to="/" className="flex gap-1 p-1 py-5 items-center ">
                <img src={`${IMAGE_ROUTES.mainLogo}`} className="w-4" alt="logo" />
                <p className="text-red-700 text-xl  font-custom-font font-medium">SILVER SCREEN BOOK</p>
                </NavLink>
                <div className="max-lg:hidden">
                <NavigationRoutes/>
                </div>
                <MobileNav/>
              </div>
        </div>
      );
}

export const NavigationRoutes = ()=>{
  return(
    <div className="flex lg:flex-row flex-wrap flex-col gap-6 text-red-700 lg:text-xl max-lg:mx-4 text-lg font-custom-font">
      {RoutesNavigation.map(routenav=><NavLink key={routenav.id} to={routenav.route}>{routenav.name}</NavLink>)}
    </div>
  )
}

export default Home;


