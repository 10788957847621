//completed
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {API_STATIC_URL, imgdefault,} from "../../apiConfig";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBriefcase } from "react-icons/fa";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { useGetAppliedJobsMutation } from "../../redux/slice/jobServiceApiSlice";

const CandidateApplications = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [appliedJobs, { data: getAppliedJobs }] = useGetAppliedJobsMutation();
  const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
  const pageSize = 10;
  useEffect(() => {
    appliedJobs({ profileId: currentUser?.refId, pageSize, pageNumber: 1 });
  }, [currentUser?.refId,appliedJobs,pageSize]);

  useEffect(() => {
    if (getAppliedJobs?.dataCount) {
        setTotalPages(Math.ceil(getAppliedJobs.dataCount / pageSize));
    }
}, [getAppliedJobs, pageSize]);


  const goToJobListing = (jobId) => {
    navigate(`/JobDetails/${jobId}`);
  };

  const backToProfile = () => {
    navigate("/UserProfile");
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    appliedJobs({ profileId: currentUser?.refId,pageNumber,pageSize});
  };

  const generatePaginationButtons = () => {
    const buttons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(currentPage + 2, totalPages);

      if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - 4);
      } else if (startPage === 1) {
        endPage = Math.min(5, totalPages);
      }

      if (startPage > 1) {
        buttons.push(1);
        if (startPage > 2) {
          buttons.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        buttons.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          buttons.push("...");
        }
        buttons.push(totalPages);
      }
    }
    return buttons;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Applied Jobs</title>
        <link
          rel="canonical"
          href="https://silverscreenbook.com/CandidateApplications"
        />
      </Helmet>
        <div className=" flex flex-col font-custom-font md:flex-row mt-20 w-full items-center py-5 md::px-2 min-h-screen">
        <div className="my-4 flex items-center gap-2 sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          <div className="flex  flex-col w-full font-custom-font items-center gap-4 mt-5">
            <h1 className="font-bold md:text-4xl sm:text-xl mb-8">
              Your Job Applications
            </h1>
            {getAppliedJobs?.result?.length === 0 && (
              <div className="font-semibold md:text-xl ">
                You have not applied to any jobs yet.
              </div>
            )}
            {getAppliedJobs?.result?.length > 0 &&
              getAppliedJobs?.result?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => goToJobListing(item.jobId)}
                  className="relative md:w-4/5 md:h-32  sm:w-full rounded-lg shadow-lg bg-white border border-gray-200 hover:border-gray-300 hover:bg-gray-100 transition ease-in-out transform hover:scale-105"
                >
                  <div className="flex md:flex-row sm:flex-col md:justify-between text-nowrap">
                    <div className="flex md:flex-row w-full md:items-center sm:flex-col m-2">
                      <div className="flex sm:flex-col ">
                        <div className="font-bold md:text-xl text-gray-800 md:my-3 flex flex-row gap-2  items-center ml-4">
                          {item.jobTitle}
                        </div>
                        <div className="flex md:flex-row sm:flex-col md:items-center sm:justify-start text-sm text-gray-600 ml-4">
                          <div className="flex flex-row items-center justify-start">
                            <img
                              className="md:h-8 md:w-8 sm:h-6 sm:w-6 rounded-full border shadow-lg m-2 md:ml-0"
                              src={`${item.imageUrl ? `${API_STATIC_URL}${item.imageUrl}`: `${imgdefault}`}`}
                              alt={"logo"}
                            />
                            <span className="flex flex-row mr-3 font-bold md:text-xl md:justify-center  md:ml-0 md:items-center">
                              {" "}
                              {item.companyName}
                            </span>
                          </div>
                          <span className="flex flex-row mx-3 md:justify-center md:items-center">
                            <FaLocationDot className="mx-2" /> {item.location}
                          </span>
                          <span className="flex flex-row mx-3 md:justify-center md:items-center">
                            <FaMoneyBillWave className="mx-2" /> {item.salary}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex  md:items-center md:w-1/5">
                      <div className="text-sm text-gray-600 sm:ml-10 md:ml-0">
                        {item.categoryName}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className="flex gap-2 mt-5">
            {generatePaginationButtons().map((button, index) => (
                    <button
                      key={index}
                      className={`rounded-lg bodybg px-2 font-semibold  border hover:scale-110 transition border-red-400 hover:bg-red-500 ${
                        button === "..." ? "opacity-0" : ""
                      } ${
                        currentPage === button
                          ? "bg-yellow-600 text-white"
                          : "text-black"
                      }`}
                      onClick={() =>
                        typeof button === "number"
                          ? handlePageChange(button)
                          : null
                      }
                    >
                      {button}
                    </button>
                  ))}
            </div>
            <button
              className="rounded-lg font-semibold bodybg mt-10 px-6 py-2 hover:scale-110 hover:bg-yellow-500 transition"
              onClick={backToProfile}
            >
              Back
            </button>
          </div>
          <div className="my-4 flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
    </>
  );
};

export default CandidateApplications;
