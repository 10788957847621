import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { HiOfficeBuilding } from "react-icons/hi";
import { MdDescription } from "react-icons/md";
import {
    useGetCandidateProfileQuery,
    useUpdateCandidateProfileMutation,
    useUploadNewResumeMutation,
    useVerifyCandidateEmailMutation,
    useVerifyCandidateMobileMutation,
} from "../../../redux/slice/jobServiceApiSlice";
import {
    useSendVerifyEmailOTPMutation,
    useSendVerifyMobileOTPMutation,
} from "../../../redux/slice/notificationApiSlice";
import { createSearchParams } from "react-router-dom";
import Changepassword from "./ChangePassword";
import { Button } from "@mui/joy";
import { API_STATIC_URL } from "../../../apiConfig";

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};

const VerifyEmailModal = ({
    closeEmailOTPModal,
    emailId,
    candidateId,
    refetch,
}) => {
    const [verifyCandidateEmail] = useVerifyCandidateEmailMutation();
    const [OTP, setOTP] = useState(null);
    const submitEmailOTP = async () => {
        const payload = {
            candidateId,
            emailId,
            otp: OTP,
        };
        try {
            const response = await verifyCandidateEmail(payload);
            if (response.data.success === true) {
                toast.success("Email Verified", {
                    autoClose: 1000,
                    style: {
                        width: "300px",
                    },
                });
                refetch();
                closeEmailOTPModal();
            } else {
                toast.error("Invalid OTP and Try Again!", {
                    autoClose: 1000,
                    style: {
                        width: "300px",
                    },
                });
            }
        } catch (error) {
            toast.error(error, {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
        }
    };

    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="flex flex-col bg-red-300 rounded-lg p-8">
                <button
                    className="flex bg-red-500 self-end rounded-lg px-4 py-1 "
                    onClick={closeEmailOTPModal}
                >
                    X
                </button>
                <h2 className="text-xl font-bold mb-4">
                    Enter OTP sent to your Email Address
                </h2>
                <input className="h-10 " onChange={(e) => setOTP(e.target.value)} />

                <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg mt-4"
                    onClick={submitEmailOTP}
                >
                    Submit OTP
                </button>
            </div>
        </div>
    );
};

const VerifyMobileModal = ({
    closeMobileOTPModal,
    mobile,
    candidateId,
    refetch,
}) => {
    const [OTP, setOTP] = useState(null);
    const [verifyCandidateMobile] = useVerifyCandidateMobileMutation();
    const submitMobileOTP = async () => {
        const payload = {
            candidateId,
            mobile: mobile,
            otp: OTP,
        };
        try {
            const response = await verifyCandidateMobile(payload);
            if (response.data.success === true) {
                toast.success("Mobile Number Verified", {
                    autoClose: 1000,
                    style: {
                        width: "300px",
                    },
                });
                refetch();
                closeMobileOTPModal();
            } else {
                toast.error("Invalid OTP and Try Again!", {
                    autoClose: 1000,
                    style: {
                        width: "300px",
                    },
                });
            }
        } catch (error) {
            toast.error(error, {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
        }
    };

    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="flex flex-col bg-red-300 rounded-lg p-8">
                <button
                    className="flex bg-red-500 self-end rounded-lg px-4 py-1 "
                    onClick={closeMobileOTPModal}
                >
                    X
                </button>
                <h2 className="text-xl font-bold mb-4">
                    Enter OTP sent to your Mobile No.
                </h2>
                <input className="h-10 " onChange={(e) => setOTP(e.target.value)} />

                <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg mt-4"
                    onClick={submitMobileOTP}
                >
                    Submit OTP
                </button>
            </div>
        </div>
    );
};

const ResumeUploadModal = ({ closeModal, refId, refetch }) => {
    const [resume, setResume] = useState(null);
    const [preview, setPreview] = useState("");
    const [uploadNewResume] = useUploadNewResumeMutation();

    const handleResumeChange = async (e) => {
        const file = e.target.files[0];
        setResume(file);
        const previewBase64 = await convertBase64(file);
        setPreview(previewBase64);
    };
    const handleUpload = async () => {
        if (resume) {
            const payloadData = {
                profileId: refId,
                attachment: preview,
            };

            try {
                const response = await uploadNewResume(payloadData);
                // Handle response
                if (response.data.success === false) {
                    toast.error("Upload Failed", {
                        autoClose: 1000,
                        style: {
                            width: "300px",
                        },
                    });
                } else {
                    toast.success("Uploaded Successfully", {
                        autoClose: 1000,
                        style: {
                            width: "300px",
                        },
                    });
                    refetch(); // You might want to handle this based on your server response
                    closeModal();
                }
            } catch (error) {
                // Handle error
                console.error("Error uploading resume:", error);
            }
        } else {
            toast.error("Please select a file.", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
        }
    };

    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="flex flex-col bg-red-300 rounded-lg p-8">
                <button
                    className="flex bg-red-500 self-end rounded-lg px-4 py-1 "
                    onClick={closeModal}
                >
                    X
                </button>
                <h2 className="text-xl font-bold mb-4">Upload Resume</h2>
                <input type="file" onChange={handleResumeChange} />

                <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg mt-4"
                    onClick={handleUpload}
                >
                    Upload
                </button>
                {preview && <p className="my-4">Resume Preview:</p>}
                {preview && (
                    <embed
                        src={preview}
                        type="application/pdf"
                        width="100%"
                        height="500px"
                    />
                )}
            </div>
        </div>
    );
};
const PersonalDetails = () => {
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
    const { data: getCandidateProfile, refetch } = useGetCandidateProfileQuery(currentUser?.refId);
    const [updateCandidateProfile] = useUpdateCandidateProfileMutation();
    const [sendVerifyMobileOTP] = useSendVerifyMobileOTPMutation();
    const [sendVerifyEmailOTP] = useSendVerifyEmailOTPMutation();
    const [fullName, setFullName] = useState(""); // State to store full name input
    const [email, setEmail] = useState(""); // State to store email input
    const [address, setAddress] = useState(""); // State to store address input
    const [experience, setExperience] = useState(0); // State to store experience input
    const [mobile, setMobile] = useState("");
    const [showResumeModal, setShowResumeModal] = useState(false);
    const [showEmailOTPModal, setEmailOTPModal] = useState(false);
    const [showMobileOTPModal, setMobileOTPModal] = useState(false);

    useEffect(() => {
        if (getCandidateProfile?.result) {
            setFullName(getCandidateProfile?.result?.fullName); // State to store full name input
            setEmail(getCandidateProfile?.result?.email); // State to store email input
            setAddress(getCandidateProfile?.result?.address); // State to store address input
            setExperience(getCandidateProfile?.result?.experience); // State to store experience input
            setMobile(getCandidateProfile?.result?.mobile);
        }
    }, [getCandidateProfile?.result]);



    const emailModalOpen = () => {
        toast.success(`OTP sent to ${getCandidateProfile?.result?.email}`, {
            autoClose: 1000,
            style: {
                width: "300px",
            },
        });
        sendVerifyEmailOTP({ emailId: getCandidateProfile?.result?.email });
        setEmailOTPModal(true);
    };

    const mobileModalOpen = async () => {
        toast.success(`OTP sent to ${getCandidateProfile?.result?.mobile}`, {
            autoClose: 1000,
            style: {
                width: "300px",
            },
        });
        await sendVerifyMobileOTP({ mobile: getCandidateProfile?.result?.mobile });
        setMobileOTPModal(true);
    };

    const viewResume = (resumeContent) => {
        const newTab = window.open(`${API_STATIC_URL}${resumeContent}`, "_blank");
        if (newTab) {
            newTab.focus();
        } else {
            alert("Please allow popups for this site to view resumes.");
        }
    };

    const submitProfile = async () => {
        try {
            const requestData = {
                id: currentUser?.refId,
                fullName,
                email,
                address,
                experience,
                mobile: parseInt(mobile),
                // Note: Resume field is not included here as it is handled separately
            };

            const response = await updateCandidateProfile(requestData);
            if (response.data.success === false) {
                toast.error("Error is Updating Info", {
                    autoClose: 1000,
                    style: {
                        width: "300px",
                    },
                });
            } else {
                toast.success("Profile Updated successfully", {
                    autoClose: 1000,
                    style: {
                        width: "300px",
                    },
                });
                refetch();
                setEditing(false);
            }
        } catch (error) {
            toast.error(error, {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
        }
    };

    const goToBrowseJobs = () => {
        navigate({
            pathname: "/JobSearch",
            search: `?${createSearchParams({
                catId: getCandidateProfile?.result?.categoryId,
            })}`,
        });
    };
    return (<>
        <div className="wrapper">
            {!editing && getCandidateProfile?.result && (
                <div className="flex flex-col text-black p-6 rounded-lg  md:w-[700px] xl:w-[800px] w-full mx-auto sm:mx-2">
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-10">
                        <div className="flex flex-row bg-gray-300 rounded-lg p-2 gap-4 hover:scale-105 transition">
                            <div className=" flex items-center text-black md:text-3xl jusitfy-center bg-gray-300 p-4 rounded-lg">
                                <FaUser />
                            </div>
                            <div className=" text-black font-semibold flex flex-col justify-between">
                                <div className=" md:text-xl text-black font-semibold">
                                    Name
                                </div>
                                <div>{getCandidateProfile?.result.fullName}</div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-stretch items-center">
                            <div className="flex flex-row bg-gray-300  rounded-lg p-2 w-full gap-4 hover:scale-105 transition">
                                <div className=" flex items-center text-black md:text-3xl jusitfy-center p-4 rounded-lg">
                                    <FaPhoneAlt />
                                </div>
                                <div className=" text-black font-semibold flex flex-col justify-between">
                                    <div className=" md:text-xl text-black font-semibold flex">
                                        Mobile
                                        {getCandidateProfile?.result.isMobileVerify === 0 && (
                                            <button
                                                className="bg-gray-500 hover:bg-gray-600 text-white text-sm ml-7 px-2 rounded-lg shadow-lg transition ease-in-out hover:scale-105"
                                                onClick={mobileModalOpen}
                                            >
                                                Verify Mobile
                                            </button>
                                        )}
                                    </div>
                                    <div className="text-sm">
                                        {getCandidateProfile?.result.mobile}
                                    </div>
                                </div>
                            </div>
                            {/* {getCandidateProfile?.result.isMobileVerify === 0 && (
                                        <button className='bg-red-500 hover:bg-red-600 text-white w-1/5 font-bold h-1/2 ml-2 px-2 rounded-lg shadow-lg transition ease-in-out hover:scale-105' onClick={mobileModalOpen} >Verify Mobile</button>
                                    )} */}
                        </div>
                        <div className="flex flex-row justify-stretch items-center">
                            <div className="flex flex-row bg-gray-300 rounded-lg p-2 gap-4 w-full hover:scale-105 transition text-wrap">
                                <div className=" flex items-center text-black md:text-3xl jusitfy-center bg-gray-300 p-4 rounded-lg">
                                    <IoMdMail />
                                </div>
                                <div className=" text-black font-semibold flex flex-col justify-between text-wrap ">
                                    <div className="md:text-xl text-black font-semibold flex">
                                        Email
                                        {getCandidateProfile?.result.isEmailVerify === 0 && (
                                            <button
                                                className="bg-gray-500 hover:bg-gray-600 text-white text-sm ml-7 px-2 rounded-lg shadow-lg "
                                                onClick={emailModalOpen}
                                            >
                                                Verify Email
                                            </button>
                                        )}
                                    </div>
                                    <div className="flex text-balance text-sm">
                                        {getCandidateProfile?.result.email}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-item flex flex-col justify-center  items-center bg-white rounded-lg shadow-lg hover:scale-105 transition ease-in-out bg-opacity-50">
                            <div className="flex flex-row bg-gray-300 rounded-lg h-full p-4 w-full gap-4 transition">
                                <div className=" flex items-center text-black md:text-3xl jusitfy-center bg-gray-300 p-4 rounded-lg">
                                    <MdDescription />
                                </div>
                                <div className=" text-black font-semibold flex flex-col justify-between">
                                    <div className=" md:text-xl text-black font-semibold">
                                        Resume
                                    </div>
                                    <div>
                                        {getCandidateProfile?.result.profileAttachment ? (
                                            <button
                                                className="md:text-sm font-medium  text-white bg-gray-600 rounded-lg px-2 py-1"
                                                onClick={() =>
                                                    viewResume(
                                                        getCandidateProfile?.result.profileAttachment
                                                    )
                                                }
                                            >
                                                View Resume
                                            </button>
                                        ) : (
                                            <button
                                            onClick={() => setShowResumeModal(true)}
                                            className="edit-button bg-gray-600  hover:bg-gray-700  hover:scale-105 transition ease-in-out text-white font-medium text-sm py-1 px-2 rounded-lg"
                                        >
                                            Upload Resume
                                        </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row bg-gray-300 rounded-lg p-2 gap-4 w-full hover:scale-105 transition">
                            <div className=" flex items-center text-black md:text-3xl jusitfy-center bg-gray-300 p-4 rounded-lg">
                                <HiOfficeBuilding />
                            </div>
                            <div className=" text-black font-semibold flex flex-col justify-between">
                                <div className="md:text-xl text-black font-semibold">
                                    Address
                                </div>
                                <div className="">
                                    {getCandidateProfile?.result?.address}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex sm:flex-col md:flex-row justify-center mt-8 md:gap-8 gap-5">
                        {/* <button
                            className="bodybg hover:bg-red-600 text-white font-bold py-3 px-8 rounded-lg transition ease-in-out hover:scale-105 shadow-lg"
                            onClick={goToBrowseJobs}
                        >
                            Back
                        </button>

                        <button
                            onClick={() => setEditing(true)}
                            className="edit-button bodybg hover:bg-red-600 text-white font-bold py-3 px-8 rounded-lg shadow-lg transition ease-in-out hover:scale-105"
                        >
                            Edit Profile
                        </button> */}
                        <Button  onClick={goToBrowseJobs} className='flex gap-2 ' color="danger" size="lg">Back</Button>
                        <Button onClick={() => setEditing(true)} className='flex gap-2 ' color="danger" size="lg">Edit Profile</Button>
                        <Changepassword/>
                    </div>
                </div>
            )}
            {editing && (
                <div className="text-center flex flex-col bg-gray-300 gap-2 md:w-2/5 sm:w-4/5 sm:mx-2 p-8 rounded-lg shadow-lg">
                    <h1 className="md:text-xl sm:text-xl font-bold mb-8 text-black">
                        Update Your Profile
                    </h1>
                    <h3 className="flex w-full mx-2 text-black text-lg font-bold">
                        Name :
                    </h3>
                    <input
                        type="text"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder="Full Name"
                        className="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black"
                    />
                    <h3 className="flex w-full mx-2 text-black text-lg mt-2 font-bold">
                        Email :
                    </h3>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black"
                    />
                    <h3 className="flex w-full mx-2 text-black  text-lg mt-2 font-bold">
                        Mobile :
                    </h3>
                    <input
                        type="text"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder="Mobile"
                        className="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black"
                    />
                    <h3 className="flex w-full mx-2 text-black text-lg mt-2 font-bold">
                        Address :
                    </h3>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address"
                        className="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black"
                    />

                    <div className="flex md:flex-row md:gap-10 sm:flex-col sm:gap-2 mt-2 justify-center">
                        <button
                            className="bodybg  hover:bg-black  hover:scale-105 transition ease-in-out text-white font-bold py-2 px-4 rounded-lg"
                            onClick={() => setEditing(false)}
                        >
                            Back
                        </button>
                        <button
                            onClick={() => setShowResumeModal(true)}
                            className="edit-button bodybg  hover:bg-black  hover:scale-105 transition ease-in-out text-white font-bold py-2 px-4 rounded-lg"
                        >
                            Upload Resume
                        </button>
                        <button
                            className="bodybg  hover:bg-black  hover:scale-105 transition ease-in-out text-white font-bold py-2 px-4 rounded-lg"
                            onClick={submitProfile}
                        >
                            Save
                        </button>
                    </div>
                </div>
            )}
        </div>
        <div className="relative z-3 bg-white">
            {showResumeModal && (
                <ResumeUploadModal
                    closeModal={() => setShowResumeModal(false)}
                    refId={currentUser?.refId}
                    refetch={refetch}
                />
            )}
            {showEmailOTPModal && (
                <VerifyEmailModal
                    closeEmailOTPModal={() => setEmailOTPModal(false)}
                    emailId={getCandidateProfile?.result?.email}
                    candidateId={currentUser.refId}
                    refetch={refetch}
                />
            )}
            {showMobileOTPModal && (
                <VerifyMobileModal
                    closeMobileOTPModal={() => setMobileOTPModal(false)}
                    mobile={getCandidateProfile?.result?.mobile}
                    candidateId={currentUser.refId}
                    refetch={refetch}
                />
            )}
        </div>
    </>
    )
}

export default PersonalDetails