import { Button } from "@mui/joy"
import { Add } from "@mui/icons-material"
import { useEffect, useState } from "react";
import ReactHtmlParser from 'html-react-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useGetCandidateProfileQuery, useUpdateProfileDescriptionMutation } from "../../../redux/slice/jobServiceApiSlice";

const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }], [{'size': []}],[{'color': []}],[{'background': []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
    ['clean']
  ],
}

const formats = [
  'header','font','size','color','background',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
]
const UserAboutU = () => {
  const [profileDescription, setProfileDescription] = useState("");
  const [editor,setEditor]=useState(false)
  const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
  const { data: getCandidateProfile, refetch,isSuccess } = useGetCandidateProfileQuery(currentUser?.refId,{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true});
  const [updateProfileDescription]=useUpdateProfileDescriptionMutation()

console.log(getCandidateProfile);

  useEffect(()=>{
    setProfileDescription(getCandidateProfile?.result?.profileDescription)
  },[getCandidateProfile?.result?.profileDescription,editor])

  const handleSave =async()=>{
    await updateProfileDescription({profileId:currentUser?.refId,profileDescription})
    refetch()
    setEditor(false)
  }


  return (
    <div className="wrapper">
      <div className="flex justify-between font-custom-font flex-row gap-4 mb-3">
        <p className='font-medium text-lg'>About:</p>
        <Button startDecorator={<Add />} onClick={()=>setEditor(true)}  color="success" className='flex gap-1'>Add/Edit Description</Button>
      </div>
      {editor&&<div className="flex flex-col gap-2 my-3">
        <ReactQuill theme="snow" value={profileDescription} onChange={setProfileDescription}
          modules={modules} formats={formats} />
          <div className="flex gap-3">
          <Button color="danger" className='flex gap-2 w-[100px]' onClick={()=>setEditor(false)}>Cancel</Button>
          <Button color="success" className='flex gap-2 w-[100px]' onClick={handleSave}>Save</Button>
          </div>
      </div>}
      {isSuccess && getCandidateProfile?.result?.profileDescription && <div>
        {ReactHtmlParser(getCandidateProfile?.result?.profileDescription)}
      </div>}
    </div>
  )
}

export default UserAboutU