import { Button } from "@mui/joy"
import { API_STATIC_URL } from "../../../apiConfig"
import { MdDelete } from "react-icons/md"
import { useDeleteVideoMutation } from "../../../redux/slice/ProfileApiSlice"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from "react";
import { useLocation } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};
const VideoComponent = ({ video, refetch }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [deleteVideo] = useDeleteVideoMutation()
    const location = useLocation()
    const handleDelete = async () => {
        await deleteVideo(video?.profileVideoId)
        refetch()
        setOpen(false);
    }
    return (
        <div className="relative">

            <div className='w-[300px] h-[200px] overflow-hidden rounded-lg'>
                <video src={`${API_STATIC_URL}${video?.videoUrl}`} controls className='w-full ' />
            </div>
           {/*  <div className="h-60">
                <video controls>
                    <source src={`${API_STATIC_URL}${video?.videoUrl}`} type="video/mp4" />
                </video>
            </div> */}
            {location.pathname === "/userProfile/videos" && <div className="absolute top-0 right-0 ">
                <Button onClick={handleOpen} variant="plain" color="danger"><MdDelete size={20} /></Button>
            </div>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Delete Video
                    </Typography>
                    <p className='text-lg'>
                        Are you sure, you want to delete the Video?
                    </p>
                    <div className='flex justify-end gap-6 mt-4'>
                        <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
                        <Button onClick={handleDelete} size="md" variant="solid" color="success">Delete</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default VideoComponent