import { Link } from "react-router-dom";
import { API_STATIC_URL } from "../../../apiConfig";
import { Avatar } from "@mui/joy";


const Comments = ({ comment, userId }) => {
    
    return (
        <div className={`flex flex-row gap-1 items-center py-1 px-2 ${userId === comment.userId ? `justify-end` : `justify-start`} `}>
            {comment?.imageUrl ? (
                <Avatar
                    alt="Profile Pic"
                    src={`${API_STATIC_URL}${comment?.imageUrl}`}
                    sx={{ width: 30, height: 30 }}
                />
            ) : (
                <Avatar sx={{ width: 30, height: 30 }} color="success">
                    <p className="text-lg">{comment?.fullName?.charAt(0)}</p>
                </Avatar>
            )}
            <div className="flex flex-col items-start justify-start bg-gray-200 px-1 rounded-lg  ">
                <Link to="" className='hover:underline text-xs'>{comment?.fullName}</Link>
                <p className="text-md text-md">{comment?.postComments}</p>
            </div>
        </div>
    )
}

export default Comments