import { Box, Button, Modal, Typography } from "@mui/joy";
import { useState } from "react";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { API_STATIC_URL } from "../../../apiConfig";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
  /*   bgcolor: 'background.paper',
     border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};


const ImageScreen = ({imageUrl,setZoomIn}) => {
    const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () =>{
      setOpen(false);
      setZoomIn(false)
   }


 return   < >
    {/* <div className="absolute top-0 right-0">
 <Button onClick={handleOpen} variant='plain' className='flex gap-1 ' ><HiOutlineArrowsExpand  size="24"/></Button>
    </div> */}
 <Modal
     open={open}
     onClose={handleClose}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
 >
     <Box sx={style}>
        <div className='w-full'>

     <img src={`${API_STATIC_URL}${imageUrl}`} alt="fullimage" className='w-full h-full'/>
        </div>
     </Box>
 </Modal>
</>
}

export default ImageScreen