import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState} from 'react';
import { FormLabel } from '@mui/joy';
import { convertBase64 } from './PersonalDetails';
import { AiFillFileAdd, AiTwotoneCloseCircle } from "react-icons/ai";
import { useAddVideoProfileMutation, useGetUserTotalVideosQuery } from '../../../redux/slice/ProfileApiSlice';
import { toast } from 'react-toastify';
import { FaVideo } from 'react-icons/fa6';
import VideoComponent from '../VideoComponent/VideoComponent';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};

const PhotosU = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [videoUrl, setVideoUrl] = useState("");
    const currentUser = JSON.parse(localStorage.getItem('vlinks'));
    const [addVideoProfile] = useAddVideoProfileMutation();
    const { data: videos, refetch } = useGetUserTotalVideosQuery(currentUser?.refId, { refetchOnFocus: true, refetchOnMountOrArgChange: true, pollingInterval: 6000 });


    const handleClose = () => {
        setOpen(false);
        setVideoUrl("");
    };

    const handleChange = async (e) => {
        const file = e.target.files[0];
        const videoUrl64 = await convertBase64(file);
        setVideoUrl(videoUrl64);
        e.target.value = null; 
    };

    const handleSave = async () => {
        const res = await addVideoProfile({ profileId: currentUser?.refId, videoUrl });
        if (res?.data?.success) {
            toast.success("New video Added successfully", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
            refetch()
            setVideoUrl("");
            setOpen(false);
        } else {
            toast.error("Can't add the new video", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
        }
    };
    return (
        <div className='wrapper'>
            <div className="flex justify-between font-custom-font flex-row gap-4">
                <p className='font-medium text-lg'>VIDEOS:</p>
                <Button onClick={handleOpen} startDecorator={<FaVideo  />} color="success" className='flex gap-1'>Add Video</Button>
            </div>
            <div className="flex flex-wrap items-center gap-4 mt-6">
                {videos?.result?.length > 0 && videos?.result?.map(video => <VideoComponent video={video} key={video.profileVideoId} refetch={refetch} />)}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Video
                    </Typography>
                    <div className='mt-4 flex flex-col gap-3'>
                        <FormLabel>Video:</FormLabel>
                        <div className='flex flex-col gap-2 relative'>

                            {(videoUrl) && <div className="px-1 flex justify-between flex-row w-full absolute z-10">
                                <Button variant="plain" size="sm" component="label" htmlFor="fileInput" >
                                    Change
                                    <input id="fileInput" type="file" accept="video/*" onChange={handleChange} className="hidden" />
                                </Button>
                                <div>
                                    <Button variant="plain" onClick={() => { setVideoUrl(""); }}>
                                        <AiTwotoneCloseCircle size="20" />
                                    </Button>
                                </div>
                            </div>}
                            {videoUrl && <div className='w-full h-[250px] overflow-auto'>
                            <video src={videoUrl} controls className='w-full ' />
                            </div>}
                            {(!videoUrl) && <label className='w-full h-[200px] justify-center flex items-center bg-gray-200 text-sm font-medium flex-col gap-1' htmlFor='image'><span><AiFillFileAdd size="18" /></span>Upload Video</label>}
                            <input type='file' accept="video/*" id="image" onChange={handleChange} className='hidden' />
                        </div>
                    </div>
                    <div className='flex justify-end gap-6 mt-4'>
                        <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
                        <Button onClick={handleSave} size="md" variant="solid" color="success" disabled={videoUrl === ""}>Save</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default PhotosU;