//completed
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBriefcase } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { API_STATIC_URL, ApplicationStatus, imgdefault } from "../../apiConfig";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { toast } from "react-toastify";
import {
  useGetRecruiterQuery,
  useJobApplicantsMutation,
  useRecruiterJobInfoQuery,
  useUpdateJobApplicationStatusMutation,
} from "../../redux/slice/recruiterServiceApiSlice";
import { useParams } from "react-router-dom";
import { Avatar } from "@mui/joy";

const ListingDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [appStatusId, setAppStatusId] = useState(0);
  const { id } = useParams();
  const [jobAppl, { data: jobApplicants }] = useJobApplicantsMutation();
  const [updateJobApplicationStatus] = useUpdateJobApplicationStatusMutation();
  const { data: recruiterJobInfo } = useRecruiterJobInfoQuery(id);
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));
  const { data: getRecruiter } = useGetRecruiterQuery(currentUser?.refId);
const pageSize =10;

  useEffect(() => {
    jobAppl({ jobId: id, applicationStatusId: 0, pageNumber: 1, pageSize });
  }, [id, jobAppl, pageSize]);

  useEffect(() => {
    if (jobApplicants?.dataCount) {
      setTotalPages(Math.ceil(jobApplicants.dataCount / pageSize));
    }
  }, [jobApplicants, pageSize]);

  const viewResume = (resumeContent) => {
    const newTab = window.open(`${API_STATIC_URL}${resumeContent}`, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      alert("Please allow popups for this site to view resumes.");
    }
  };

  const updateStatus = async (appId, newStatus) => {
    try {
      await updateJobApplicationStatus({
        applStatusId: newStatus,
        applicationId: appId,
      });
      await jobAppl({ jobId: id, applicationStatusId: 0 });
      setAppStatusId(0);
      toast.success("Changed Status");
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleApplicantsStatus = async (applicationStatusId) => {
    jobAppl({ jobId: id, applicationStatusId, pageSize, pageNumber: 1 });
    setAppStatusId(applicationStatusId);
  };

  const backToListings = () => {
    navigate("/ListingDashboard");
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    jobAppl({
      jobId: id,
      applicationStatusId: appStatusId,
      pageNumber,
      pageSize,
    });
  };
  const generatePaginationButtons = () => {
    const buttons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(currentPage + 2, totalPages);

      if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - 4);
      } else if (startPage === 1) {
        endPage = Math.min(5, totalPages);
      }

      if (startPage > 1) {
        buttons.push(1);
        if (startPage > 2) {
          buttons.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        buttons.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          buttons.push("...");
        }
        buttons.push(totalPages);
      }
    }
    return buttons;
  };
 console.log(jobApplicants);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Applications</title>
        <link rel="canonical" href="https://silverscreenbook.com/ListingDetails" />
      </Helmet>
      <div className=" font-custom-font relative  w-full px-1  mt-20">
        <div className="flex md:flex-row sm:flex-col gap-2">
          <div className="my-4 flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          <div className="flex flex-col min-h-screen mx-auto items-center py-10 gap-3">
            <div className="relative w-full justify-between flex md:flex-row sm:flex-col items-center bg-white rounded-lg shadow-lg  border border-gray-200 hover:border-gray-300 hover:bg-gray-100 transition ease-in-out transform hover:scale-105">
              <div className="flex m-2">
                <div>
                  <div className="font-bold text-xl text-gray-800 my-3 flex flex-row gap-2 item-center ml-4">
                    {recruiterJobInfo?.result?.jobTitle}
                  </div>
                  <div className="flex md:flex-row sm:flex-col md:items-center text-sm text-gray-600 ml-4">
                    <img
                      className="h-8 w-8 rounded-full m-2 border shadow-lg"
                      src={`${API_STATIC_URL}${getRecruiter?.result?.imageUrl}`}
                      alt={recruiterJobInfo?.result?.jobTitle}
                    />
                    <span className="flex flex-row mr-3 md:justify-center text-xl font-semibold items-center">
                      {" "}
                      {getRecruiter?.result?.companyName}
                    </span>
                    <span className="flex flex-row mx-3 md:justify-center items-center">
                      <FaLocationDot className="mx-2" />{" "}
                      {recruiterJobInfo?.result?.location}
                    </span>
                    {recruiterJobInfo?.result?.salary&&<span className="flex flex-row mx-3 md:justify-center items-center">
                      <FaMoneyBillWave className="mx-2" />{" "}
                      {recruiterJobInfo?.result?.salary}
                    </span>}
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <div className="text-sm text-gray-600 mx-5">
                  {recruiterJobInfo?.result?.categoryName}
                </div>
              </div>
              <div className="flex mr-6 sm:mt-2  justify-end">
                Total Applicants: {recruiterJobInfo?.result?.applicationsCount}
              </div>
            </div>
            <h1 className="text-red-800 text-4xl font-bold mt-16 ">
              Job Applicants
            </h1>
            <div className="flex items-center justify-center">
              <div className=" md:my-6 flex flex-col items-center">
                <div className="flex flex-row items-center justify-center  md:px-6 gap-10">
                  {ApplicationStatus.map((status) => (
                    <button
                      key={status.id}
                      value={status.id}
                      onClick={() => handleApplicantsStatus(status.id)}
                      className={`w-[120px] p-2 mx-auto rounded-lg border-2 text-white  ${
                        status.id === appStatusId
                          ? `bodybg`
                          : `bg-gray-500`
                      } `}
                    >
                      {status.status}
                    </button>
                  ))}
                </div>
                {jobApplicants?.result?.length > 0 ? (
                  <div
                    className={`bg-blue-400 relative gap-5 p-5 min-h-32 flex flex-col transition mt-2  rounded-lg`}
                  >
                    {jobApplicants?.result?.map((item) => (
                      <div
                        key={item.applicationId}
                        className="flex px-3 flex-col gap-4 p-1 overflow-hidden rounded-lg shadow-lg bg-white border border-gray-200 hover:border-gray-300  hover:bg-gray-200 "
                      >
                          <Link  to={`/profiledetails/${item?.profile_id}`} className="flex flex-row gap-2 items-center">
                             {item?.image_url ? (
                                <Avatar
                                    alt="Profile Pic"
                                    src={`${API_STATIC_URL}${item?.image_url}`}
                                    sx={{ width: 50, height: 50 }}
                                />
                            ) : (
                                <Avatar sx={{ width: 50, height: 50 }} color="warning">
                                    <p className="text-2xl">{item.full_name.charAt(0)}</p>
                                </Avatar>
                            )}
                            <p className="hover:underline">
                              {item?.full_name}
                            </p>
                          </Link>
                          <div className="flex  justify-between items-center w-full gap-10 text-nowrap ">
                              <div className="flex md:flex-row items-center md:justify-center text-gray-600">
                                <FaPhoneAlt className="mr-1" />
                                <span className="text-sm text-gray-600">
                                  {item.mobile}
                                </span>
                              </div>
                              <span className="flex flex-row items-center text-gray-600">
                                <MdEmail className="mr-1" />{" "}
                                <span className="text-sm">{item.email}</span>
                              </span>
                          </div>

                          <div className="flex flex-row pb-2 items-center gap-10 justify-between text-gray-600">
                            <span className="flex flex-row pb-2 items-center">
                              <IoHome className="mr-1" />{" "}
                              <span className="text-sm">{item.address}</span>
                            </span>
                            <button
                              className="bg-red-500 hover:bg-red-700 text-white font-bold px-2 ml-2 rounded-lg"
                              onClick={() =>
                                viewResume(item.profile_attachment)
                              }
                            >
                              View Resume
                            </button>
                            <div className="flex flex-col items-end justify-end gap-3 p-2 ">
                              <div className="flex flex-row gap-2 items-center">
                                Status:
                                <select
                                  value={item.status}
                                  onChange={(e) =>
                                    updateStatus(
                                      item.applicationId,
                                      parseInt(e.target.value)
                                    )
                                  }
                                  className="px-2 py-1 bg-white border border-gray-300 rounded-md"
                                >
                                  {ApplicationStatus.map((status) => (
                                    <option key={status.id} value={status.id}>
                                      {status.status}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="p-10 text-3xl font-bold  text-red-900 mt-10">
                    {appStatusId === 0 ? "No New Applicants" : "No Applicants"}
                  </div>
                )}
              </div>
            </div>
            {jobApplicants?.result?.length > 0 && (
              <div className="flex mt-5 gap-2">
                {generatePaginationButtons().map((button, index) => (
                  <button
                    key={index}
                    className={`rounded-lg bg-yellow-400 px-2 font-semibold  border hover:scale-110 transition border-yellow-400 hover:bg-yellow-500 ${
                      button === "..." ? "opacity-0" : ""
                    } ${
                      currentPage === button
                        ? "bg-yellow-600 text-white"
                        : "text-black"
                    }`}
                    onClick={() =>
                      typeof button === "number"
                        ? handlePageChange(button)
                        : null
                    }
                  >
                    {button}
                  </button>
                ))}
              </div>
            )}
            <button
              className="bodybg hover:bg-red-500  text-white px-10 py-2 font-bold rounded-lg shadow-lg mt-10 hover:scale-110 transition"
              onClick={backToListings}
            >
              {" "}
              Back
            </button>
          </div>
          <div className="flex my-4  items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingDetails;
