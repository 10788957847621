
import { MdOutlineLogin, MdSpaceDashboard } from "react-icons/md";
import { PiBriefcaseBold } from "react-icons/pi";
import { IoLogInOutline } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useGetCandidateProfileQuery } from "../../redux/slice/jobServiceApiSlice";
import { Avatar } from "@mui/joy";
import { API_STATIC_URL } from "../../apiConfig";
import { FiLogOut } from "react-icons/fi";
import { useGetRecruiterQuery } from "../../redux/slice/recruiterServiceApiSlice";

const UserIcon = () => {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));
//  const { data: getCandidateProfile } =currentUser?.refId ===2 ? useGetRecruiterQuery(currentUser?.refId,{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true}) : useGetCandidateProfileQuery(currentUser?.refId, { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true });
const fetchQuery = currentUser?.userRoleId === 2 ? useGetRecruiterQuery : useGetCandidateProfileQuery;
const { data: getCandidateProfile } = fetchQuery(currentUser?.refId, { 
    pollingInterval: 60000, 
    refetchOnFocus: true, 
    refetchOnMountOrArgChange: true 
});


  const LogOut = () => {
    localStorage.removeItem(`vlinks`);
    navigate("/")
    window.location.reload();
  }

  const goToRecuiterProfile = () => {
    if (currentUser?.userRoleId === 3) {
      navigate('/UserProfile');
    } else {
      navigate('/RecruiterProfile');
    }

  }

  const handleGoToAppliedJobs = () => {
    navigate('/CandidateApplications')
  }

  const handleBackToDashboard = () => {
    navigate('/RecruiterDash')
  }
  return (<>
    <div className='w-full md:block md:w-auto' >
      {currentUser?.token ? <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 gap-4 rounded-lg md:mx-10">
        {currentUser?.userRoleId !== 1 && (
          <button onClick={goToRecuiterProfile} className="items-center h-10  bg-gray-200   text-red-500 font-bold  rounded-lg transition  ease-in-out transform hover:scale-110">
            <div className=" flex flex-row  gap-2 items-center ">
              <div>
                {getCandidateProfile?.result?.imageUrl ? (
                  <Avatar
                    alt="Profile Pic"
                    src={`${API_STATIC_URL}${getCandidateProfile?.result?.imageUrl}`}
                    sx={{ width: 30, height: 30 }}
                  />
                ) : (
                  <Avatar sx={{ width: 30, height: 30 }} color="warning" variant="solid">
                    {currentUser?.userRoleId === 3 ?<p >{getCandidateProfile?.result?.fullName?.charAt(0)}</p>:<p >{getCandidateProfile?.result?.companyName?.charAt(0)}</p>}
                  </Avatar>
                )}</div>
              <h1 className=" sm:text-nowrap font-bold">{currentUser?.userRoleId === 2 ? getCandidateProfile?.result?.companyName :getCandidateProfile?.result?.fullName}</h1>
            </div>
          </button>
        )}
        {currentUser?.userRoleId === 2 &&
          (
            <button className="flex items-center h-10 gap-2   text-red-500 font-bold  rounded-lg transition  ease-in-out transform hover:scale-110" onClick={handleBackToDashboard}>
              <MdSpaceDashboard size={18} /> Dashboard
            </button>
          )}
        {currentUser?.userRoleId === 3 &&
          (
            <button className="flex items-center h-10 gap-2   text-red-500 font-bold  rounded-lg transition  ease-in-out transform hover:scale-110" onClick={handleGoToAppliedJobs}>
              <PiBriefcaseBold size={18} />  Applied Jobs
            </button>
          )}
        <button className="flex items-center gap-2 h-10  hover: text-red-500 font-bold  rounded-lg transition  ease-in-out transform hover:scale-110" onClick={LogOut}><FiLogOut size={18} /> Logout</button>
      </ul> : <ul className=" flex flex-col p-4 md:p-0 mt-4 gap-4 rounded-lg md:mx-10 ">
        <Link className="flex items-center h-10 gap-2  hover:text-red-600 text-red-500 font-bold  rounded-lg transition  ease-in-out transform hover:scale-110" to="/login">
          <MdOutlineLogin  size={18} /> Profile Login
        </Link>
        <Link className="flex items-center h-10 gap-2  hover:text-red-600 text-red-500 font-bold  rounded-lg transition  ease-in-out transform hover:scale-110" to="/loginrecruiter">
          <MdOutlineLogin  size={18} /> Recruiter Login
        </Link>
      </ul>}
    </div>
  </>)
}

export default UserIcon