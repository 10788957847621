//completed
import { API_STATIC_URL, imgdefault } from "../../apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FaMoneyBillWave } from "react-icons/fa";
import { HiOfficeBuilding } from "react-icons/hi";
import { FaBriefcase } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { GrDocumentUpload } from "react-icons/gr";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { useParams } from "react-router-dom";
import {
  useApplyJobMutation,
  useGetCandidateProfileQuery,
  useGetJobByIdQuery,
} from "../../redux/slice/jobServiceApiSlice";

const JobDetails = () => {
  const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
  const { jobId } = useParams();
  const { data } = useGetJobByIdQuery(jobId,{pollingInterval:6000,refetchOnFocus:true,refetchOnMountOrArgChange:true});
  const { data: getCandidateProfile } = useGetCandidateProfileQuery(currentUser?.refId);
  
  const [applyJob] = useApplyJobMutation();
  const navigate = useNavigate();


  const handleApply = async () => {
    if (currentUser?.token) {
      if (currentUser?.userRoleId === 2) {
        toast.error("This is not a JobSeeker Account", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      } else if (getCandidateProfile?.result) {
        if (
          getCandidateProfile?.result?.isEmailVerify === 0 ||
          getCandidateProfile?.result?.isMobileVerify === 0 ||
          getCandidateProfile?.result?.profileAttachment == null ||
          getCandidateProfile?.result?.fullName == null 
        ) {
          toast.error("Please complete  Mobile Verification,Email verification and upload Resume", {
            autoClose: 3000,
            style: {
              width: "300px",
            },
          });
          navigate("/UserProfile");
        } else {
          const payloadData = {
            profileId: getCandidateProfile?.result?.id,
            jobId,
            status: 0,
          };
          const res = await applyJob(payloadData);
          console.log(res);
          if (res.data.result == null) {
            toast.error(res.data.message, {
              autoClose: 1000,
              style: {
                width: "300px",
              },
            });
          } else {
            toast.success("Application Submitted", {
              autoClose: 1000,
              style: {
                width: "300px",
              },
            });
            navigate("/JobSearch");
          }
        }
      }
    } else {
      toast.error("Login to your account first", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
      navigate("/Login/3");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Job Description</title>
        <link rel="canonical" href="https://silverscreenbook.com/JobDetails" />
      </Helmet>
          <div className="flex md:flex-row sm:flex-col  font-custom-font justify-between md:px-2 mt-20  w-full ">
            <div className="my-4 flex items-center sm:hidden md:block ">
              <div className="">
                <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
              </div>
            </div>
            {data?.result && (
              <div className="flex flex-col bg-gray-100 md:w-2/5 sm:w-full md:m-8 p-8 rounded-lg shadow-lg ">
                <div className="flex justify-between mb-6">
                  <div>
                    <h1 className="md:text-3xl sm:text-xl mr-10 font-bold text-nowrap">
                      {data?.result?.jobTitle}
                    </h1>
                  </div>
                </div>
                <hr className="border border-red-200  mb-10" />
                <div className="flex md:flex-row sm:flex-col md:items-center mb-6 sm:justify-between ">
                  <div className="flex  items-center md:w-1/2 mb-6">
                    <img
                      className="md:h-14 md:w-14 sm:w-8 sm:h-8 rounded-full mr-4 shadow-md"
                      src={`${data?.result?.imageUrl ? `${API_STATIC_URL}${data?.result?.imageUrl}`: `${imgdefault}`}`}
                      alt={data?.result?.jobTitle}
                    />
                    <div>
                      <p className="md:text-lg sm:text-sm font-semibold">
                        {data?.result?.companyName}
                      </p>
                      <div className="flex items-center sm:text-sm">
                        <HiOfficeBuilding className="mr-2" />
                        <span className="text-gray-700">
                          {data?.result?.location}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:w-full md:w-1/2 items-end">
                    <p className="text-sm text-gray-500 mb-2">
                      Posted:{data?.result?.datePosted}
                    </p>
                    <p className="text-sm text-gray-500 flex w-full justify-end">
                      Category: {data?.result?.categoryName}
                    </p>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 sm:text-sm w-full">
                  {data?.result?.salary&&<div className="flex items-center">
                    <FaMoneyBillWave className="mr-2" />
                    <span className="text-gray-700">
                      {data?.result?.salary}
                    </span>
                  </div>}
                  <div className="flex items-center">
                    <FaPhoneAlt className="mr-2" />
                    <span className="text-gray-700">
                      {data?.result?.mobile}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <MdEmail className="mr-2" />
                    <span className="text-gray-700">{data?.result?.email}</span>
                  </div>
                </div>
                <p className=" flex flex-row items-start text-xl my-4 sm:text-sm">
                  Description:
                </p>
                <p className=" flex flex-row font-normal items-start text-sm text-gray-600 mb-4 ">
                  {" "}
                  {data?.result?.jobDescription}
                </p>
                <div className="flex justify-center gap-5 items-end h-full">
                  <button
                    onClick={() => navigate(-1)}
                    className="bodybg text-white font-semibold py-2 px-6 rounded hover:bg-red-700 shadow-md hover:scale-110 transition"
                  >
                    Back
                  </button>
                  {(currentUser?.userRoleId !== 1 &&
                    currentUser?.userRoleId !== 2) && (
                    <button
                      onClick={handleApply}
                      className="bodybg text-white font-semibold flex items-center gap-2 py-2 px-6 rounded hover:bg-red-700 shadow-md hover:scale-110 transition"
                    >
                      Apply Now <GrDocumentUpload />
                    </button>
                  )}
                </div>
              </div>
            )}

            <div className="my-4 flex items-center sm:hidden md:block ">
              <div className="">
                <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
              </div>
            </div>
          </div>
    </>
  );
};

export default JobDetails;
