
import {  NavLink } from "react-router-dom";
import { NavigationRoutes } from "../Home/Home";
import MobileNav from "../Home/MobileNav";
import { IMAGE_ROUTES } from "../../apiConfig";



/* const RNavBar = () => {
  const navigate = useNavigate();
  const currentUser=JSON.parse(localStorage.getItem("vlinks"));
  const [showButtons, setShowButtons] = useState(false);
    const LogOut = () =>{
      localStorage.removeItem(`vlinks`);
      navigate("/")
      window.location.reload();
    }
    const goToRecuiterProfile = () => {
      if(currentUser?.userRoleId === 3){
        navigate('/UserProfile');
      }else {
        navigate('/RecruiterProfile');
      }
     
    }
    const toggleButtons = () => {
      setShowButtons(prevState => !prevState);
    };

    const handleGoToAppliedJobs = () => {
      navigate('/CandidateApplications')
  }

    const handleBackToDashboard = () => {
      navigate('/RecruiterDash')
    }
    
    return (
        <div className='fixed top-0  w-full px-3 bg-white shadow-lg  z-50 '>
              <div className="flex flex-wrap items-center justify-between mx-auto ">
                <NavLink to="/">
                <img src={`${IMAGE_ROUTES.mainLogo}`} className="relative h-20" alt="Logo" />
                </NavLink>
                <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false"  onClick={toggleButtons}>
                  <span className="sr-only">Open main menu</span>
                  <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                  </svg>
                 
                </button>
                
                <div className={`w-full md:block md:w-auto ${showButtons ? 'block' : 'hidden'}`} id="navbar-default">
                  {currentUser?.token?<ul className="font-medium flex flex-col p-4 md:p-0 mt-4 gap-2 sm:items-end rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
                    {currentUser?.userRoleId === 2 &&
                    (
                      <button className="flex items-center h-10 gap-2 bodybg hover:bodybg text-white font-bold py-3 px-5 rounded-lg transition  ease-in-out transform hover:scale-110" onClick={handleBackToDashboard}>
                            Dashboard <MdSpaceDashboard />
                        </button>
                    ) }
                    {currentUser?.userRoleId === 3 &&
                    (
                      <button className="flex items-center h-10 gap-2 bodybg hover:bodybg text-white font-bold py-3 px-5 rounded-lg transition  ease-in-out transform hover:scale-110" onClick={handleGoToAppliedJobs}>
                            My Jobs <FaBriefcase/>
                        </button>
                    ) }
                  {currentUser?.userRoleId === 1 ? (null) : (
                      <button onClick={goToRecuiterProfile} className="flex items-center h-10 gap-2 bodybg hover:bodybg text-white font-bold py-3 px-5 rounded-lg transition  ease-in-out transform hover:scale-110">
                      Profile  <FaUser/>
                     </button>
                  )} 
                    <button className="flex items-center gap-2 h-10 bodybg hover:bodybg text-white font-bold py-3 px-5 rounded-lg transition  ease-in-out transform hover:scale-110" onClick={LogOut}>Logout<IoIosLogOut /></button> 
                  </ul>:<ul className="font-medium flex flex-col p-4 md:p-0 mt-4 gap-2 sm:items-end rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
                      <Link className="flex items-center h-10 gap-2 bodybg hover:bodybg text-white font-bold py-3 px-5 rounded-lg transition  ease-in-out transform hover:scale-110" to="/Login/3">
                      Login<IoLogInOutline/>
                        </Link>
                      <Link className="flex items-center h-10 gap-2 bodybg hover:bodybg text-white font-bold py-3 px-5 rounded-lg transition  ease-in-out transform hover:scale-110" to="/Login/2">
                      Post new Job<FaBriefcase/>
                        </Link>
                  </ul>}
                </div>
              </div>
        </div>
      );
      
} */


const RNavBar = () => {
  return (
    <div className='fixed top-0  w-full  bg-white  shadow-lg  z-10 '>
    <div className="flex items-center justify-between gap-4 wrapper">
      <NavLink to="/" className="flex gap-1 py-3 items-center ">
      <img src={`${IMAGE_ROUTES.mainLogo}`}  alt="Logo" />
      <p className="text-red-700 text-xl font-custom-font font-medium">SILVER SCREEN BOOK</p>
      </NavLink>
      <div className="max-lg:hidden">
      <NavigationRoutes/>
      </div>
      <MobileNav/>
    </div>
</div>
  )
}




export default RNavBar;