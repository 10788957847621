import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect, useState} from 'react';
import { FormLabel } from '@mui/joy';
import InputEmoji from "react-input-emoji";
import { AiFillFileAdd, AiTwotoneCloseCircle } from "react-icons/ai";
import { toast } from 'react-toastify';
import { MdEdit } from 'react-icons/md';
import { API_STATIC_URL } from '../../../apiConfig';
import { useUpdatePostMutation } from '../../../redux/slice/ProfileApiSlice';
import { convertBase64 } from '../UserProfile/PersonalDetails';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
     /* border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
};

const EditPost = ({post,setPageNumber,setRefreshPosts}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [postText, setPostText] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [updatePost] = useUpdatePostMutation();

    useEffect(()=>{
        setImageUrl(post?.imageUrl)
        setPostText(post?.postText)
        setVideoUrl(post?.videoUrl)
    },[post])

    const handleClose = () => {
        setOpen(false);
        setImageUrl(post?.imageUrl)
        setPostText(post?.postText)
        setVideoUrl(post?.videoUrl)
    };

    const handleChange = async (e) => {
        if (e.target && e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const fileType = file.type.split('/')[0];
        if (fileType === 'image') {
            const imageUrl = await convertBase64(file);
            setImageUrl(imageUrl);
            setVideoUrl("");
        } else if (fileType === 'video') {
              // Create a video element to read the video file
              const videoElement = document.createElement('video');
              videoElement.src = URL.createObjectURL(file);
      
              // Wait for metadata to be loaded to get the duration
              videoElement.addEventListener('loadedmetadata', async() => {
                  // Check if the duration is less than 5 minutes (300 seconds)
                  if (videoElement.duration < 300) {
                      const videoUrl =await convertBase64(file);
                      setVideoUrl(videoUrl);
                      setImageUrl("");
                  } else {
                      toast.error("Video duration should be less than 5 minutes.", {
                          autoClose: 1000,
                          style: {
                              width: "300px",
                          },
                      });
                  }
              });
        }}
    };


     const handleSave = async () => {
        const res = await updatePost({ postId: post.id, imageUrl, videoUrl, postText });
        if (res?.data?.success) {
            toast.success("Post Edited successfully", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
            setPageNumber(1); // Reset page number to 1
            setRefreshPosts(prev => !prev);
            setOpen(false);
        } else {
            toast.error("Can't Edit post", {
                autoClose: 1000,
                style: {
                    width: "300px",
                },
            });
        }
    }; 

    return (
        <div className=''>
            <div className="flex ">
            <Button variant='plain' onClick={handleOpen} startDecorator={<MdEdit  size="18" />}  size="sm" >Edit</Button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Post
                    </Typography>
                    <div className='mt-4 flex flex-col gap-3'>
                        <div className='flex flex-col gap-2 relative'>
                            <FormLabel>Post Text:</FormLabel>
                            <InputEmoji placeholder="Message your thoughts..." borderColor='#000'  value={postText} onChange={value=>setPostText(value)} /* onEnter={handleOnEnter} */ />
                        </div>
                        <div className='flex flex-col gap-2 relative'>
                            {(imageUrl || videoUrl) && <div className="px-1 flex justify-between flex-row w-full absolute z-10">
                                <Button variant="plain" size="sm" component="label" htmlFor="fileInput" >
                                    Change
                                    <input id="fileInput" type="file" accept="image/*, video/*" onChange={handleChange} className="hidden" />
                                </Button>
                                <div>
                                    <Button variant="plain" onClick={() => { setImageUrl(""); setVideoUrl(""); }}>
                                        <AiTwotoneCloseCircle size="20" />
                                    </Button>
                                </div>
                            </div>}
                            {imageUrl.includes("data:image") ? (<div className='w-full h-[200px] overflow-auto'>
                                <img src={imageUrl} alt="Preview" className='w-full' />
                            </div>):(imageUrl && <div className='w-full h-[200px] overflow-auto'>
                                <img src={`${API_STATIC_URL}${imageUrl}`} alt="Preview" className='w-full' />
                            </div>)}
                            {videoUrl.includes("data:video/mp4;base64") ? (<div className='w-full h-[250px] overflow-auto'>
                            <video src={videoUrl} controls className='w-full ' />
                            </div>):(videoUrl&& <div className='w-full h-[250px] overflow-auto'>
                            <video src={`${API_STATIC_URL}${videoUrl}`} controls className='w-full ' />
                            </div>)}

                            {(!imageUrl && !videoUrl ) && <label className='w-full h-[200px] justify-center flex items-center bg-gray-200 text-sm font-medium flex-col gap-1' htmlFor='image'><span><AiFillFileAdd size="18" /></span>Upload Image/Video</label>}
                            <input type='file' accept="image/*, video/*" id="image" onChange={handleChange} className='hidden' />
                        </div>
                    </div>
                    <div className='flex justify-end gap-6 mt-4'>
                        <Button onClick={handleClose} size="md" variant="solid" color="danger">Close</Button>
                        <Button onClick={handleSave} size="md" variant="solid" color="success" disabled={postText === "" && imageUrl === "" && videoUrl === ""}>Post</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default EditPost;
