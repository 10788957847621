
import "react-toastify/dist/ReactToastify.css";
import { FaUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { HiOfficeBuilding } from "react-icons/hi";
import { MdDescription } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useGetCandidateProfileQuery } from "../../../redux/slice/jobServiceApiSlice";
import { API_STATIC_URL } from "../../../apiConfig";



const PersonalDetailsSU = () => {
    const {userId} = useParams()
    const { data: getCandidateProfile, refetch } = useGetCandidateProfileQuery(userId,{pollingInterval:6000,refetchOnFocus:true,refetchOnMountOrArgChange:true});

    const viewResume = (resumeContent) => {
        const newTab = window.open(`${API_STATIC_URL}${resumeContent}`, "_blank");
        if (newTab) {
            newTab.focus();
        } else {
            alert("Please allow popups for this site to view resumes.");
        }
    };
  
    return (<>
        <div className="wrapper">
            {getCandidateProfile?.result && (
                <div className="flex flex-col text-black p-6 rounded-lg  md:w-[700px] xl:w-[800px] w-full mx-auto sm:mx-2">
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-10">
                        <div className="flex flex-row bggray rounded-lg p-2 gap-4 hover:scale-105 transition">
                            <div className=" flex items-center md:text-3xl jusitfy-center p-4 rounded-lg">
                                <FaUser />
                            </div>
                            <div className=" font-semibold flex flex-col justify-between">
                                <div className=" md:text-xl font-semibold">
                                    Name
                                </div>
                                <div>{getCandidateProfile?.result.fullName}</div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-stretch items-center">
                            <div className="flex flex-row bggray rounded-lg p-2 w-full gap-4 hover:scale-105 transition">
                                <div className=" flex items-center md:text-3xl jusitfy-center p-4 rounded-lg">
                                    <FaPhoneAlt />
                                </div>
                                <div className="  font-semibold flex flex-col justify-between">
                                    <div className=" md:text-xl  font-semibold flex">
                                        Mobile
                                    </div>
                                    <div className="text-sm">
                                        {getCandidateProfile?.result.mobile}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-stretch items-center">
                            <div className="flex flex-row bggray rounded-lg p-2 gap-4 w-full hover:scale-105 transition text-wrap">
                                <div className=" flex items-center md:text-3xl jusitfy-center p-4 rounded-lg">
                                    <IoMdMail />
                                </div>
                                <div className="  font-semibold flex flex-col justify-between text-wrap ">
                                    <div className="md:text-xl  font-semibold flex">
                                        Email
                                    </div>
                                    <div className="flex text-balance text-sm">
                                        {getCandidateProfile?.result.email}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-item flex flex-col justify-center  items-center bg-white rounded-lg shadow-lg hover:scale-105 transition ease-in-out bg-opacity-50">
                            <div className="flex flex-row bggray rounded-lg h-full p-4 w-full gap-4 transition">
                                <div className=" flex items-center md:text-3xl jusitfy-center p-4 rounded-lg">
                                    <MdDescription />
                                </div>
                                <div className="  font-semibold flex flex-col justify-between">
                                    <div className=" md:text-xl  font-semibold">
                                        Resume
                                    </div>
                                    <div>
                                        {getCandidateProfile?.result.profileAttachment ? (
                                            <button
                                                className="md:text-sm font-medium bg-gray-600 text-white rounded-lg px-2 py-1"
                                                onClick={() =>
                                                    viewResume(
                                                        getCandidateProfile?.result.profileAttachment
                                                    )
                                                }
                                            >
                                                View Resume
                                            </button>
                                        ) : (
                                            <p
                                                className="edit-button bg-red-600  hover:bg-red-700  hover:scale-105 transition ease-in-out  font-medium text-sm py-1 px-2 rounded-lg"
                                            >
                                                No Resume Uploaded
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row bggray rounded-lg p-2 gap-4 w-full hover:scale-105 transition">
                            <div className=" flex items-center md:text-3xl jusitfy-center p-4 rounded-lg">
                                <HiOfficeBuilding />
                            </div>
                            <div className="  font-semibold flex flex-col justify-between">
                                <div className="md:text-xl  font-semibold">
                                    Address
                                </div>
                                <div className="">
                                    {getCandidateProfile?.result?.address}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </>
    )
}

export default PersonalDetailsSU