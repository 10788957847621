import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IMAGE_ROUTES } from "../../apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { Link, createSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useAuthenticateMutation } from "../../redux/slice/userServiceApiSlice";

const Login = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailMobile, setEmailMobile] = useState("");
  const [authenticate] = useAuthenticateMutation();
  const [emailMobileEmpty, setEmailMobileEmpty] = useState(false); // Track if email field is empty
  const [passwordEmpty, setPasswordEmpty] = useState(false);

  const loginClicked = async (e) => {
    e.preventDefault();
    if (emailMobile === "" || emailMobile === null || password === "") {
      toast.error("Please input your credentials", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
      if (emailMobile === "" || emailMobile === null) setEmailMobileEmpty(true); // Set emailEmpty to true if email is empty
      if (password === "") setPasswordEmpty(true);

      return;
    }
    setEmailMobileEmpty(false); // Reset emailEmpty
    setPasswordEmpty(false); // Reset passwordEmpty

    try {
      let userData = await authenticate({
        userName: emailMobile,
        userPassword: password,
        userRoleId: 3,
      });
      if (userData?.data?.result) {
        localStorage.setItem("vlinks", JSON.stringify(userData?.data?.result));
        toast.success("Login Successful", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
        navigate({
          pathname: "/JobSearch",
          search: `?${createSearchParams({
            catId: userData?.data?.result?.categoryId,
          })}`,
        });
      } else {
        toast.error("Login failed. Invalid credentials.", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    } catch (error) {
      toast.error(error);
      setPassword("");
      toast.error("Login failed. Please check your credentials.", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Silver Screen Book - Login</title>
        <link rel="canonical" href="https://silverscreenbook.com/Login" />
      </Helmet>

      <div className="flex md:flex-row sm:flex-col gap-4 px-2 justify-between items-center font-custom-font ">
        <div className="flex  items-center sm:hidden md:block ">
          <div className="">
            <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
          </div>
        </div>
        <div className="md:min-h-screen md:w-[450px] w-full flex items-center  justify-center md:py-2 sm:py-4 md:px-4">
          <div className="flex items-center flex-col space-y-5 md:px-10 w-full rounded-2xl border border-red-600 hover:border-red-900">
            <Link className="flex justify-center items-center" to="/">
              <img
                src={`${IMAGE_ROUTES.altLogo}`}
                alt="Logo"
                className="max-h-36"
              />
            </Link>
            <div className="flex flex-col items-center gap-5">
              <h2 className="relative text-center text-2xl font-bold text-red-900">
                Login to your Profile
              </h2>
            </div>

            <div className="relative w-11/12 space-y-6">
              <div className="rounded-md shadow-sm space-y-6">
                <div className="flex flex-col gap-1">
                  <label htmlFor="email">Mobile Number</label>
                  <input
                    id="email"
                    value={emailMobile}
                    type="tel"
                    onChange={(e) => setEmailMobile(e.target.value)}
                    className={`relative block w-full px-3 py-2 border ${
                      emailMobileEmpty
                        ? "border-red-500 animate-shake"
                        : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-0 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    placeholder="Enter Mobile number"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="passwordInput">Password</label>
                  <div className="flex flex-row">
                    <input
                      id="passwordInput"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={`relative block w-full px-3 py-2 border ${
                        passwordEmpty
                          ? "border-red-500 animate-shake"
                          : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                      placeholder="Enter Your Password"
                    />
                    <button
                      onClick={() => setShowPassword((p) => !p)}
                      className="flex items-center bg-gray-200 relative w-11 inset-y-0 rounded-r-lg right-0 px-3 py-2 text-gray-500 border border-gray-300 focus:outline-none hover:bg-gray-300 hover:border-gray-500"
                    >
                      <FaEye />
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>
                <div className="text-sm">
                  <Link
                    className="font-medium text-sm  hover:text-red-700 hover:cursor-pointer"
                    to={`/Loginotpuser`}
                  >
                    Sign in with OTP
                  </Link>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <button
                  onClick={loginClicked}
                  className="bodybg hover:bg-red-500 w-full font-bold py-2 px-4 mt-2 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Sign in
                </button>
              </div>
              <div>
                <div className="flex items-center justify-end mb-2 gap-4">
                  <div className="">New Here?</div>
                  <Link
                    to={`/register`}
                    className="bodybg hover:bg-red-500 font-semibold py-3 px-5 h-10 flex items-center rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex  items-center sm:hidden md:block ">
          <div className="">
            <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
